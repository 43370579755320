import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../../App';

import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  selectEvaluationByQuestionUser,
  selectOutcomeById,
  selectQuestionById
} from '../../store/slices/outcomeSlice';
import { selectSettingByName } from '../../store/slices/settingsSlice';

import { parseISO } from 'date-fns';
import { FaAsterisk } from 'react-icons/fa';
import { FiCheck, FiStar } from 'react-icons/fi';
import { MdSpeed } from 'react-icons/md';
import { TbLayoutAlignMiddle } from 'react-icons/tb';

import {
  Button,
  Card,
  Collapse,
  Divider,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { BsChevronDoubleDown, BsChevronDoubleUp } from 'react-icons/bs';

export default function SmallQuestionCard({ questionId }) {
  const { userData } = useContext(AuthContext);
  const userId = userData.id;
  const navigate = useNavigate();
  const theme = useTheme();
  const [collapsed, setCollapsed] = useState(true);
  const usernameSettings = useSelector((state) =>
    selectSettingByName(state, 'View Usernames')
  );

  const question = useSelector((state) =>
    selectQuestionById(state, questionId)
  );
  const evaluation = useSelector((state) =>
    selectEvaluationByQuestionUser(state, questionId, userId)
  );
  const outcome = useSelector((state) =>
    selectOutcomeById(state, question.outcome_id)
  );
  const forecastOverruleSettings = useSelector((state) =>
    selectSettingByName(state, 'Forecast Submission Override')
  );
  const timeSincePosted = () => {
    return moment.utc(question.created_at).local().fromNow();
  };

  const isEdited = () => {
    return question.modified_at ? true : false;
  };

  const getStatusColour = () => {
    let status =
      question.status !== 'Completed' ? question.status : completedStatusCalc();
    switch (status) {
      case 'Pending':
        return theme.palette.statuses.mid1;
      case 'Rejected':
        return theme.palette.statuses.mid5;
      case 'Not Submitted':
        return theme.palette.statuses.mid2;
      case 'Accepted':
        return theme.palette.statuses.mid3;
      case 'Submitted':
        return theme.palette.statuses.mid3;
      case 'Closed':
        return theme.palette.statuses.mid4;
      case 'Duplicate':
        return theme.palette.statuses.mid6;
      default:
        return theme.palette.statuses.mid7;
    }
  };

  const completedStatusCalc = () => {
    if (outcome !== undefined) {
      if (outcome.statuses.includes('Forecasting')) {
        return 'Submitted';
      } else {
        return 'Closed';
      }
    } else {
      return 'Closed';
    }
  };

  const getResolutionColour = () => {
    switch (question.current_resolution?.name) {
      case 'True':
        return theme.palette.statuses.mid1;
      case 'False':
        return theme.palette.statuses.mid5;
      case 'Ambiguous':
        return theme.palette.statuses.mid4;
      case 'Not Set':
        return theme.palette.success.main;
      default:
        return null;
    }
  };

  let resolution_text = null;
  if (question.current_resolution != null) {
    resolution_text =
      'Resolved ' +
      moment.utc(question.current_resolution.resolved_at).local().fromNow();
  }

  const categoryTextColor = (category) => {
    switch (category) {
      case 'Very Low':
        return 'text-red-500';
      case 'Low':
        return 'text-orange-500';
      case 'Medium':
        return 'text-blue-500';
      case 'High':
        return 'text-teal-500';
      case 'Very High':
        return 'text-green-500';
      default:
        return 'bg-blue-500';
    }
  };

  const evaluationScoreCategoryTextColor = (category) => {
    switch (category) {
      case 'Furthest':
        return 'text-red-500';
      case 'Far':
        return 'text-orange-500';
      case 'Average':
        return 'text-blue-500';
      case 'Close':
        return 'text-teal-500';
      case 'Correct':
        return 'text-green-500';
      default:
        return 'bg-blue-500';
    }
  };

  if (usernameSettings === undefined) {
    return null;
  } else {
    return (
      <Card id={'questionCard' + questionId} className={`QuestionCard w-full`}>
        <div className="px-3 pt-2">
          <div className="break-words">
            <div className="">
              <div className="flex justify-between items-start max-h-42 overflow-y-auto">
                <Typography sx={{ fontWeight: 'bold', lineHeight: 1.3 }}>
                  <span className="whitespace-pre-line">
                    {question.question_text}
                  </span>
                </Typography>
                <div className="flex items-center">
                  {question.current_resolution == null && (
                    <Typography
                      sx={{
                        borderColor: getStatusColour(),
                        backgroundColor: getStatusColour(),
                        py: 0,
                        px: 0.3,
                        ml: 'auto',
                        fontSize: '0.9rem'
                      }}
                      className={`border-2 rounded text-white font-normal text-xs px-1`}>
                      {question.status !== 'Completed'
                        ? question.status
                        : completedStatusCalc()}
                    </Typography>
                  )}
                  {question.current_resolution?.name != null && (
                    <Typography
                      sx={{
                        borderColor: getResolutionColour(),
                        backgroundColor: getResolutionColour(),
                        py: 0,
                        px: 0.3,
                        fontSize: '0.85rem',
                        mx: 0.6,
                        textTransform: 'capitalize'
                      }}
                      className={`border-2 rounded text-white font-normal text-xs px-1 ml-1`}>
                      {question.current_resolution?.name.replace(
                        'Not Set',
                        'Reopened'
                      )}
                    </Typography>
                  )}
                </div>
              </div>
            </div>
            <Divider sx={{ my: 0.6 }} />
            <div className="QuestionInfo flex items-center justify-between ml-2">
              <div className="flex items-center">
                <Typography
                  sx={{ fontSize: '0.875rem', mr: 0.6 }}
                  color="text.secondary">
                  Posted {timeSincePosted()}
                </Typography>
                {(userData.role === 'Admin' ||
                  userData.role === 'Moderator' ||
                  usernameSettings.active) && (
                  <div className="flex items-center">
                    <Typography
                      sx={{ mr: 0.6, fontSize: '0.875rem' }}
                      color="text.secondary">
                      by
                    </Typography>
                    <Typography
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/profile/${question.created_by.username}`);
                      }}
                      tabIndex={0}
                      onKeyDown={(e) => {
                        if (e.key === ' ' || e.key === 'Enter') {
                          e.stopPropagation();
                          navigate(`/profile/${question.created_by.username}`);
                        }
                      }}
                      sx={{ fontSize: '0.875rem' }}
                      className="hover:underline hover:cursor-pointer"
                      color="primary.main">
                      {question.created_by.username}
                    </Typography>
                  </div>
                )}
                {isEdited() && (
                  <div className="mr-1">
                    <Tooltip title="Edited">
                      <div>
                        <FaAsterisk className="py-1" />
                      </div>
                    </Tooltip>
                  </div>
                )}
                {question.resolvable && (
                  <div className="mr-1">
                    <Tooltip title="Is Resolvable">
                      <div>
                        <FiCheck />
                      </div>
                    </Tooltip>
                  </div>
                )}
                {question.relevant && (
                  <div className="mr-1">
                    <Tooltip title="Is Relevant" placement="bottom">
                      <div>
                        <FiCheck />
                      </div>
                    </Tooltip>
                  </div>
                )}
                {question.forecast_override === 'True' &&
                  ((outcome.created_by.username === userData.username &&
                    forecastOverruleSettings.options.find(
                      (setting) => setting.name === 'outcome owner'
                    ).selected === true) ||
                    userData.role === 'Admin') && (
                    <div className="mr-1">
                      <Tooltip title="Forecast override" placement="bottom">
                        <div>
                          <FiCheck />
                        </div>
                      </Tooltip>
                    </div>
                  )}
              </div>
              <div>
                <Typography
                  sx={{ fontSize: '0.95rem', mr: 0.6 }}
                  color="text.secondary">
                  {resolution_text}
                </Typography>
              </div>
            </div>
            <Collapse in={!collapsed}>
              <div className="flex flex-wrap py-1">
                <div className="flex-col pr-2">
                  <Typography
                    sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}
                    color="text.secondary">
                    Resolution Criteria
                  </Typography>
                  <Typography
                    sx={{ fontSize: '0.875rem' }}
                    className="whitespace-pre-line font-medium text-xs">
                    {question.resolution_criteria}
                  </Typography>
                </div>
                <div className="flex-col pr-2">
                  <Typography
                    sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}
                    color="text.secondary">
                    Source Link
                  </Typography>
                  <Typography
                    sx={{ fontSize: '0.875rem' }}
                    className=" font-medium text-xs">
                    {question.source}
                  </Typography>
                </div>
                <div className="flex-col pr-2">
                  <Typography
                    sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}
                    color="text.secondary">
                    Resolution Date
                  </Typography>
                  <Typography
                    sx={{ fontSize: '0.875rem' }}
                    className=" font-medium text-xs">
                    {moment
                      .utc(question.resolution_date)
                      .local()
                      .format('dddd, MMMM Do YYYY')}
                  </Typography>
                </div>
                <div className="flex-col pr-2">
                  <Typography
                    sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}
                    color="text.secondary">
                    Extra Information
                  </Typography>
                  <Typography
                    sx={{ fontSize: '0.875rem' }}
                    className=" font-medium text-xs">
                    {question.extra_info}
                  </Typography>
                </div>
              </div>
            </Collapse>
            <div className="flex justify-end">
              <Button
                sx={{ px: 1, minWidth: 0 }}
                onClick={(event) => {
                  event.stopPropagation();
                  setCollapsed(!collapsed);
                }}>
                {collapsed ? (
                  <BsChevronDoubleDown size={15} />
                ) : (
                  <BsChevronDoubleUp size={15} />
                )}
              </Button>
            </div>
          </div>
        </div>
      </Card>
    );
  }
}
