import React from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';  // Import the plugin to support GFM (for tables, strikethrough, etc.)

const MarkdownComponent = ({ description }) => (
    <ReactMarkdown
      children={description}
      remarkPlugins={[gfm]}  // Use remark-gfm plugin to support GFM features
    />
);

export default MarkdownComponent;
