import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { AuthContext, checkTokenStatus } from '../../App';
import ConfirmationModal from '../modals/ConfirmationModal';

import {
  PieChart,
  SsidChartOutlined,
  StackedLineChart
} from '@mui/icons-material';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteStrategy,
  fetchOutcomes,
  selectCurrentUserForecastByQuestion,
  selectOutcomeById,
  selectRelevanceEvaluationsByStrategyUser
} from '../../store/slices/outcomeSlice';
import { selectSettingByName } from '../../store/slices/settingsSlice';
import StrategyForm from '../other/StrategyForm/StrategyForm';

import { FaAsterisk } from 'react-icons/fa';
import { FiDelete, FiEdit } from 'react-icons/fi';
import { GoComment } from 'react-icons/go';
import CreateCommentCard from './CreateCommentCard';

import {
  Button,
  Card,
  Divider,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { BsCheck2All, BsPlusSlashMinus } from 'react-icons/bs';
import { ClipLoader } from 'react-spinners';
import RoleBadgeIcon from '../icons/RoleBadgeIcon';
import LikelihoodPredictionModal from '../modals/LikelihoodPredictionModal';
import SetRelevanceEvaluationModal from '../modals/SetRelevanceEvaluationModal';
import SetResolutionModal from '../modals/SetResolutionModal';
import Markdown from '../other/Markdown';

export default function StrategyCard({ strategy, isLoggedIn, hideButtons }) {
  const dispatch = useDispatch();
  const { setIsLoggedIn, userData } = useContext(AuthContext);
  const userId = userData.id;
  const navigate = useNavigate();
  const theme = useTheme();
  const usernameSettings = useSelector((state) =>
    selectSettingByName(state, 'View Usernames')
  );

  const strategyId = strategy.id;

  const usersData = useSelector((state) => state.users.entities);
  const [errorMessage, setErrorMessage] = useState('');
  const [deleteStrategyRequestStatus, setDeleteStrategyRequestStatus] =
    useState('idle');
  const [showAddComment, setShowAddComment] = useState(false);
  const [editCard, setEditCard] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showLikelihoodPredictionModal, setShowLikelihoodPredictionModal] =
    useState(false);
  const [showRelevanceEvaluationModal, setShowRelevanceEvaluationModal] =
    useState(false);
  const [showResolutionModal, setShowResolutionModal] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);
  const outcome = useSelector((state) =>
    selectOutcomeById(state, strategy.outcome_id)
  );
  const outcomeStatus = useSelector(
    (_state) => _state.outcomes.outcomes.status
  );

  useEffect(() => {
    // check if auth token is valid
    if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      setIsLoggedIn(true);
    }
  }, [setIsLoggedIn]);

  useEffect(() => {
    let isMounted = true;
    setErrorMessage('');
    function fetchOutcomeData() {
      if (isMounted) {
        if (outcomeStatus === 'idle') {
          const token = localStorage.getItem('auth_token');
          dispatch(fetchOutcomes({ auth_token: token }));
        }
      }
    }
    fetchOutcomeData();
    return () => {
      isMounted = false;
    };
  }, [outcomeStatus, dispatch, outcome]);

  const createComment = () => {
    setShowAddComment(true);
  };

  const replyClick = () => {
    createComment();
  };

  const hasResolved = strategy.current_resolution === undefined ? false : true;

  const getResolutionColour = () => {
    switch (strategy.current_resolution?.name) {
      case 'True':
        return theme.palette.statuses.mid1;
      case 'Implemented':
        return theme.palette.statuses.mid3;
      case 'False':
        return theme.palette.statuses.mid5;
      case 'Not Implemented':
        return theme.palette.statuses.mid5;
      case 'Ambiguous':
        return theme.palette.statuses.mid4;
      case 'Not Set':
        return theme.palette.success.main;
      default:
        return null;
    }
  };

  let resolution_text = null;
  if (strategy.current_resolution != null) {
    if (strategy.current_resolution.name === 'Not Set') {
      resolution_text =
        'Reopened ' +
        moment.utc(strategy.current_resolution.resolved_at).local().fromNow();
    } else {
      resolution_text =
        'Implemented ' +
        moment.utc(strategy.current_resolution.resolved_at).local().fromNow();
    }
  }

  const relevanceEvaluations = useSelector((state) =>
    selectRelevanceEvaluationsByStrategyUser(state, strategyId, userId)
  );

  const currentRelevanceEvaluation = relevanceEvaluations
    ? relevanceEvaluations.find((evaluation) => evaluation.status === 'current')
    : null;

  const currentUserOutcomeForecast = useSelector((state) =>
    selectCurrentUserForecastByQuestion(state, {
      userId: userData.id,
      questionId: outcome?.outcome_question_id
    })
  );

  const canDeleteStrategy =
    [strategy.id, checkTokenStatus()].every(Boolean) &&
    deleteStrategyRequestStatus === 'idle';

  const deleteStrategyData = async () => {
    if (canDeleteStrategy) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setDeleteStrategyRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          id: strategy.id,
          auth_token: token,
          outcome_id: outcome.id
        };
        await dispatch(deleteStrategy(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) {
              if (editCard) {
                setEditCard(!editCard);
              }
            }
          });
      } catch (err) {
        setErrorMessage(`Failed to delete strategy: ${err.message}`);
      } finally {
        if (isMounted) {
          setDeleteStrategyRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      setErrorMessage('Strategy could not be deleted.');
    }
  };

  const timeSincePosted = () => {
    return moment(strategy.created_at, 'YYYY-MM-DDThh:mm:ss').fromNow();
  };

  const isEdited = () => {
    return strategy.modified_at ? true : false;
  };

  let content;
  let canRenderCard =
    outcomeStatus === 'succeeded' &&
    strategy &&
    outcome &&
    (outcome.statuses !== 'Generation' ||
      strategy.created_by.username === userData.username ||
      userData.role === 'Moderator' ||
      userData.role === 'Admin' ||
      userData.role === 'Observer' ||
      outcome.created_by.username === userData.username);

  if (usernameSettings === undefined) {
    content = null;
  } else if (outcomeStatus === 'loading') {
    content = (
      <div className="text-center">
        <ClipLoader color="#f87171" loading={true} size={100} />
      </div>
    );
  } else if (canRenderCard) {
    content = (
      <div>
        <Card
          id={'strategyCard' + strategyId}
          className={` rounded mx-10 my-5 `}>
          <div className="px-5 pt-3 break-words">
            <div className="flex items-start justify-between mb-1 max-h-42 overflow-y-auto">
              {errorMessage && (
                <Typography color="error">{errorMessage}</Typography>
              )}
              {editCard ? (
                <StrategyForm
                  strategy={strategy}
                  setEditCard={setEditCard}
                  saveClicked={saveClicked}
                  setSaveClicked={setSaveClicked}
                  outcomeId={outcome.id}
                />
              ) : (
                <div className="w-full">
                  <div className="flex items-center justify-between">
                    <Typography
                      sx={{ fontSize: '1rem', fontWeight: 'bold' }}
                      color="text.secondary">
                      Strategy
                    </Typography>
                    {hasResolved && (
                      <Typography
                        sx={{
                          borderColor: getResolutionColour(),
                          backgroundColor: getResolutionColour(),
                          py: 0,
                          px: 0.3,
                          fontSize: '0.85rem',
                          mx: 0.6,
                          textTransform: 'capitalize'
                        }}
                        className={`border-2 rounded text-white font-normal text-xs px-1 ml-1`}>
                        {strategy.current_resolution?.name.replace(
                          'Not Set',
                          'Reopened'
                        )}
                      </Typography>
                    )}
                  </div>
                  <div className="markdown">
                    <Markdown description={strategy.strategy} />
                  </div>
                  <Typography
                    sx={{
                      fontSize: '1rem',
                      fontWeight: 'bold',
                      marginTop: '0.5vw'
                    }}
                    color="text.secondary">
                    Execution and Reporting Date
                  </Typography>
                  <Typography>
                    This strategy is expected to be executed{' '}
                    {strategy.strategy_date_preposition + ' '}
                    {strategy.strategy_date.start !== undefined
                      ? strategy.strategy_date.start +
                        ' - ' +
                        strategy.strategy_date.end
                      : strategy.strategy_date.end}
                    . As reported by {strategy.resolution_source + ' '}
                    {strategy.link_authority &&
                      '(' + strategy.link_authority + ') '}
                    {strategy.source_date_preposition + ' '}
                    {strategy.resolution_date.start !== undefined
                      ? strategy.resolution_date.start +
                        ' - ' +
                        strategy.resolution_date.end
                      : strategy.resolution_date.end}
                    .
                  </Typography>
                  {strategy.extra_info && (
                    <>
                      <Typography
                        sx={{
                          fontSize: '1rem',
                          fontWeight: 'bold',
                          marginTop: '0.5vw'
                        }}
                        color="text.secondary">
                        Extra Info
                      </Typography>
                      <Typography>{strategy.extra_info}</Typography>
                    </>
                  )}
                  <Typography
                    sx={{
                      fontSize: '1rem',
                      fontWeight: 'bold',
                      marginTop: '0.5vw'
                    }}
                    color="text.secondary">
                    Likelihood Prediction
                  </Typography>
                  {strategy.current_likelihood_prediction &&
                    (strategy.current_resolution
                      ? !['Implemented', 'Not Implemented'].includes(
                          strategy.current_resolution.name
                        )
                      : true) && (
                      <Typography className="pb-2">
                        The outcome owner currently thinks they are{' '}
                        {(
                          strategy.current_likelihood_prediction.probability
                            .p_value * 100
                        ).toFixed(1)}
                        % likely to execute this strategy.
                      </Typography>
                    )}
                  {strategy.current_resolution?.name === 'Not Implemented' && (
                    <Typography className="pb-2" sx={{ fontWeight: 'bold' }}>
                      The outcome owner will not implement this strategy.
                    </Typography>
                  )}
                  {strategy.current_resolution?.name === 'Implemented' && (
                    <Typography className="pb-2" sx={{ fontWeight: 'bold' }}>
                      The outcome owner has implemented this strategy.
                    </Typography>
                  )}

                  <div>
                    <Typography
                      sx={{ fontSize: '0.95rem', mr: 0.6 }}
                      color="text.secondary">
                      {resolution_text}
                    </Typography>
                  </div>

                  {currentRelevanceEvaluation && (
                    <div className="flex items-center mr-2">
                      <SsidChartOutlined sx={{ fontSize: '1.1rem', mr: 0.6 }} />
                      <Typography sx={{ mr: 0.6 }}>Your Relevance: </Typography>
                      <Typography
                        className={`${
                          currentRelevanceEvaluation.relevance_level > 0
                            ? 'text-green-500'
                            : currentRelevanceEvaluation.relevance_level === 0
                            ? 'text-amber-500'
                            : 'text-red-500'
                        }`}>
                        {currentRelevanceEvaluation.relevance_level.toFixed(3)}
                      </Typography>
                    </div>
                  )}

                  {Object.hasOwn(strategy, 'relevance_level') && (
                    <div className="flex items-center mr-2">
                      <StackedLineChart
                        sx={{ fontSize: '1.1rem', mr: 0.6 }}
                        fill="black"
                      />
                      <Typography sx={{ mr: 0.6 }}>Crowd Relevance:</Typography>
                      <Typography
                        sx={{ fontWeight: 500 }}
                        className={`${
                          strategy.relevance_level > 0
                            ? 'text-green-500'
                            : strategy.relevance_level === 0
                            ? 'text-amber-500'
                            : 'text-red-500'
                        }`}>
                        {strategy.relevance_level.toFixed(3)}
                      </Typography>
                    </div>
                  )}
                  {Object.hasOwn(strategy, 'current_impact') && (
                    <div className="flex items-center mr-2">
                      <PieChart
                        sx={{ fontSize: '1.1rem', mr: 0.6 }}
                        fill="black"
                      />
                      <Typography sx={{ mr: 0.6 }}>Current Impact:</Typography>
                      <Typography
                        sx={{ fontWeight: 500 }}
                        className={`${
                          strategy.current_impact > 0
                            ? 'text-green-500'
                            : 'text-red-500'
                        }`}>
                        {(strategy.current_impact * 100).toFixed(2)}%
                      </Typography>
                    </div>
                  )}
                </div>
              )}
            </div>
            <Divider />
            <div className="StrategyInfo flex items-center justify-between ">
              <div className="flex items-center">
                <Typography
                  sx={{ fontSize: '0.95rem', mr: 0.6 }}
                  color="text.secondary">
                  Posted {timeSincePosted()}
                </Typography>
                {(userData.role === 'Admin' ||
                  userData.role === 'Moderator' ||
                  usernameSettings.active) && (
                  <div className="flex items-center">
                    <Typography
                      sx={{ mr: 0.6, fontSize: '0.95rem' }}
                      color="text.secondary">
                      by
                    </Typography>
                    <Typography
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/profile/${strategy.created_by.username}`);
                      }}
                      sx={{ fontSize: '0.95rem' }}
                      className="hover:underline hover:cursor-pointer"
                      color="primary.main">
                      {strategy.created_by.username}
                    </Typography>
                    <div className="pb-1">
                      <RoleBadgeIcon
                        role={usersData[strategy.created_by.id]?.role}
                      />
                    </div>
                  </div>
                )}
                {isEdited() && (
                  <div className="mr-1">
                    <Tooltip title="Edited">
                      <div>
                        <FaAsterisk className="py-1" />
                      </div>
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
          </div>
          {!hideButtons && (
            <div className="flex pb-1 pt-1 items-center justify-between">
              <div className="ml-5 flex flex-wrap">
                <div className="flex items-center mr-2">
                  <Button
                    disabled={!isLoggedIn}
                    onClick={(e) => {
                      e.stopPropagation();
                      replyClick();
                    }}
                    size="small">
                    <GoComment className="mr-1 pl-0.5" />
                    <span>Add a comment</span>
                  </Button>
                </div>
                {(strategy.created_by.username === userData.username ||
                  userData.role === 'Admin' ||
                  userData.role === 'Moderator') && (
                  <div className="flex items-center mr-2">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();

                        setEditCard(!editCard);
                      }}
                      className={'inline-flex items-center'}
                      size="small">
                      <FiEdit className="mr-1 pb-0.5" />
                      {editCard ? 'Cancel' : 'Edit'}
                    </Button>
                  </div>
                )}
                {(strategy.created_by.username === userData.username ||
                  userData.role === 'Admin' ||
                  userData.role === 'Moderator') && (
                  <div className="flex items-center mr-2">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowDeleteConfirm(true);
                      }}
                      className={'inline-flex items-center'}
                      size="small">
                      <FiDelete className="mr-1 pl-0.5 pb-0.5" />
                      Delete
                    </Button>
                  </div>
                )}
                {outcome.created_by.username === userData.username && (
                  <div className="flex items-center mr-2">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowLikelihoodPredictionModal(true);
                      }}
                      className={'inline-flex items-center'}
                      disabled={
                        strategy.current_resolution &&
                        ['Implemented', 'Not Implemented'].includes(
                          strategy?.current_resolution.name
                        )
                      }
                      size="small">
                      <FiDelete className="mr-1 pl-0.5 pb-0.5" />
                      {strategy.current_likelihood_prediction
                        ? 'Update likelihood prediction'
                        : 'Add likelihood prediction'}
                    </Button>
                  </div>
                )}
                {userData.role !== 'Observer' &&
                  outcome.statuses.includes('Forecasting') &&
                  outcome.forecast_mechanism === 'derived' &&
                  typeof strategy.current_likelihood_prediction !==
                    'undefined' &&
                  typeof currentUserOutcomeForecast !== 'undefined' && (
                    <div className="flex items-center mr-2">
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowRelevanceEvaluationModal(true);
                        }}>
                        <BsPlusSlashMinus className="mr-1 pl-0.5 pb-0.5" />
                        {`${
                          currentRelevanceEvaluation ? 'Override' : 'Add'
                        } Relevance Evaluation`}
                      </Button>
                    </div>
                  )}
                {editCard && (
                  <div className="flex justify-end px-4">
                    <Button
                      variant="contained"
                      onClick={() => setSaveClicked(true)}>
                      Save
                    </Button>
                  </div>
                )}
                {(outcome.created_by.username === userData.username ||
                  ['Admin', 'Moderator'].includes(userData.role)) &&
                  (outcome.statuses.includes('Forecasting') ||
                    outcome.statuses.includes('Closed')) && (
                    <div className="flex items-center mr-2">
                      <Button
                        onClick={() => {
                          setShowResolutionModal(true);
                        }}
                        className={'inline-flex items-center'}>
                        <BsCheck2All className="mr-1 pl-0.5 pb-0.5" size={20} />
                        {hasResolved
                          ? 'Edit Implementation'
                          : 'Mark Implemented'}
                      </Button>
                    </div>
                  )}
              </div>
            </div>
          )}

          {showDeleteConfirm && (
            <ConfirmationModal
              shown={showDeleteConfirm}
              close={() => {
                setShowDeleteConfirm(false);
              }}
              confirm={() => {
                setShowDeleteConfirm(false);
                deleteStrategyData();
              }}
              confirmationMessage="Do you really want to delete this strategy? This process cannot be undone"
            />
          )}
          {showRelevanceEvaluationModal && (
            <SetRelevanceEvaluationModal
              shown={showRelevanceEvaluationModal}
              close={() => {
                setShowRelevanceEvaluationModal(false);
              }}
              strategyId={strategyId}
              outcomeForecast={
                currentUserOutcomeForecast.probability.display_probability
              }
              questionForecast={
                strategy.current_likelihood_prediction?.probability.p_value
              }
              existingEvaluation={
                currentRelevanceEvaluation
                  ? currentRelevanceEvaluation.relevance_forecast * 100
                  : null
              }
            />
          )}

          {showLikelihoodPredictionModal && (
            <LikelihoodPredictionModal
              strategyId={strategy.id}
              outcomeId={outcome.id}
              currentLikelihoodPrediction={
                strategy.current_likelihood_prediction?.probability.p_value
              }
              close={() => {
                setShowLikelihoodPredictionModal(false);
              }}
              afterSubmit={() => {
                setShowLikelihoodPredictionModal(false);
              }}
            />
          )}
        </Card>
        {showAddComment && (
          <CreateCommentCard
            close={() => {
              setShowAddComment(false);
            }}
            afterSubmit={() => {
              setShowAddComment(false);
            }}
            strategyId={strategy.id}
          />
        )}

        {showResolutionModal && (
          <SetResolutionModal
            shown={showResolutionModal}
            close={() => {
              setShowResolutionModal(false);
            }}
            parentEntity={strategy}
          />
        )}
      </div>
    );
  } else {
    content = null;
  }
  return <section>{content}</section>;
}
