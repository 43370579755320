import { TextField, FormControl, Autocomplete, Chip } from "@mui/material";
import { useEffect, useState } from "react";

export default function MultiSelectDropdown({
  label,
  options,
  selectedValues,
  onSelectedValuesChange,
  disabledOptions
}) {
  const [value, setValue] = useState(selectedValues);
  if (!disabledOptions) {
    disabledOptions = [];
  }

  const handleChange = (event, newValue) => {
    setValue([
      ...disabledOptions,
      ...newValue.filter((option) => !disabledOptions?.includes(option)),
    ]);
    onSelectedValuesChange([
      ...disabledOptions,
      ...newValue.filter((option) => !disabledOptions?.includes(option))
    ]
    );
  };

  useEffect(() => {
    setValue(selectedValues);
  }, [selectedValues]);

  return (
    <div>
      <FormControl sx={{ my: 1.2 }} className={`w-full`}>
        <Autocomplete
          multiple
          id="tags-standard"
          options={options}
          getOptionLabel={(option) => option}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const { key, ...tagProps } = getTagProps({ index });
              return (
                <Chip
                  key={key}
                  label={option} // Ensure this matches the structure of your option objects
                  {...tagProps}
                  disabled={disabledOptions?.includes(option)}
                />
              );
            })
          }
          value={value}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField {...params} variant="standard" label={label} />
          )}
        />
      </FormControl>
    </div>
  );
}