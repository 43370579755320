import {
  Autocomplete,
  Card,
  FormControl,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBucketPresets, selectPresetById } from '../../store/slices/bucketSlice';
import {
  selectOutcomeById,
  selectQuestionById
} from '../../store/slices/outcomeSlice';
import FrugalForecastInput from '../other/FrugalForecastInput';
import NumericForecastInput from '../other/NumericForecastInput';

export default function FrugalForecastCard({ questionId }) {
  const dispatch = useDispatch();
  const question = useSelector((state) =>
    selectQuestionById(state, questionId)
  );
  const outcome = useSelector((state) =>
    selectOutcomeById(state, question?.outcome_id)
  );
  const preset = useSelector((state) => selectPresetById(state, outcome?.bucket_preset?.id))
  const presetStatus = useSelector(
    (state) => state.buckets.presets.status
  );
  const [configNames, setConfigNames] = useState([]);
  const [forecastingType, setForecastingType] = useState('Frugal');
  const [configurationName, setConfigurationName] = useState();

  useEffect(() => {
    if (preset?.configurations) {
      const names = preset.configurations.map(config => config.name);
      setConfigNames(names);
      
      if (!configurationName || !names.includes(configurationName)) {
        setConfigurationName(names[0] || null);
      }
    }
  }, [preset?.configurations]);

  useEffect(() => {
    if (presetStatus === 'idle') {
      const auth_token = localStorage.getItem('auth_token');
      dispatch(fetchBucketPresets(auth_token));
    }
  }, [presetStatus, dispatch]);

  const handleConfigSelect = (event, newName) => {
    setConfigurationName(newName);
  };

  const changeForecastingType = (event, newType) => {
    if (newType !== null) {
      setForecastingType(newType);
    }
  };

  return (
    <Card
      sx={{ my: 6, p: 3, borderRadius: 3, boxShadow: 3, overflowX: 'auto' }}
      className="w-full md:w-5/6 lg:w-2/3 xl:w-3/5 2xl:w-2/5">
      <div className="flex items-center justify-between">
        <div className="text-left">
          {forecastingType === 'Frugal' && (
            <FormControl sx={{ mt: 2, pb: 3, minWidth: 300 }}>
              <Autocomplete
                id="tags-standard"
                options={configNames}
                value={configurationName || null} 
                onChange={handleConfigSelect}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Select Configuration"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }
                    }}
                  />
                )}
              />
            </FormControl>
          )}
        </div>
        <div className="text-right">
          <div className="flex-initial">
            <ToggleButtonGroup
              value={forecastingType}
              exclusive
              onChange={changeForecastingType}
              aria-label="forecasting type"
              sx={{ pb: 2 }}>
              <ToggleButton value="Frugal" aria-label="frugal mode">
                Frugal
              </ToggleButton>
              <ToggleButton value="Numeric" aria-label="numeric mode">
                Numeric
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
      </div>
      <Typography
        sx={{
          fontWeight: 700,
          textAlign: 'center',
          fontSize: '1.5rem'
        }}>
        {forecastingType} Forecasting
      </Typography>

      {forecastingType === 'Frugal' && (
        <FrugalForecastInput
          questionId={questionId}
          configurationName={configurationName}
        />
      )}

      {forecastingType === 'Numeric' && (
        <NumericForecastInput
          questionId={questionId}
        />
      )}
    </Card>
  );
}
