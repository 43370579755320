import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { hivemindAPI } from "../HivemindAPI";

const bucketAdapter = createEntityAdapter();

const bucketPresetAdapter = createEntityAdapter();

const bucketConfigurationAdapter = createEntityAdapter();

const bucketInitialState = bucketAdapter.getInitialState({
  status: "idle",
  error: null
});

const presetInitialState = bucketPresetAdapter.getInitialState({
  status: "idle",
  error: null
});

const configurationInitialState = bucketConfigurationAdapter.getInitialState({
  status: "idle",
  error: null
});

const initialState = {
  buckets: bucketInitialState,
  presets: presetInitialState,
  configurations: configurationInitialState
};

export const fetchBuckets = createAsyncThunk(
  "buckets/fetchBuckets",
  async (auth_token) => {
    const response = await hivemindAPI.fetch("/api/buckets", null, auth_token);
    return response.json();
  }
);

export const deleteBucket = createAsyncThunk(
  "buckets/deleteBucket",
  async (payload) => {
    const response = await hivemindAPI.delete(
      "/api/buckets",
      {
        id: payload.id
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const updateBucket = createAsyncThunk(
  "buckets/updateBucket",
  async (payload) => {
    const response = await hivemindAPI.put(
      "/api/buckets",
      {
        id: payload.id,
        new_name: payload.name,
        new_upper_limit: payload.upperLimit,
        new_lower_limit: payload.lowerLimit,
        new_configuration_id: payload.bucketConfigurationId
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const addNewBucket = createAsyncThunk(
  "buckets/addNewbucket",
  async (payload) => {
    const response = await hivemindAPI.post(
      "/api/buckets",
      {
        name: payload.name,
        upper_limit: payload.upperLimit,
        lower_limit: payload.lowerLimit,
        configuration_id: payload.bucketConfigurationId
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const fetchBucketPresets = createAsyncThunk(
  "buckets/fetchBucketPresets",
  async (auth_token) => {
    const response = await hivemindAPI.fetch(
      "/api/buckets/presets",
      null,
      auth_token
    );
    return response.json();
  }
);

export const deleteBucketPreset = createAsyncThunk(
  "buckets/deleteBucketPreset",
  async (payload) => {
    const response = await hivemindAPI.delete(
      "/api/buckets/presets",
      {
        id: payload.id
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const updateBucketPreset = createAsyncThunk(
  "buckets/updateBucketPreset",
  async (payload) => {
    const response = await hivemindAPI.put(
      "/api/buckets/presets",
      {
        id: payload.id,
        new_name: payload.name
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const addNewBucketPreset = createAsyncThunk(
  "buckets/addNewBucketPreset",
  async (payload) => {
    const response = await hivemindAPI.post(
      "/api/buckets/presets",
      {
        name: payload.name
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const fetchBucketConfigurations = createAsyncThunk(
  "buckets/fetchBucketConfigurations",
  async (auth_token) => {
    const response = await hivemindAPI.fetch(
      "/api/buckets/configurations",
      null,
      auth_token
    );
    return response.json();
  }
);

export const deleteBucketConfiguration = createAsyncThunk(
  "buckets/deleteBucketConfiguration",
  async (payload) => {
    const response = await hivemindAPI.delete(
      "/api/buckets/configurations",
      {
        id: payload.id
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const updateBucketConfiguration = createAsyncThunk(
  "buckets/updateBucketConfiguration",
  async (payload) => {
    const response = await hivemindAPI.put(
      "/api/buckets/configurations",
      {
        id: payload.id,
        new_name: payload.name
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const addNewBucketConfiguration = createAsyncThunk(
  "buckets/addNewBucketConfiguration",
  async (payload) => {
    const response = await hivemindAPI.post(
      "/api/buckets/configurations",
      {
        name: payload.name,
        bucket_name: payload.bucket_name,
        bucket_upper_limit: payload.bucket_upper_limit,
        bucket_lower_limit: payload.bucket_lower_limit
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const addConfigurationsToPreset = createAsyncThunk(
  "buckets/addConfigurationsToPreset",
  async (payload) => {
    const response = await hivemindAPI.post(
      "/api/buckets/presets/assign",
      {
        preset_id: payload.id,
        config_ids: payload.configurations
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const removeConfigurationsFromPreset = createAsyncThunk(
  "buckets/removeConfigurationsToPreset",
  async (payload) => {
    const response = await hivemindAPI.delete(
      "/api/buckets/presets/assign",
      {
        preset_id: payload.id,
        config_ids: payload.configurations
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const bucketSlice = createSlice({
  name: "buckets",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchBuckets.pending, (state, action) => {
        state.buckets.status = "loading";
      })
      .addCase(fetchBuckets.fulfilled, (state, action) => {
        state.buckets.status = "succeeded";
        bucketAdapter.upsertMany(state.buckets, action.payload.data);
      })
      .addCase(fetchBuckets.rejected, (state, action) => {
        state.buckets.status = "failed";
        state.buckets.error = action.error.message;
      })
      .addCase(updateBucket.fulfilled, (state, action) => {
        const updatedbucket = action.payload.data;
        bucketAdapter.updateOne(state.buckets, {
          id: updatedbucket.id,
          changes: updatedbucket
        });
      })
      .addCase(deleteBucket.fulfilled, (state, action) => {
        bucketAdapter.removeOne(state.buckets, action.meta.arg.id);
      })
      .addCase(addNewBucket.fulfilled, (state, action) => {
        bucketAdapter.addOne(state.buckets, action.payload.data);
      })
      .addCase(fetchBucketPresets.pending, (state, action) => {
        state.presets.status = "loading";
      })
      .addCase(fetchBucketPresets.fulfilled, (state, action) => {
        state.presets.status = "succeeded";
        bucketPresetAdapter.upsertMany(state.presets, action.payload.data);
      })
      .addCase(fetchBucketPresets.rejected, (state, action) => {
        state.presets.status = "failed";
        state.presets.error = action.error.message;
      })
      .addCase(updateBucketPreset.fulfilled, (state, action) => {
        const updatedBucketPreset = action.payload.data;
        bucketPresetAdapter.updateOne(state.presets, {
          id: updatedBucketPreset.id,
          changes: updatedBucketPreset
        });
      })
      .addCase(deleteBucketPreset.fulfilled, (state, action) => {
        bucketPresetAdapter.removeOne(state.presets, action.meta.arg.id);
      })
      .addCase(addNewBucketPreset.fulfilled, (state, action) => {
        bucketPresetAdapter.addOne(state.presets, action.payload.data);
      })
      .addCase(fetchBucketConfigurations.pending, (state, action) => {
        state.configurations.status = "loading";
      })
      .addCase(fetchBucketConfigurations.fulfilled, (state, action) => {
        state.configurations.status = "succeeded";
        bucketConfigurationAdapter.upsertMany(
          state.configurations,
          action.payload.data
        );
      })
      .addCase(fetchBucketConfigurations.rejected, (state, action) => {
        state.configurations.status = "failed";
        state.configurations.error = action.error.message;
      })
      .addCase(updateBucketConfiguration.fulfilled, (state, action) => {
        const updatedBucketConfiguration = action.payload.data;
        bucketConfigurationAdapter.updateOne(state.configurations, {
          id: updatedBucketConfiguration.id,
          changes: updatedBucketConfiguration
        });
      })
      .addCase(deleteBucketConfiguration.fulfilled, (state, action) => {
        bucketConfigurationAdapter.removeOne(
          state.configurations,
          action.meta.arg.id
        );
      })
      .addCase(addNewBucketConfiguration.fulfilled, (state, action) => {
        bucketConfigurationAdapter.addOne(
          state.configurations,
          action.payload.data
        );
      })
      .addCase(addConfigurationsToPreset.fulfilled, (state, action) => {
        const updatedBucketPreset = action.payload.data;
        bucketPresetAdapter.updateOne(state.presets, {
          id: updatedBucketPreset.id,
          changes: updatedBucketPreset
        });
      })
      .addCase(removeConfigurationsFromPreset.fulfilled, (state, action) => {
        const updatedBucketPreset = action.payload.data;
        bucketPresetAdapter.updateOne(state.presets, {
          id: updatedBucketPreset.id,
          changes: updatedBucketPreset
        });
      });
  }
});

export const bucketsActions = bucketSlice.actions;
export default bucketSlice.reducer;

export const {
  selectAll: selectAllBuckets,
  selectById: selectBucketById,
  selectIds: selectBucketIds
} = bucketAdapter.getSelectors((state) => state.buckets?.buckets);

export const {
  selectAll: selectAllPresets,
  selectById: selectPresetById,
  selectIds: selectPresetIds
} = bucketPresetAdapter.getSelectors((state) => state.buckets.presets);

export const {
  selectAll: selectAllConfigurations,
  selectById: selectConfigurationById,
  selectIds: selectConfigurationIds
} = bucketConfigurationAdapter.getSelectors(
  (state) => state.buckets.configurations
);

export const selectBucketNames = createSelector(
  [selectAllBuckets, (state) => state],
  (buckets) => {
    return buckets.map((bucket) => bucket.name);
  }
);

export const selectPresetNames = createSelector(
  [selectAllPresets, (state) => state],
  (presets) => {
    return presets.map((preset) => preset.name);
  }
);

export const selectConfigurationNames = createSelector(
  [selectAllConfigurations, (state) => state],
  (configurations) => {
    return configurations.map((configuration) => configuration.name);
  }
);

export const selectBucketByName = createSelector(
  [selectAllBuckets, (state, name) => name],
  (buckets, name) => buckets.find((bucket) => bucket.name === name)
);

export const selectPresetByName = createSelector(
  [selectAllPresets, (state, name) => name],
  (presets, name) => presets.find((preset) => preset.name === name)
);

export const selectConfigurationByName = createSelector(
  [selectAllConfigurations, (state, name) => name],
  (configurations, name) =>
    configurations.find((configuration) => configuration.name === name)
);

export const selectPresetByIds = createSelector(
  [selectAllPresets, (state, ids) => ids],
  (presets, ids) => presets.filter((preset) => ids.includes(preset.id))
);

export const selectConfigurationByIds = createSelector(
  [selectAllConfigurations, (state, ids) => ids],
  (configurations, ids) =>
    configurations.filter((configuration) => ids.includes(configuration.id))
);

export const selectBucketByIds = createSelector(
  [selectAllBuckets, (state, ids) => ids],
  (buckets, ids) => buckets.filter((bucket) => ids.includes(bucket.id))
);
