import React, { useContext, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { AuthContext, checkTokenStatus } from '../../App';
import {
  addNewComment,
  addNewCommentReply,
  selectQuestionById,
  selectStrategyById
} from '../../store/slices/outcomeSlice';

import { Button, Card, TextField, Typography, useTheme } from '@mui/material';
import MDEditor from '@uiw/react-md-editor';
import { set } from 'date-fns';
import { ClipLoader } from 'react-spinners';

export default function CreateCommentCard({
  close,
  afterSubmit,
  questionId,
  strategyId,
  parentCommentId
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDarkMode = localStorage.getItem('mode') === 'dark';
  const { setIsLoggedIn, userData } = useContext(AuthContext);
  var localCommentObj = {};
  if (questionId)
    localCommentObj = JSON.parse(
      localStorage.getItem(
        'question:' + questionId + 'comment' + userData.username
      )
    );
  if (strategyId)
    localCommentObj = JSON.parse(
      localStorage.getItem(
        'strategy:' + strategyId + 'comment' + userData.username
      )
    );
  if (parentCommentId)
    localCommentObj = JSON.parse(
      localStorage.getItem(
        'parentCommentId:' + parentCommentId + 'comment' + userData.username
      )
    );
  if (localCommentObj === null) localCommentObj = {};

  const [newComment, setNewComment] = useState(
    localCommentObj.comment ? localCommentObj.comment : ''
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [isMounted, setIsMounted] = useState(false);
  const [addRequestStatus, setAddRequestStatus] = useState('idle');

  const maxCommentLength = 2500;

  const question = useSelector((state) =>
    selectQuestionById(state, questionId)
  );

  const strategy = useSelector((state) =>
    selectStrategyById(state, strategyId)
  );

  const changeCommentText = (value) => {
    updateLocalStorage('comment', value);
    setNewComment(value);
  };

  const updateLocalStorage = (field, value) => {
    // question id + parent comment id + username is the composite unique field for items saved in local storage (this makes drafts unique)
    // object is saved in stringified format so we parse it after fetching
    var localCommentObj = {};
    if (questionId)
      localCommentObj = JSON.parse(
        localStorage.getItem(
          'question:' + questionId + 'comment' + userData.username
        )
      );
    if (strategyId)
      localCommentObj = JSON.parse(
        localStorage.getItem(
          'strategy:' + strategyId + 'comment' + userData.username
        )
      );
    if (parentCommentId)
      localCommentObj = JSON.parse(
        localStorage.getItem(
          'parentCommentId:' + parentCommentId + 'comment' + userData.username
        )
      );
    if (localCommentObj === null) localCommentObj = {};

    // update question object's specified field
    localCommentObj[field] = value;
    // save updated object in local storage (stringified format)
    if (questionId)
      localStorage.setItem(
        'question:' + questionId + 'comment' + userData.username,
        JSON.stringify(localCommentObj)
      );
    if (strategyId)
      localStorage.setItem(
        'strategy:' + strategyId + 'comment' + userData.username,
        JSON.stringify(localCommentObj)
      );
    if (parentCommentId)
      localStorage.setItem(
        'parentCommentId:' + parentCommentId + 'comment' + userData.username,
        JSON.stringify(localCommentObj)
      );
  };

  const canCreate =
    [
      newComment,
      newComment.length <= maxCommentLength,
      question || strategy || parentCommentId,
      checkTokenStatus()
    ].every(Boolean) && addRequestStatus === 'idle';
  const createComment = async () => {
    if (canCreate) {
      setIsMounted(true);
      setErrorMessage('');
      try {
        setAddRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        if (parentCommentId) {
          let payload = {
            parentCommentId: parentCommentId,
            text: newComment,
            auth_token: token
          };
          await dispatch(addNewCommentReply(payload))
            .unwrap()
            .then((data) => {
              // clears local storage 'draft' when comment is submitted
              if (questionId)
                localStorage.removeItem(
                  'question:' + questionId + 'comment' + userData.username
                );
              if (strategyId)
                localStorage.removeItem(
                  'strategy:' + strategyId + 'comment' + userData.username
                );
              if (parentCommentId)
                localStorage.removeItem(
                  'parentCommentId:' +
                    parentCommentId +
                    'comment' +
                    userData.username
                );
              if (isMounted) setNewComment('');
            });
        } else {
          let payload = {
            question_id: question?.id,
            strategy_id: strategy?.id,
            text: newComment,
            auth_token: token
          };
          await dispatch(addNewComment(payload))
            .unwrap()
            .then((data) => {
              // clears local storage 'draft' when comment is submitted
              if (questionId)
                localStorage.removeItem(
                  'question:' + questionId + 'comment' + userData.username
                );
              if (strategyId)
                localStorage.removeItem(
                  'strategy:' + strategyId + 'comment' + userData.username
                );
              if (parentCommentId)
                localStorage.removeItem(
                  'parentCommentId:' +
                    parentCommentId +
                    'comment' +
                    userData.username
                );
              if (isMounted) setNewComment('');
            });
        }
        if (isMounted) setAddRequestStatus('idle');
        afterSubmit();
      } catch (err) {
        setErrorMessage(`Failed to create comment: ${err.message}`);
        if (isMounted) setAddRequestStatus('idle');
      } finally {
        setIsMounted(false);
      }
    } else if (!newComment) setErrorMessage('Comment cannot be empty.');
    else if (newComment.length > maxCommentLength)
      setErrorMessage('Comment is too long.');
    else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      if (newComment) setErrorMessage('Comment could not be created.');
      if (!newComment) setErrorMessage('Comment does not exist.');
    }
  };

  return (
    <div className={parentCommentId ? 'ml-10' : ''}>
      <Card
        className={`CommentCard ml-20 mr-10 p-2 border-l-4 border-slate-700 break-words mt-2 mb-5`}>
        <div data-color-mode={isDarkMode ? 'dark' : 'light'}>
          <MDEditor
            id="CommentText"
            value={newComment}
            onChange={(event) => changeCommentText(event)}
            textareaProps={{
              placeholder: 'What is your comment?',
              maxLength: maxCommentLength,
              required: true,
              autoCorrect: 'on'
            }}
            preview="edit"
            style={{
              backgroundColor: isDarkMode
                ? theme.palette.background.paper
                : '#fff',
              color: isDarkMode ? theme.palette.text.primary : '#000'
            }}
            defaultTabEnable={true}
          />
        </div>
        <Typography
          color="text.secondary"
          sx={{
            fontSize: '0.75rem'
          }}>{`${newComment.length}/${maxCommentLength}`}</Typography>
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
        <div className="flex justify-end p-1 mt-2">
          <div className="flex items-center mr-2">
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'gray',
                ':hover': { backgroundColor: '#757575' }
              }}
              onClick={() => {
                // clears local storage 'draft' when comment is cancelled
                if (questionId)
                  localStorage.removeItem(
                    'question:' +
                      questionId +
                      'comment:' +
                      parentCommentId +
                      userData.username
                  );
                if (strategyId)
                  localStorage.removeItem(
                    'strategy:' +
                      strategyId +
                      'comment:' +
                      parentCommentId +
                      userData.username
                  );
                close();
              }}
              className="w-full">
              Cancel
            </Button>
          </div>
          <Button
            variant="contained"
            onClick={createComment}
            disabled={isMounted}
            size="small">
            {isMounted ? (
              <ClipLoader color="#ffffff" loading={true} size={50} />
            ) : (
              'Submit'
            )}
          </Button>
        </div>
      </Card>
    </div>
  );
}
