import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  Typography
} from '@mui/material';
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function QuestionScoreFormula() {
  return (
    <span className="text-center">
      <MathJaxContext>
        <MathJax>
          {
            'Q = \\(\\frac{1}{M}{\\sum_{i=1}^{M-1} \\sum_{j=1}^{N} \\ \\left| p_{Q,i,{j+1}} - p_{Q,i,j} \\right|} \\)'
          }
        </MathJax>
      </MathJaxContext>
    </span>
  );
}

function BoxesRow() {
  return (
    <div className="m-5 flex justify-center space-x-1 text-white">
      <Box
        sx={{
          width: '14%',
          flexGrow: 1,
          p: 4,
          bgcolor: 'statuses.mid4',
          textAlign: 'center',
          fontSize: '0.8rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        Outcome Submission
      </Box>
      <Box
        sx={{
          width: '23%',
          flexGrow: 1,
          p: 4,
          bgcolor: 'statuses.mid1',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          fontSize: '0.8rem'
        }}>
        Question Generation
      </Box>
      <Box
        sx={{
          width: '23%',
          flexGrow: 1,
          p: 4,
          bgcolor: 'statuses.mid2',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '0.8rem',
          textAlign: 'center'
        }}>
        Question Evaluation
      </Box>
      <Box
        sx={{
          width: '40%',
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          fontSize: '0.8rem',
          p: 4,
          bgcolor: 'statuses.mid3'
        }}>
        Forecasting
      </Box>
    </div>
  );
}

function QuestionsGrid() {
  const sections = {
    Basics: [
      { text: 'What is Hivemind?', link: '?section=basics#WhatIsHivemind' },
      { text: 'What is an Outcome?', link: '?section=basics#WhatIsAnOutcome' },
      { text: 'What is a Question?', link: '?section=basics#WhatIsAQuestion' }
    ],
    'Outcome Owner': [
      {
        text: 'What am I supposed to do with Hivemind?',
        link: '?section=outcome-owner#WhatAmISupposedToDoWithHivemind'
      },
      {
        text: 'What kind of thing should I write?',
        link: '?section=outcome-owner#WhatKindOfThingShouldIWrite'
      },
      {
        text: "But what if there isn't an obvious metric?",
        link: '?section=outcome-owner#WhatIfThereIsNotAnObviousMetric'
      }
    ],
    Questioner: [
      {
        text: 'What should I do on Hivemind?',
        link: '?section=questioner#WhatShouldIDoOnHivemind'
      },
      {
        text: 'What makes a good question?',
        link: '?section=questioner#WhatMakesAGoodQuestion'
      },
      {
        text: "Why do I have to ask 'closed' questions?",
        link: '?section=questioner#WhyClosedQuestions'
      }
    ],
    Scoring: [
      {
        text: 'What is a Question Score?',
        link: '?section=scoring#WhatIsAQuestionScore'
      },
      {
        text: 'What is an Evaluation Score?',
        link: '?section=scoring#WhatIsAnEvaluationScore'
      },
      {
        text: 'What is a User Generation Score?',
        link: '?section=scoring#WhatIsAUserGenerationScore'
      }
    ], 
    "Account Management": [
      {
        text: 'How to register',
        link: '?section=scoring#HowToRegister'
      },
      {
        text: 'Reset password?',
        link: '?section=scoring#ResetPassword'
      }
    ]
  };

  return (
    <div className="w-full md:w-2/3">
      <Grid container spacing={2} sx={{ width: '100%' }}>
        {Object.entries(sections).map(([sectionName, questions]) => (
          <Grid item xs={12} sm={6} md={6} key={sectionName}>
            <Card
              className="px-6 p-3"
              sx={{ height: '100%', textAlign: 'left' }}>
              <Typography variant="h6">{sectionName}</Typography>
              <Divider sx={{ borderColor: 'LightGray', mb: 0.6 }} />
              {questions.slice(0, 3).map((question, index) => (
                <div key={index} className="">
                  <Typography key={index} sx={{}}>
                    <Link href={question.link}>{question.text}</Link>
                  </Typography>
                </div>
              ))}
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default function FrequentlyAskedQuestionView() {
  const [expandedPanels, setExpandedPanels] = useState({});
  const location = useLocation();

  const expandPanel = async (panel) => {
    setExpandedPanels((prev) => ({ ...prev, [panel]: true }));
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedPanels((prev) => ({ ...prev, [panel]: isExpanded }));
  };

  // useEffect(() => {
  //   const queryParams = new URLSearchParams(location.search);
  //   const section = queryParams.get('section');
  //   if (section) {
  //     expandPanel(section);
  //   }
  // }, [location.search]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const section = queryParams.get('section');
    if (section) {
      expandPanel(section);
    }
    setTimeout(function () {
      if (location.hash) {
        let str;
        let splitArr = location.hash.split('#', location.hash.length);
        str = splitArr[1];
        let elem = document.getElementById(str);
        if (elem) {
          elem.scrollIntoView(true, { behavior: 'smooth' });
        }
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }
    }, 400);
  }, [location]);

  return (
    <div className="mx-auto mt-5 mb-5 md:mx-20">
      <Card className="mb-5" sx={{ backgroundColor: 'background.secondary' }}>
        <CardContent className="text-center">
          <h1 className="text-3xl font-bold mb-2">
            FAQ - Frequently Asked Questions
          </h1>
          <p>Explore our FAQ sections to find answers about:</p>
          <div className="pt-2 flex justify-center">{QuestionsGrid()}</div>
        </CardContent>
      </Card>

      <Accordion
        key="Basics"
        expanded={expandedPanels['basics'] || false}
        onChange={handleChange('basics')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={'panel-details-basics'}
          id={'panel-header-basics'}
          sx={{ backgroundColor: 'background.secondary' }}>
          <div>
            <Typography
              sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}
              className="text-2xl font-black">
              Basics
            </Typography>
            <Typography> General information about Hivemind </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails
          id={'panel-details-basics'}
          sx={{ backgroundColor: 'background.secondary' }}>
          <div className="mb-5">
            <Typography
              sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}
              id="WhatIsHivemind">
              What is Hivemind?
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              Hivemind is a platform for generating better questions about
              desired outcomes or problem statements. The goal is to provide a
              service that leverages crowd knowledge and artificial intelligence
              to help leaders ask the right questions about the future.
            </Typography>
          </div>

          <div className="mb-5">
            <Typography
              sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}
              id="WhatIsAnOutcome">
              What is an Outcome?
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              An outcome broadly describes a particular goal of a decision
              maker. It can represent a decision, a desired end-state, or a
              problem statement that the decision maker faces in the near
              future.
              <br />
              The overall goal of Hivemind is to be able to explore the entire
              problem space of these Outcomes to enable decision makers to take
              the best course of action towards achieving their desired
              Outcomes.
              <br />
            </Typography>
          </div>

          <div className="mb-5">
            <Typography
              sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}
              id="WhatIsAQuestion">
              What is a Question?
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              In Hivemind, Questions are measurable statements about the future
              which are unambiguously resolvable. They often start with the word
              'will'. For example: Will Joe Biden be re-elected as president of
              the US in 2025?
            </Typography>
          </div>

          <div className="mb-5">
            <Typography
              sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}
              id="WhatIsTheHivemindWorkflow">
              What is the Hivemind Workflow?
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              Hivemind splits the core user activities into different phases.
              Each Outcome transitions through these phases over time frames
              defined by the Outcome Owner.
              <br />
              The first phase is <strong>Generation</strong> where the Hivemind
              user base will post resolvable questions that aim to maximally
              reduce ignorance about an Outcome.
              <br />
              Second, an Outcome transitions to the <strong>
                Evaluation
              </strong>{' '}
              phase. This is where users evaluate each other's question for
              information value.
              <br />
              Next the Outcome reaches <strong>Forecasting</strong> phase, where
              a selection of questions with the best evaluations are included in
              a forecasting tournament. They will then receive live forecasts
              indicating their current likelihood.
              <br />
              Last is the <strong>Closed</strong> phase. By this point all
              insights that will help the decision maker achieve their Outcome
              should have been obtained and no more user activities will occur.
              <br />
            </Typography>
            {BoxesRow()}
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion
        key="outcome-owner"
        expanded={expandedPanels['outcome-owner'] || false}
        onChange={handleChange('outcome-owner')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={'panel-details-outcome-owner'}
          id={'panel-header-outcome-owner'}
          sx={{ backgroundColor: 'background.secondary' }}>
          <div>
            <Typography
              sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}
              className="text-2xl font-black">
              Outcome Owner
            </Typography>

            <Typography>Details for Outcome Owners</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails
          id={'panel-details-outcome-owner'}
          sx={{ backgroundColor: 'background.secondary' }}>
          <div className="mb-5">
            <Typography
              sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}
              id="WhatAmISupposedToDoWithHivemind">
              I am a decision-maker, what am I supposed to do with Hivemind?
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              Welcome! In Hivemind terms, you are called an 'Outcome Owner'. An
              'Outcome' is the thing that you want to achieve. All you have to
              do is type that in and tell Hivemind when you need make your
              decisions about how you are going to achieve that outcome.
            </Typography>
          </div>

          <div className="mb-5" id="WhatKindOfThingShouldIWrite">
            <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              What kind of thing should I write?
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              The best outcome is when you know specifically what you want to
              achieve and you can express that as a metric. So maybe if you are
              a business leader, you want to achieve a certain revenue target.
              Or if you are a military commander, you have a certain mission or
              objective you need to achieve. Or perhaps you are a politician who
              wants to win an election or achieve a certain political goal.
            </Typography>
          </div>

          <div className="mb-5" id="WhatIfThereIsNotAnObviousMetric">
            <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              But what if there isn't an obvious metric?
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              That's okay, Hivemind will still work fine. The only difference is
              that the more 'general' the outcome you want, the more 'general'
              will be the relationship between the questions and the outcome.
              <br />
              <br />
              For example, if you want to 'achieve peace in the Middle East',
              there isn't a single or several metrics in that outcome that can
              tell you that that has been achieved, or not. But we can still
              generate informative questions on that topic which might indicate
              if something like that outcome will, or will not, be achieved.
            </Typography>
          </div>

          <div className="mb-5" id="CanISubmitAQuestionAsAnOutcome">
            <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              Can I submit a question as an outcome?
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              Certainly, it will work. We feel that it won't usually be as
              informative, because your asking a question implies that there is
              something beyond that, the reason you need to know in the first
              place. Hivemind works best if you put that in, in order to
              generate the widest number of relevant questions.
            </Typography>
          </div>

          <div className="mb-5" id="IWantToAchieveAMultifacetedOutcome">
            <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              I want to achieve a multi-faceted outcome - how do I deal with
              that?
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              It will normally be better if you split those into multiple
              outcomes, because then it becomes easier to see which factors
              identified in the individual questions relate to which bit of your
              outcome. But if that doesn't work for you, just put it all in as a
              single outcome.
            </Typography>
          </div>

          <div className="mb-5" id="AfterIHaveSubmittedMyOutcome">
            <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              After I have submitted my outcome, what happens?
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              The crowd-sourced group will generate questions as individuals
              that will maximally inform your topic: that is, think of questions
              that are relevant to your outcome and be useful to you in making
              your decisions. Then as a group, they will rank all the questions
              to determine which questions are likely to be most informative.
              Then, a group of forecasters will predict what the answer to the
              question is likely to be. The Hivemind platform then infers how
              and how much those likely answers will affect the outcome you
              want.
              <br />
              <br />
              At every stage, you can just click on your outcome, find out what
              stage it is in, and all the details associated with it: all the
              questions that have been generated, how good the group thinks each
              question is, what the likely answer to that question is and how
              much those answers are affecting the outcome. It also tells you
              how likely the outcome is to happen if the outcome is specific.
              For non-specific outcomes, you will be told a level of 'belief'
              that the indicators are supporting that outcome.
            </Typography>
          </div>

          <div className="mb-5" id="IWantToOverrideTheGroupsChoiceOfQuestion">
            <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              I want to over-ride the group's choice about one or more of the
              questions - can I do that?
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              Yes. You own the outcome, you can override anything in the
              platform.
            </Typography>
          </div>

          <div className="mb-5" id="IDisagreeWithTheGroupsForecastedEffect">
            <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              I disagree with the assumption of the group about the forecasted
              effect of 'yes' of a question - can I change that myself?
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              <strong>Yes.</strong> You click on the 'Negative' button under
              'Resolution Impact' in the 'Options' table next to the
              'Probability' graph.
              <br />
              <br />
              <strong>Yes.</strong> Resolution Impact is crowd sourced and
              elicited from the decision maker. As an Outcome Owner you simply
              need to navigate to a Question and click the 'Set Resolution
              Impact' button to decide whether it makes your Outcome more or
              less likely.
            </Typography>
          </div>

          <div className="mb-5" id="GraphsExplained">
            <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              That graph has a lot of buttons - what do they all do and what
              does it all mean?
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              The Forecast Dashboard is displayed for an Outcome in the
              Forecasting phase, assuming at least one Question has a resolution
              impact set. It is highly configurable but generally aims to show
              the current state of play.
              <br />
              <br />
              The label above the graph tells you if, in the collective opinion
              of the forecasters, the Outcome you entered is going to happen or
              not. If the Outcome is specific and measurable, the number is the
              forecast that the Outcome will in fact happen. If the Outcome is
              more general, then the number is a more general level of belief
              that the Outcome will occur.
              <br />
              <br />
              The Positive and Negative buttons simply toggle whether to show
              how Questions support your Outcome or how much they refute it.
              <br />
              <br />
              The Divide and Aggregate buttons show you how each individual
              question contributed to the overall forecast or belief about the
              Outcome. If there are lots of questions all impacting on the
              Outcome, this may not be very clear or insightful. It is more
              useful if there are only a few questions, or if one or two
              questions are in the opinion of the users much more important than
              the others.
              <br />
              <br />
              The Absolute button shows the cumulated impact in raw scores. This
              is not very insightful for most users, who are better off with the
              Relative button, which shows the percentage forecast or belief.
              <br />
              <br />
              The Filters button allows you to ignore some of the questions when
              you are doing your analysis. When you press that button, a list of
              questions with checkboxes appear underneath. Just untick those you
              want to be excluded from the analysis.
            </Typography>
          </div>

          <div className="mb-5" id="OutcomeOwnerStrategiesOverview">
            <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              What are strategies?
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              Strategies describe a course of action a decision maker could 
              take to potentially influence their Outcome. The crowd can 
              provide relevance evaluations between a strategy and an Outcome. 
              This is useful as it allows us to determine a 'probable 
              effectiveness' of a strategy IF and ONLY IF it is executed.
              <br />
              <br />
              You have control over who can see strategies and who can write 
              strategies on your Outcome. By default, it's only the Outcome
              owner (you!) and platform admins/moderators who can see and write
              them. This allows you to keep your strategies private if you need
              to! You can expand these permissions to allow the crowd to see, 
              evaluate, and comment on your strategies. You can expand the 
              permissions further and the crowd will be able to write strategies 
              for you.
              <br />
              <br />
              When you write a strategy on your own Outcome, you will be asked to
              provide a "likelihood prediction" - how likely you think it is that
              you will employ this strategy, expressed as a percentage. You can 
              update this prediction later, as it's common that it will change over 
              time. If the crowd can see your strategies, they will use this metric 
              to inform their questions, forecasting, evaluations etc.
              <br />
              <br />
              If another user writes a strategy on your Outcome, it will not have 
              a likelihood prediction yet. It's a good idea to fill out this metric
              on crowd strategies - even if it's a very rough estimate to begin with
               - because it will allow the crowd to gauge your attitude towards it 
              and start submitting relevance evaluations.
              <br />
              <br />
              Relevance evaluations on strategies are a measure of how closely linked
              a strategy is to the Outcome. A high relevance (close to 1) means that 
              employing the strategy would greatly positively influence the end result 
              of the Outcome. A low relevance (close to -1) means that employing the 
              strategy would greatly negatively influence the end result of the Outcome. 
              A neutral relevance (close to 0) means that the strategy will have little 
              impact on the Outcome either way. Please see the section on Relevance 
              Evaluations for more detail.
              {/* TODO add link to relevance evaluation section */}
              <br />
              <br />
              When you know whether or not you have implemented the strategy, you can 
              mark it as either "Implemented" or "Not Implemented". This will give it a
              colourful flag, allowing other users to see resolved strategies at a glance.
              If you mistakenly mark a strategy as implemented or not implemented, you can 
              undo it by clicking "Edit Implementation" again and selecting "Not Set".
            </Typography>
          </div>

          <div className="mb-5" id="OutcomeOwnerStrategiesCreation">
            <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              How do I create a strategy?
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              From the detail page of your Outcome, you will be able to see a
              list of questions, and maybe a question creation form (depending
              on the settings for your Outcome). Between the Outcome card and
              the question list, there is a button that says "Switch to strategy
              view". Clicking on this will switch you to the strategy view. 
              Clicking it again will switch you back to the question view.
              <br />
              <br />
              The strategy view has a strategy creation form at the top and a
              list of strategies underneath.
              <br />
              <br />
              Describe your potential strategy in the main strategy text box at the
              top of the form. See the section about "What makes a good strategy?"
              for tips and guidance.
              <br />
              <br />
              You will also be asked to submit the date you think the strategy will 
              be executed by, and who is the authority that you trust to accurately
              report whether the strategy has been executed or not. For strategies,
              the authority is likely to be you!
              <br />
              <br />
              After giving a likelihood prediction (how likely you think it is that
              you will employ this strategy), click Post. This will create your
              strategy in the system. You will be able to edit your strategy after 
              posting it if you need to. You can also delete any strategy that you created.
            </Typography>
          </div>

          <div className="mb-5" id="OutcomeOwnerWhatMakesAGoodStrategy">
            <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              What makes a good strategy?
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              A strategy will contain an undetermined number of conditions which 
              constitute a course of action. A date has to be one of these. The 
              smaller the number of elements within the strategy, the more specific 
              the feedback can be given about the efficacy of those elements - 
              i.e. a good strategy will concisely describe a course of action with a 
              low amount of variables.
            </Typography>
          </div>

          <div className="mb-5" id="AdvancedOutcomeSettings">
            <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              Advanced Outcome settings
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
                When creating or editing your Outcome, you can adjust the following 
                advanced settings:

              <List sx={{ listStyleType: 'disc', pl: 4 }}>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography >
                    Bucket preset. This option determines how users will forecast on the 
                    questions of your outcomes. See the forecasting section of the FAQ 
                    for more detail.
                  </Typography>
                </ListItem>

                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    Outcome owner can forecast / outcome owner can create a question. These
                    options determine whether you as the Outcome owner will be able to write 
                    your own questions and/or forecast on other users' questions. Note: if 
                    you enable these options, you cannot disable them later.
                  </Typography>
                </ListItem>

                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    Crowd can view strategies / crowd can submit strategies. These options 
                    determine whether the crowd (e.g. regular users) are able to see the 
                    strategies written on your Outcome and/or whether they are able to 
                    submit their own strategy suggestions. Note: if you enable these options, 
                    you cannot disable them later.
                  </Typography>
                </ListItem>

                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    Continue forecasting past end date. This option determines whether questions 
                    will remain open for forecasting past the Outcome's end date. If this is set 
                    to true, the Outcome will allow further forecasts until all questions are 
                    resolved. If it is set to false, no more forecasts can be submitted after the 
                    Outcome's end date. 
                  </Typography>
                </ListItem>

                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    AI forecasting. This option determines whether the questions on your Outcome 
                    are eligible to be sent to Hivemind AI forecasting/prediction bot. This bot 
                    will scrape the web for relevant articles, perform an analysis and return a 
                    prediction (with a justification comment). More information on the AI forecasting
                    process can be found here.
                    {/* TODO insert link to AI forecasting/bot section */}
                  </Typography>
                </ListItem>

                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    External forecasting. If this option is enabled, your Outcome's questions will be 
                    posted to an external forecasting platform to gain predictions from a different 
                    audience. You must speak to the platform administrator before enabling this option, 
                    because there is a lot of manual processing involved. 
                  </Typography>
                </ListItem>

                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    Outcome group(s). This option determines which groups of users can interact with 
                    your outcome.
                  </Typography>
                </ListItem>
              </List>
            </Typography>
          </div>

        </AccordionDetails>
      </Accordion>

      <Accordion
        key="questioner"
        expanded={expandedPanels['questioner'] || false}
        onChange={handleChange('questioner')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={'panel-details-questioners'}
          id={'panel-header-questioners'}
          sx={{ backgroundColor: 'background.secondary' }}>
          <div>
            <Typography
              sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}
              className="text-2xl font-black">
              Questioners
            </Typography>

            <Typography>Details for Questioners</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails
          id={'panel-details-questioners'}
          sx={{ backgroundColor: 'background.secondary' }}>
          <div className="mb-5" id="WhatShouldIDoOnHivemind">
            <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              I am a user, what should I do on Hivemind?
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              Users can do one of four things: look at an outcome that has been
              posted and write an insightful question about it; evaluate other
              users' questions and select those which you think will be the most
              informative; predict the answer to those questions; and check how
              your questions and forecasts compare to those of other users.
            </Typography>
          </div>

          <div className="mb-5" id="WhatMakesAGoodQuestion">
            <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              What makes a good question?
            </Typography>
            <Box
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              <Typography>
                Good question 😉.
                <br />
                We think there are five elements to a really good question:
              </Typography>
              <List sx={{ listStyleType: 'disc', pl: 4 }}>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    Is it relevant to the outcome the decision-maker wants? We
                    can actually measure this objectively to an extent, but the
                    simple answer is "does the answer to this question make the
                    outcome more or less likely?". If it doesn't make a
                    difference either way, how can it be really relevant?
                  </Typography>
                </ListItem>

                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    Is it resolvable? That means is it possible for it to be
                    definitively answered both in theory and in practice - you
                    know where to find out what the answer is when it becomes
                    definitive. The platform provides an input mask to help you
                    write a resolvable question. The simplest form is
                    <i> will (something) happen by a (certain date)? </i>
                    You then add in the website where the information will be
                    posted when it happens. The most perfect questions are
                    websites with dashboards and tables where you are referring
                    to a specific number. But if that isn't possible for your
                    question it doesn't matter. Perhaps it will be whether a
                    certain thing is announced on a website you trust. Perhaps
                    it will be a real person telling the outcome owner the
                    answer.
                  </Typography>
                </ListItem>

                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    Does it reduce ignorance? The easiest way to explain this is
                    that the best questions are very uncertain - perhaps someone
                    forecasting them will think of them as 50:50 questions or
                    close to that - but there will also be new information
                    coming in about it. For example, the result of a sport's
                    match in a week's time might be a 50:50, but maybe there
                    will be information about players recovering from injury, or
                    new tactics, or pitch conditions which will help someone
                    predict the result of the match before it takes place. On
                    the other hand, there might be little you can find out that
                    would change your belief about a coin toss being 50:50
                    before it happens (unless you suspect cheating, of course…).
                  </Typography>
                </ListItem>

                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    Is it timely? Usually it will be best to ask a question that
                    you know will be answered before the Outcome End date - the
                    Outcome end date is when the outcome owner will have to make
                    a decision about that outcome. So, for example, if the
                    outcome is about a technology that will definitely not be
                    ready for 10 years, the best question will be about some
                    intermediate stage which will be ready before the outcome
                    date - that will give the best indication that the
                    technology is on track at this stage. Conversely, questions
                    which will be known well before the outcome ends will not
                    usually be as good as those which finish just before the
                    outcome ends. The technical reasons take a bit of explaining
                    but think of it as everyone having time to factor in what
                    happens tomorrow but when the decision happens next week,
                    there is still a lot that can change. It must be stressed
                    that this element in particular is very much a guideline,
                    there may be occasions when it is better to not take this
                    advice.
                  </Typography>
                </ListItem>

                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    Is it novel? All other things being equal, the less obvious
                    question is better (since the outcome owner is less likely
                    to have come up with it themselves). In measurement terms,
                    and appreciating that this is a developing concept, the
                    'distance' from the actual outcome to the question. So, if
                    the most obvious question to ask about the outcome is will
                    (that outcome) happen, then the least obvious question is
                    the one which appears to be very tangential to the outcome,
                    but is actually closely related because of a very opaque but
                    very strong causal relationship between a question and the
                    outcome.
                  </Typography>
                </ListItem>
              </List>
            </Box>
          </div>

          <div className="mb-5">
            <Typography
              sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}
              id="WhyClosedQuestions">
              I have always been told that 'open' questions are better than
              'closed' questions: why do I have to ask 'closed' questions?
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              Open questions are great for opening discussions or beginning to
              think about topics. But closed questions are important for two
              reasons: one, there is much less opportunity to argue about the
              answer; and two, a closed question is really challenging you to
              say: what, <i>specifically</i>, would make me more or less sure
              about the answer to a question? How, <i>specifically</i>, should a
              decision-maker think about making a decision based on these
              questions?
            </Typography>
          </div>

          <div className="mb-5" id="WhyCantIAskedWhenItWillHappen">
            <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              On some of the forecasting platforms, like Good Judgement Open and
              Metaculus, they ask questions about when something will happen -
              why doesn't Hivemind allow this?
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              These kind of continuous questions are great for really testing
              the skills of forecasters and, perhaps, providing useful
              information to the community. But they lack a final 'so what?'
              Whether AGI happens in 2028 or 2032 is interesting…but what
              conclusions should you draw from that? Hivemind is optimized to
              deliver the questions with the 'so what' to the decision-maker at
              all times.
            </Typography>
          </div>

          <div className="mb-5" id="HowDoICreateAQuestion">
            <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              How do I create a question?
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              To create a Question, simply visit the Outcomes Page and look for
              an Outcome in the Generation phase.
              <br />
              Once you have found an Outcome you want to submit a Question for,
              navigate to it and you will see the create a question form. This
              allows you to create Questions following the structured or free
              form format. We recommend the structured format as it helps to
              ensure your Question is resolvable. For in depth breakdowns of
              what information to provide see{' '}
              <Link href="?section=questioner#HowToWriteAStructuredQuestion">
                Structured Questions
              </Link>
              <> or </>{' '}
              <Link href="?section=questioner#HowToWriteAFreeFormQuestion">
                Free Form Questions
              </Link>
              .
            </Typography>
          </div>

          <div className="mb-5" id="HowDoIEvaluateAQuestion">
            <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              How do I evaluate a question?
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              The obvious answer is do you think it has the make-up of a good
              question, which we discuss above. But there is a simpler approach:
              <br />
              <br />
              If you were to make a prediction about the answer to the question,
              expressed as a percentage, how close would that prediction be to
              50:50? The closer, the better. Really think about the question -
              how <i>exactly</i> is it worded? That might make a big difference
              to your prediction.
              <br />
              <br />
              Do you think that there will be many - and at least two - pieces
              of news that will be reported about this question that may make
              you more confident about your prediction? If there aren't, it
              isn't likely to be a good question.
              <br />
              <br />
              At the time of writing, we are considering making this simpler
              approach a more integral part of the Hivemind evaluation system.
            </Typography>
          </div>

          <div className="mb-5" id="HowToWriteAStructuredQuestion">
            <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              How to write a Structured Question?
            </Typography>
            <Box
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              <Typography>
                Writing a Structured Question might look more difficult than a
                free form initially, but it makes writing a resolvable Question
                much easier by breaking down a Question into more specific
                fields.
                <br />
                To create a structured question, you must provide the following
                information:
              </Typography>
              <List sx={{ listStyleType: 'disc', pl: 4 }}>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    <strong>Will</strong>: All structured questions start with
                    the word will.
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    <strong>Event</strong>: An event in the form of a possible
                    condition that if met, results in truthful resolution. For
                    example, 'Joe Biden be re-elected as president of the US'
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    <strong>State</strong>: An optional verb that can be added
                    to your event, i.e. 'occur'.{' '}
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    <strong>Date Preposition</strong>: A preposition to combine
                    with a date which together indicate when the event needs to
                    have occurred by to qualify for truthful resolution.
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    <strong>Date</strong>: The date by which the event needs to
                    have occurred by to qualify for truthful resolution.
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    <strong>Source</strong>: A description of the source that
                    will verify the Question, e.g. BBC News.
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    <strong>Link Authority</strong>: An optional link to the
                    source.
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    <strong>Source Date Preposition</strong>: A preposition to
                    combine with a date which together indicate when the source
                    must have reported on the outcome of the event.
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    <strong>Source Date</strong>: The date by which the source
                    must have reported on the outcome of the event.{' '}
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    <strong>Relevance Relationship</strong>: Whether this
                    Question makes the Outcome more or less likely.
                  </Typography>
                </ListItem>
              </List>
            </Box>
          </div>

          <div className="mb-5" id="HowToWriteAFreeFormQuestion">
            <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              How to write a Free Form Question?
            </Typography>
            <Box
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              <Typography>
                Writing a Free Form Question gives you more flexibility than a
                Structured Question, but more care must be taken to make sure it
                meets the resolvability requirements. To create a free form
                Question, you must provide the following information:
              </Typography>
              <List sx={{ listStyleType: 'disc', pl: 4 }}>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    <strong>Title</strong>. A measurable, verifiable statement
                    about the future, which should start with 'Will' and end
                    with a question mark!
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    <strong>Resolution Date</strong>. A date by which the
                    Question will definitely have resolved - i.e. the date by
                    which it will definitely be known whether the state of
                    affairs described in 'Resolution'
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    <strong>Resolution Criteria</strong>. A precise description
                    of what will make the Question resolve as true or false.
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    <strong>Relevance</strong>. A brief explanation of why this
                    Question is relevant to the Outcome.
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    <strong>Source</strong>. A link to or a description of the
                    authority that will be used to decide resolution.
                  </Typography>
                </ListItem>
              </List>
            </Box>
          </div>
          <div className="mb-5" id="QuestionerStrategiesOverview">
            <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              What are strategies?
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              Strategies describe a course of action a decision maker could 
              take to potentially influence their Outcome. The crowd can 
              provide relevance evaluations between a strategy and an Outcome. 
              This is useful as it allows us to determine a 'probable 
              effectiveness' of a strategy IF and ONLY IF it is executed.
              <br />
              <br />
              Outcome owners have control over who can see strategies and who can 
              write strategies on their Outcomes. They can expand the permissions 
              to allow "the crowd" (you!) to see, evaluate, and comment on strategies.
              If they expand the permissions further, the crowd will be able
              to write strategies too.
              <br />
              <br />
              Outcome owners can provide a metric called a "likelihood prediction"
               - how likely they think it is that they will employ this strategy, 
              expressed as a percentage. They can update this prediction later, 
              as it's common that it will change over time. The crowd will use this 
              metric to inform their questions, forecasting, evaluations etc.
              <br />
              <br />
              Relevance evaluations on strategies are a measure of how closely linked
              a strategy is to the Outcome. A high relevance (close to 1) means that 
              employing the strategy would greatly positively influence the end result 
              of the Outcome. A low relevance (close to -1) means that employing the 
              strategy would greatly negatively influence the end result of the Outcome. 
              A neutral relevance (close to 0) means that the strategy will have little 
              impact on the Outcome either way. Please see the section on Relevance 
              Evaluations for more detail.
              {/* TODO add link to relevance evaluation section */}
              <br />
              <br />
              When the Outcome owner knows whether or not they have implemented the strategy, 
              they can mark it as either "Implemented" or "Not Implemented". This will give it a
              colourful flag, allowing other users to see resolved strategies at a glance.
            </Typography>
          </div>

          <div className="mb-5" id="QuestionerStrategiesCreation">
            <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              How do I create a strategy?
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              From the detail page of an Outcome, you will be able to see a
              list of questions and a question creation form. Between the 
              Outcome card and the question list, there may be a button 
              that says "Switch to strategy view". Clicking on this will 
              switch you to the strategy view. Clicking it again will switch 
              you back to the question view. If there is no such button, it
              is likely the Outcome owner does not permit the crowd to view 
              strategies on their Outcome.
              <br />
              <br />
              The strategy view may have a strategy creation form at the top and a
              list of strategies underneath. If you cannot see the strategy 
              creation form, it is likely the Outcome owner has allowed the crowd 
              to see, comment on and evaluate their strategies, but not to submit 
              additional strategies.
              <br />
              <br />
              When creating a strategy, describe your main point in the strategy 
              text box at the top of the form. See the section about "What makes 
              a good strategy?" for tips and guidance.
              <br />
              <br />
              You will also be asked to submit the date you think the strategy will 
              be executed by, and who is the authority that you trust to accurately
              report whether the strategy has been executed or not. For strategies,
              the authority is likely to be the Outcome owner.
              <br />
              <br />
              Clicking Post will create your strategy in the system. You will be able 
              to edit your strategy after posting it if you need to. You can also 
              delete any strategy that you created.
            </Typography>
          </div>

          <div className="mb-5" id="QuestionerWhatMakesAGoodStrategy">
            <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              What makes a good strategy?
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              A strategy will contain an undetermined number of conditions which 
              constitute a course of action. A date has to be one of these. The 
              smaller the number of elements within the strategy, the more specific 
              the feedback can be given about the efficacy of those elements - 
              i.e. a good strategy will concisely describe a course of action with a 
              low amount of variables.
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion
        key="scoring"
        expanded={expandedPanels['scoring'] || false}
        onChange={handleChange('scoring')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={'panel-details-scoring'}
          id={'panel-header-scoring'}
          sx={{ backgroundColor: 'background.secondary' }}>
          <div>
            <Typography
              sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}
              className="text-2xl font-black">
              Scoring
            </Typography>

            <Typography>How scoring and evaluations work</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails
          id={'panel-details-scoring'}
          sx={{ backgroundColor: 'background.secondary' }}>
          <div className="mb-5" id="WhatIsAQuestionScore">
            <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              What is a Question Score?
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              A Question Score is an objective measurement of information value
              generated by a Question. It is intended to act as the objective
              that Questions should be designed around. To create a good scoring
              Question, see the section on{' '}
              <Link href="?section=questioner#WhatMakesAGoodQuestion">
                What makes a Good Question?
              </Link>
              <br />
              A breakdown of how its calculated:
              <br />
              The Question Score is based off variation in a Question
              probability, so consider {'{p0, p1, p2…pn}'} are probabilities
              entries given to a question with n being the maximum entries
              before resolution.
              <br />
              Variation is then calculated by:
              <br />
              {QuestionScoreFormula()}
              Weights are then incorporated which manage the influence of time
              on the score to negate potential bias and exploitation.
              <br />
              Question Scores then fall into one of five categories ranging from
              'Very Low' to 'Very High'. These categories are empirically
              derived from historical forecasting questions.
            </Typography>
          </div>

          <div className="mb-5" id="WhatIsAnEvaluationScore">
            <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              What is an Evaluation Score?
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              An Evaluation Score is based on how close your Evaluation was to
              the actual observed Question Score. You will get a score of '5' if
              the Score fell into the category you predicted, '4' if in an
              adjacent category, and so on. The higher this number, the better
              your Evaluation Score.
            </Typography>
          </div>

          <div className="mb-5" id="WhatIsAUserGenerationScore">
            <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              What is a User Generation Score?
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              A User's Generator Score shows the average Question score of
              Questions submitted by that user. This score is split into current
              and confirmed. Current shows the average of all Questions included
              those still in progress, whereas Confirmed shows scores of only
              completed questions.
            </Typography>
          </div>

          <div className="mb-5" id="WhatIsAUserEvaluationScore">
            <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              What is a User Evaluation Score?
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              A User's Evaluation Score will equal the average Evaluation Score
              across all Evaluations made. This score is split into current and
              confirmed. Current shows the average Evaluation Scores across all
              Questions included those still in progress, whereas Confirmed
              includes only completed questions. Note that there is a minimum
              number of Evaluations needed to qualify for a leaderboard
              position. By default, this is 5 Evaluations.
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>


      <Accordion
        key="AccountManagement"
        expanded={expandedPanels['AccountManagement'] || false}
        onChange={handleChange('AccountManagement')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={'panel-details-account-management'}
          id={'panel-header-account-management'}
          sx={{ backgroundColor: 'background.secondary' }}>
          <div>
            <Typography
              sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}
              className="text-2xl font-black">
              Account Management
            </Typography>

            <Typography>Performing account management actions</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails
          id={'panel-details-scoring'}
          sx={{ backgroundColor: 'background.secondary' }}>
          <div className="mb-5" id="HowToRegister">
            <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              How to register for a Hivemind account
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              To register for a normal questioner account on Hivemind, click on 
              the profile icon in the top right corner of the site, then click
              "Register". If you do not see this option, Hivemind may not be 
              open to public user registration. Contact the system administrator.
              Only questioner accounts can be created via this method. If you 
              need different permissions - i.e. outcome owner, moderator, observer - 
              contact the system administrator.
              <br/>
              <br/>
              Your username:
              <List sx={{ listStyleType: 'disc', pl: 4 }}>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    Must be unique
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    Must be 6-50 characters long
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    Must start with a letter
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    Must not contain any special characters or spaces
                  </Typography>
                </ListItem>
              </List>
              <br/>
              <br/>
              Your password:
              <List sx={{ listStyleType: 'disc', pl: 4 }}>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    Must be at least 8 characters long
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    Must contain a digit
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    Must contain an upper case letter
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    Must contain a lower case letter
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    Must contain a special character
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    Must not contain a space
                  </Typography>
                </ListItem>
              </List>
              <br/>
              <br/>
              Depending on the platform settings, you may then be sent an email 
              containing a verification code. Follow the link in the email and 
              supply your unique verification code to complete your registration.
            </Typography>
          </div>

          <div className="mb-5" id="ResetPassword">
            <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              Reset password
            </Typography>
            <Typography
              sx={{
                p: 1.2,
                mt: 0.6,
                borderRadius: '0.5rem',
                bgcolor: 'background.paper'
              }}>
              If you have forgotten your password and need to reset it, navigate 
              to the login page then click "Reset password". Enter your username 
              or the email address associated with your account. You will be sent 
              an email containing a unique link which will allow you to reset your 
              password. Please be patient when waiting for this email, it may take 
              a few minutes to arrive. If it has not arrived after 5 minutes and 
              you have checked your junk folder, please contact your system 
              administrator who will be able to change your password for you.
              <br/>
              <br/>
              If you have not forgotten your password but you still want to change
              it, you can choose to follow the method above. Alternatively you can log in, 
              then click on the profile icon in the top right corner of the site, 
              then click "Account Settings". On the "Account" tab, you will be
              able to reset your password.
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
