import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Checkbox } from '@mui/material';
import ukLocale from 'date-fns/locale/en-GB';
import moment from 'moment';
import { useEffect, useState } from 'react';

export default function TemporalMeasure({ measure, value, setValue }) {
  const [preferNotToSay, setPreferNotToSay] = useState(false);
  const togglePreferNotToSay = () => {
    changevalue(null)
    setPreferNotToSay(!preferNotToSay)
  }

  useEffect(() => {
    setValue(maxDate);
  }, []);

  const [maxDate] = useState(moment(measure.date_range.max_date).toDate());
  const changevalue = (value) =>{
    setValue(value);
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ukLocale}>
        <DateField
          label=""
          value={value}
          onChange={(newValue) => changevalue(newValue)}
          disabled={preferNotToSay}
        />
      </LocalizationProvider>
      <div className="items-center flex ml-2 mt-1 mb-1">
        <Checkbox checked={preferNotToSay} onClick={togglePreferNotToSay} />
        <p>Prefer not to say</p>
      </div>
    </>
  );
}
