export const graphColors = [
  {
    mid: '#9ad6f9',
    darker: '#7ea8c0',
    lighter: 'rgba(201,240,249,0.4)' //'#c9f0f9'
  },
  {
    mid: '#d3b1ff',
    darker: '#a690c3',
    lighter: 'rgba(244, 220, 252,0.4)' // '#f4dcfc'
  },
  {
    mid: '#ff9154',
    darker: '#bc754f',
    lighter: 'rgba(249, 214, 168,0.4)' //'#f9d6a8'
  },
  {
    mid: '#b3e066',
    darker: '#8a9c65',
    lighter: 'rgba(227, 242, 184,0.4)' // '#e3f2b8'
  },
  {
    mid: '#8f98fa',
    darker: '#7b7baa',
    lighter: 'rgba(221, 209, 251,0.4)' // '#ddd1fb'
  },
  {
    mid: '#88fabe',
    darker: '#84b89a',
    lighter: 'rgba(201, 254, 226,0.4)' //'#c9fee2'
  },
  {
    mid: '#ff7b6b',
    darker: '#c48277',
    lighter: 'rgba(255, 204, 177,0.4)' // '#ffccb1'
  },
  {
    mid: '#ff8cc3',
    darker: '#bb7295',
    lighter: 'rgba(252, 208, 239,0.4)' //'#fcd0ef'
  },
  {
    mid: '#ffa600',
    darker: '#c38736',
    lighter: 'rgba(248, 223, 153,0.4)' // '#f8df99'
  },
  {
    mid: '#f07edb',
    darker: '#a9749d',
    lighter: 'rgba(248, 203, 244,0.4)' // '#f8cbf4'
  },
  {
    mid: '#ff8f40',
    darker: '#b07755',
    lighter: 'rgba(249, 214, 161,0.4)' // '#f9d6a1'
  },
  {
    mid: '#488f31',
    darker: '#4c6b40',
    lighter: 'rgba(180, 209, 162,0.4)' // '#b4d1a2'
  },
  {
    mid: '#faf492',
    darker: '#9a9569',
    lighter: 'rgba(252, 251, 202,0.4)' // '#fcfbca'
  },
  {
    mid: '#fab992',
    darker: '#a17e6a',
    lighter: 'rgba(249, 227, 198,0.4)' // '#f9e3c6'
  },
  {
    mid: '#de425b',
    darker: '#924c53',
    lighter: 'rgba(250, 180, 189,0.4)' // '#fab4bd'
  },
  {
    mid: '#35498f',
    darker: '#464b70',
    lighter: 'rgba(175, 177, 210,0.4)' // '#afb1d2'
  },
  {
    mid: '#de4343',
    darker: '#914b46',
    lighter: 'rgba(244, 185, 155,0.4)' // '#f4b99b'
  },
  {
    mid: '#f4afa7',
    darker: '#9b7470',
    lighter: 'rgba(250, 221, 208,0.4)' // '#faddd0'
  },
  {
    mid: '#8a53e2',
    darker: '#745b99',
    lighter: 'rgba(224, 180, 238,0.4)' // '#e0b4ee'
  },
  {
    mid: '#C2185B',
    darker: '#670d30',
    lighter: '#ea5690' // '#f9f9f9'
  },
  {
    mid: '#689F38',
    darker: '#37541d',
    lighter: '#9bcd70' // '#f9f9f9'
  },
  {
    mid: '#be8904',
    darker: '#5a4102',
    lighter: '#fbc02d' // '#f9f9f9'
  },
  {
    mid: '#6d4c41',
    darker: '#2d1f1b',
    lighter: '#a87b6c' // '#f9f9f9'
  },
  {
    mid: '#0288D1',
    darker: '#01466c',
    lighter: '#3cb9fd' // '#f9f9f9'
  },
  {
    mid: '#AFB42B',
    darker: '#5f6217',
    lighter: '#d7db6a' // '#f9f9f9'
  },
  {
    mid: '#7B1FA2',
    darker: '#3a0f4c',
    lighter: '#b14bdc' // '#f9f9f9'
  },
  {
    mid: '#546E7A',
    darker: '#2a383e',
    lighter: '#87a1ad' // '#f9f9f9'
  },
  {
    mid: '#B71C1C',
    darker: '#5f0e0e',
    lighter: '#e55454' // '#f9f9f9'
  },
  {
    mid: '#FFEB3B',
    darker: '#d4be00',
    lighter: '#fff5a1' // '#f9f9f9'
  },
  {
    mid: '#76ff03',
    darker: '#479c00',
    lighter: '#adff69' // '#f9f9f9'
  },
  {
    mid: '#18ffff',
    darker: '#00b1b1',
    lighter: '#7effff' // '#f9f9f9'
  },
  {
    mid: '#FF5252',
    darker: '#eb0000',
    lighter: '#ffb8b8' // '#f9f9f9'
  },
  {
    mid: '#424242',
    darker: '#0f0f0f',
    lighter: '#757575' // '#f9f9f9'
  },
  {
    mid: '#8c9eff',
    darker: '#2648ff',
    lighter: '#82B1FF' // '#f9f9f9'
  },
  {
    mid: '#FFD600',
    darker: '#998000',
    lighter: '#ffe666' // '#f9f9f9'
  },
  {
    mid: '#FF9E80',
    darker: '#ff501a',
    lighter: '#ffc5b3' // '#f9f9f9'
  },
  {
    mid: '#004d40',
    darker: '#001a16',
    lighter: '#00806a' // '#f9f9f9'
  },
  {
    mid: '#C51162',
    darker: '#960d4b',
    lighter: '#ec1d7a' // '#f9f9f9'
  },
  {
    mid: '#00C853',
    darker: '#00953e',
    lighter: '#00fb68' // '#f9f9f9'
  },
  {
    mid: '#f1f1f1',
    darker: '#909090',
    lighter: 'rgba(249, 249, 249,0.4)' // '#f9f9f9'
  }
];
