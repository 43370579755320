import {
  CalendarMonthOutlined,
  ChatBubbleOutlineOutlined,
  EditOutlined,
  EventOutlined,
  QuestionMarkOutlined,
  StarOutlineOutlined
} from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  Link,
  Tab,
  Typography,
  useTheme
} from '@mui/material';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { FiStar } from 'react-icons/fi';
import { TbLayoutAlignMiddle } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { AuthContext } from '../../App';
import {
  fetchAllUserForecasts,
  fetchOutcomes,
  fetchQuestionsByActivity,
  selectAllOutcomes,
  selectCommentsByUserId,
  selectCurrentUserForecastsByUser,
  selectEvaluationsByUserId,
  selectOutcomesByUserId,
  selectQuestionsByUserId,
  fetchStrategiesByActivity,
  selectStrategiesByUserId
} from '../../store/slices/outcomeSlice';
import OutcomeStatusText from '../other/OutcomeStatusText';

export default function ActivitySummary({ userId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);
  const theme = useTheme();
  const [fetchQuestionsStatus, setFetchQuestionsStatus] = useState('idle');
  const [fetchStrategiesStatus, setFetchStrategiesStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');
  const outcomeStatus = useSelector((state) => state.outcomes.outcomes.status);
  const userForecastStatus = useSelector(
    (state) => state.outcomes.userForecasts.status
  );
  const questions = useSelector((_state) =>
    selectQuestionsByUserId(_state, userId)
  );
  const strategies = useSelector((_state) =>
    selectStrategiesByUserId(_state, userId)
  );
  const evaluations = useSelector((_state) =>
    selectEvaluationsByUserId(_state, userId)
  );
  const comments = useSelector((_state) =>
    selectCommentsByUserId(_state, userId)
  );
  const outcomes = useSelector((state) => selectAllOutcomes(state));

  const userOutcomes = useSelector((state) =>
    selectOutcomesByUserId(state, userId)
  );
  const questionEntities = useSelector(
    (state) => state.outcomes.questions.entities
  );

  const currentUserForecasts = useSelector((state) =>
    selectCurrentUserForecastsByUser(state, userId)
  );

  const [tab, setTab] = useState('2');

  const changeTab = (event, newValue) => setTab(newValue);

  useEffect(() => {
    if (outcomeStatus === 'idle') {
      const token = localStorage.getItem('auth_token');
      dispatch(fetchOutcomes({ auth_token: token }));
    }
  }, [outcomeStatus, dispatch]);

  useEffect(() => {
    if (userForecastStatus === 'idle') {
      const token = localStorage.getItem('auth_token');
      dispatch(fetchAllUserForecasts({ auth_token: token }));
    }
  }, [userForecastStatus, dispatch]);

  useEffect(() => {
    let isMounted = true;
    setErrorMessage('');
    if (isMounted) {
      try {
        if (fetchQuestionsStatus === 'idle') {
          setFetchQuestionsStatus('pending');
          const token = localStorage.getItem('auth_token');
          dispatch(
            fetchQuestionsByActivity({
              userId: userId,
              auth_token: token
            })
          ).unwrap();
        }
      } catch (err) {
        setErrorMessage(`Failed to get questions: ${err.message}`);
      } finally {
        if (isMounted) {
          setFetchQuestionsStatus('succeeded');
          isMounted = false;
        }
      }
    }
  }, [fetchQuestionsStatus, dispatch, userId]);

  useEffect(() => {
    let isMounted = true;
    setErrorMessage('');
    if (isMounted) {
      try {
        if (fetchStrategiesStatus === 'idle') {
          setFetchStrategiesStatus('pending');
          const token = localStorage.getItem('auth_token');
          dispatch(
            fetchStrategiesByActivity({
              userId: userId,
              auth_token: token
            })
          ).unwrap();
        }
      } catch (err) {
        setErrorMessage(`Failed to get strategies: ${err.message}`);
      } finally {
        if (isMounted) {
          setFetchStrategiesStatus('succeeded');
          isMounted = false;
        }
      }
    }
  }, [fetchStrategiesStatus, dispatch, userId]);

  const getQuestionStatusColour = (status) => {
    status = status !== 'Completed' ? status : 'Closed';
    switch (status) {
      case 'Pending':
        return theme.palette.statuses.mid1;
      case 'Rejected':
        return theme.palette.statuses.mid5;
      case 'Not Submitted':
        return theme.palette.statuses.mid2;
      case 'Accepted':
        return theme.palette.statuses.mid3;
      case 'Submitted':
        return theme.palette.statuses.mid3;
      case 'Closed':
        return theme.palette.statuses.mid4;
      case 'Duplicate':
        return theme.palette.statuses.mid6;
      default:
        return theme.palette.statuses.mid7;
    }
  };

  const getOutcomeStatusColour = (status) => {
    switch (status) {
      case 'Generation':
        return theme.palette.statuses.mid1;
      case 'Evaluation':
        return theme.palette.statuses.mid2;
      case 'Closed':
        return theme.palette.statuses.mid4;
      case 'Forecasting':
        return theme.palette.statuses.mid3;
      default:
        return theme.palette.statuses.mid5;
    }
  };
  const getResolutionColour = (current_resolution_name) => {
    switch (current_resolution_name) {
      case 'True':
        return theme.palette.statuses.mid1;
      case 'Implemented':
        return theme.palette.statuses.mid3;
      case 'False':
        return theme.palette.statuses.mid5;
      case 'Not Implemented':
        return theme.palette.statuses.mid5;
      case 'Ambiguous':
        return theme.palette.statuses.mid4;
      case 'Not Set':
        return theme.palette.success.main;
      default:
        return null;
    }
  };

  const outcomeTitleClick = (id, questionId = null) => {
    if (questionId) {
      navigate(`/outcome/${id}#${questionId}`);
    } else {
      navigate(`/outcome/${id}`);
    }
  };

  const timeSince = (time) => {
    return moment.utc(time, 'YYYY-MM-DDThh:mm:ss').local().fromNow();
  };

  const categoryTextColor = (category) => {
    switch (category) {
      case 'Very Low':
        return 'text-red-500';
      case 'Low':
        return 'text-orange-500';
      case 'Medium':
        return 'text-blue-500';
      case 'High':
        return 'text-teal-500';
      case 'Very High':
        return 'text-green-500';
      default:
        return 'bg-blue-500';
    }
  };

  const evaluationScoreCategoryTextColor = (category) => {
    switch (category) {
      case 'Furthest':
        return 'text-red-500';
      case 'Far':
        return 'text-orange-500';
      case 'Average':
        return 'text-blue-500';
      case 'Close':
        return 'text-teal-500';
      case 'Correct':
        return 'text-green-500';
      default:
        return 'bg-blue-500';
    }
  };

  return (
    <Card sx={{ p: 3.6, m: 1.2, borderRadius: '0.5rem' }}>
      <TabContext value={tab} sx={{ width: '100%' }}>
        <div className="flex justify-between">
          <Typography sx={{ fontWeight: 600, fontSize: '1.4rem', mb: 2 }}>
            Activity Summary
          </Typography>
          {errorMessage && (
            <Typography color="error">{errorMessage}</Typography>
          )}

          <Box sx={{}}>
            <TabList onChange={changeTab}>
              {userOutcomes.length > 0 && <Tab label="Outcomes" value="1" />}
              <Tab label="Questions" value="2" />
              <Tab label="Evaluations" value="3" />
              <Tab label="Comments" value="4" />
              {(userId === userData.id ||
                userData.role === 'Moderator' ||
                userData.role === 'Admin') && (
                  <Tab label="Forecasts" value="5" />
                )}
              <Tab label="Strategies" value="6" />
            </TabList>
          </Box>
        </div>
        <Card
          sx={{
            borderRadius: '0.5rem',
            overflowY: 'auto',
            overflowX: 'hidden',
            height: '24rem',
            backgroundColor: 'background.default'
          }}>
          <TabPanel value={'1'} sx={{ p: 1 }}>
            {userOutcomes.length > 0 ? (
              userOutcomes.map((outcome) => (
                <div key={outcome.id} className="border-b border-gray-300">
                  <div className="flex items-center mt-1">
                    <Button
                      onClick={() => outcomeTitleClick(outcome.id)}
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '1.1rem',
                        ml: 1.2,
                        textAlign: 'left',
                        textTransform: 'none'
                      }}>
                      {outcome.title}
                    </Button>

                    {outcome.statuses.map((status, index) => (
                      <Typography
                        key={index}
                        sx={{
                          borderColor: getOutcomeStatusColour(status),
                          backgroundColor: getOutcomeStatusColour(status),
                          py: 0,
                          px: 0.3,
                          fontSize: '0.95rem',
                          mx: 0.6
                        }}
                        className={`border-1 rounded text-white font-normal text-xs px-1 ml-1`}>
                        {status}
                      </Typography>
                    ))}
                  </div>
                  <div className="text-xs font-light px-4 pb-2 ml-2 flex items-center">
                    {outcome.statuses.map((status, index) => (
                      <OutcomeStatusText
                        status={status}
                        outcome_id={outcome.id}
                        key={index}
                        micro
                      />
                    ))}
                    <Typography
                      sx={{
                        mr: 1.2,
                        minWidth: '20%',
                        fontSize: '0.875rem',
                        p: 0,
                        display: 'flex',
                        alignItems: 'center'
                      }}
                      color="text.secondary">
                      <QuestionMarkOutlined
                        fontSize="inherit"
                        sx={{ mr: 0.3, mb: 0.3 }}
                      />
                      {outcome.questions.length} Questions posted
                    </Typography>
                  </div>
                </div>
              ))
            ) : (
              <div className="px-4 pt-2 text-lg">
                <Typography>No outcomes could be found.</Typography>
              </div>
            )}
          </TabPanel>
          <TabPanel value={'2'}>
            {questions.length > 0 ? (
              questions.map((question) => (
                <div key={question.id} className="border-b border-gray-300">
                  <Button
                    onClick={() =>
                      outcomeTitleClick(question.outcome_id, question.id)
                    }
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '1rem',
                      ml: 1.2,
                      textAlign: 'left',
                      textTransform: 'none'
                    }}>
                    {outcomes?.find((x) => x.id === question.outcome_id).title}
                  </Button>
                  <li className="w-full flex items-start justify-start flex-col px-4 pb-2 ml-2 rounded-t-lg">
                    <Link underline="hover" href={`/questions/${question.id}`}>
                      <div className="flex items-center">
                        <Typography
                          sx={{
                            fontSize: '1.1rem',
                            ml: 1.2,
                            textAlign: 'left',
                            textTransform: 'none'
                          }}
                          className="text-lg font-normal inline-flex items-start max-h-42 overflow-y-auto">
                          {question.question_text}
                        </Typography>
                        <Typography
                          sx={{
                            borderColor: getQuestionStatusColour(
                              question.status
                            ),
                            backgroundColor: getQuestionStatusColour(
                              question.status
                            ),
                            py: 0,
                            px: 0.2,
                            fontSize: '0.85rem',
                            mx: 0.6
                          }}
                          className={`border-1 rounded text-white font-normal text-xs px-1 ml-1`}>
                          {question.status !== 'Completed'
                            ? question.status
                            : 'Closed'}
                        </Typography>
                      </div>
                    </Link>
                    <div className="text-xs font-light flex items-center">
                      <Typography
                        sx={{
                          mr: 1.2,
                          fontSize: '0.875rem',
                          p: 0,
                          display: 'flex',
                          alignItems: 'center'
                        }}
                        color="text.secondary">
                        <CalendarMonthOutlined
                          fontSize="inherit"
                          sx={{ mr: 0.3, mb: 0.3 }}
                        />
                        Posted {timeSince(question.created_at)}
                      </Typography>
                      <Typography
                        sx={{
                          mr: 1.2,
                          fontSize: '0.875rem',
                          p: 0,
                          display: 'flex',
                          alignItems: 'center'
                        }}
                        color="text.secondary">
                        <StarOutlineOutlined
                          fontSize="inherit"
                          sx={{ mr: 0.3, mb: 0.3 }}
                        />
                        {question.evaluations.length} Evaluations
                      </Typography>
                      <Typography
                        sx={{
                          mr: 1.2,
                          fontSize: '0.875rem',
                          p: 0,
                          display: 'flex',
                          alignItems: 'center'
                        }}
                        color="text.secondary">
                        <ChatBubbleOutlineOutlined
                          fontSize="inherit"
                          sx={{ mr: 0.3, mb: 0.3 }}
                        />
                        {question.comments.length} Comment
                        {question.comments.length === 1 ? '' : 's'}
                      </Typography>
                    </div>
                    <div className="flex pb-1 pt-1 items-center justify-between">
                      {question.question_score && (
                        <div className="flex items-center mr-2">
                          <FiStar className="mr-1 pb-0.5" fill="black" />
                          <Typography
                            sx={{
                              fontSize: '0.875rem',
                              mr: 0.6
                            }}>
                            {question.question_score_type} Question Score:
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '0.875rem'
                            }}
                            className={`${categoryTextColor(
                              question.question_score_category.name
                            )}`}>
                            {question.question_score_category.name +
                              ' (' +
                              (question.question_score * 100).toFixed(2) +
                              '%)'}
                          </Typography>
                        </div>
                      )}
                      {question.average_evaluation !== undefined &&
                        question.average_evaluation !== 0.0 && (
                          <div className="flex items-center mr-2">
                            <TbLayoutAlignMiddle className="mr-1" />
                            <Typography
                              sx={{
                                fontSize: '0.875rem',
                                mr: 0.6
                              }}>
                              Community Evaluation:{' '}
                            </Typography>
                            <Typography
                              className={`${categoryTextColor(
                                question.average_evaluation_category.name
                              )}`}>
                              {question.average_evaluation_category.name +
                                ' (' +
                                Math.round(question.average_evaluation * 10) /
                                10 +
                                ')'}
                            </Typography>
                          </div>
                        )}
                    </div>
                  </li>
                </div>
              ))
            ) : (
              <div className="px-4 pt-2 text-lg">
                <Typography>No questions could be found.</Typography>
              </div>
            )}
          </TabPanel>
          <TabPanel value={'3'}>
            {evaluations.length > 0 ? (
              evaluations.map((evaluation) => (
                <div
                  key={evaluation.id}
                  className="border-b border-gray-300 flex items-start justify-start flex-col px-4 py-2">
                  <Typography
                    className="text-lg max-h-42 overflow-y-auto"
                    color="text.secondary">
                    Question:{' '}
                    <Link
                      underline="hover"
                      href={`/questions/${evaluation.question_id}`}
                      sx={{ fontWeight: 'bold' }}>
                      {questionEntities[evaluation.question_id].question_text}
                    </Link>
                  </Typography>
                  <div className="ml-2 flex items-start justify-start">
                    <Typography sx={{ mr: 0.6 }} color="text.secondary">
                      User Evaluation:
                    </Typography>
                    <Typography
                      sx={{ fontWeight: 600, mr: 1.2 }}
                      className={`${categoryTextColor(
                        evaluation.category.name
                      )}`}>
                      {evaluation.category.name}
                    </Typography>
                    {evaluation.evaluation_score && (
                      <div className="flex">
                        <Typography sx={{ mr: 0.6 }} color="text.secondary">
                          Your {evaluation.evaluation_score_type} Evaluation
                          Score:{' '}
                        </Typography>
                        <Typography
                          sx={{ fontWeight: 600 }}
                          className={`${evaluationScoreCategoryTextColor(
                            evaluation.evaluation_score_category.name
                          )}`}>
                          {evaluation.evaluation_score_category.name +
                            ' (' +
                            evaluation.evaluation_score +
                            ')'}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="px-4 pt-2 text-lg">
                <Typography>No evaluations could be found.</Typography>
              </div>
            )}
          </TabPanel>
          <TabPanel value={'4'}>
            {comments.length > 0 ? (
              comments.map((comment) => (
                <div
                  key={comment.id}
                  className="border-b border-gray-300 flex items-start justify-start flex-col px-4 py-2">
                  {comment.parentQuestionId ? (
                    <div className="flex max-h-42 overflow-y-auto">
                      <Typography sx={{ mr: 0.6 }} color="text.secondary">
                        Question:
                      </Typography>
                      <Link
                        underline="hover"
                        href={`/questions/${comment?.parentQuestionId}/comment/${comment?.id}`}>
                        {
                          questionEntities[comment.parentQuestionId]
                            .question_text
                        }
                      </Link>
                    </div>
                  ) : (
                    <div className="flex max-h-42 overflow-y-auto">
                      <Typography sx={{ mr: 0.6 }} color="text.secondary">
                        Question:
                      </Typography>
                      <Link
                        underline="hover"
                        href={`/questions/${comment?.questionId}/comment/${comment?.id}`}>
                        {questionEntities[comment.questionId]?.question_text}
                      </Link>
                    </div>
                  )}
                  <div className="flex max-h-42 overflow-y-auto">
                    <Typography sx={{ mr: 0.6 }} color="text.secondary">
                      Comment:
                    </Typography>
                    <Typography
                      sx={{ fontWeight: 'bold' }}
                      className="text-base font-bold">
                      {comment?.text}
                    </Typography>
                  </div>
                  <div className="ml-2 flex items-start justify-start flex-col">
                    <Typography
                      sx={{
                        mr: 1.2,
                        fontSize: '0.875rem',
                        p: 0,
                        display: 'flex',
                        alignItems: 'center'
                      }}
                      color="text.secondary">
                      <CalendarMonthOutlined
                        fontSize="inherit"
                        sx={{ mr: 0.3, mb: 0.3 }}
                      />
                      Commented {timeSince(comment?.created_at)}
                    </Typography>
                  </div>
                </div>
              ))
            ) : (
              <div className="px-4 pt-2 text-lg">
                <Typography>No comments could be found.</Typography>
              </div>
            )}
          </TabPanel>
          {(userId === userData.id ||
            userData.role === 'Moderator' ||
            userData.role === 'Admin') && (
              <TabPanel value={'5'}>
                {currentUserForecasts?.length > 0 ? (
                  currentUserForecasts.map((userForecast) => (
                    <div
                      key={userForecast.id}
                      className="border-b border-gray-300 flex items-start justify-start flex-col px-4 py-2">
                      {userForecast.question_id ? (
                        <div className="flex max-h-42 overflow-y-auto">
                          <Typography sx={{ mr: 0.6 }} color="text.secondary">
                            Question:
                          </Typography>
                          <Link
                            underline="hover"
                            href={`/questions/${userForecast.question_id}`}>
                            {
                              questionEntities[userForecast.question_id]
                                ?.question_text
                            }
                          </Link>
                        </div>
                      ) : (
                        <div className="flex max-h-42 overflow-y-auto">
                          <Typography sx={{ mr: 0.6 }} color="text.secondary">
                            Question:
                          </Typography>
                          <Link
                            underline="hover"
                            href={`/questions/${userForecast.question_id}`}>
                            {
                              questionEntities[userForecast.question_id]
                                ?.question_text
                            }
                          </Link>
                        </div>
                      )}
                      <div className="flex max-h-42 overflow-y-auto items-center">
                        <Typography sx={{ mr: 0.6 }} color="text.secondary">
                          Forecast:
                        </Typography>
                        <Typography
                          sx={{
                            mx: 0.6,
                            p: 0,
                            display: 'flex',
                            alignItems: 'center'
                          }}
                          color="text.secondary">
                          <EditOutlined fontSize="inherit" sx={{ mr: 0.3 }} />
                          {(
                            userForecast.probability.display_probability * 100
                          ).toFixed(1)}
                          %
                        </Typography>
                      </div>
                      <div className="ml-2 flex items-start justify-start flex-col">
                        <Typography
                          sx={{
                            mr: 1.2,
                            fontSize: '0.875rem',
                            p: 0,
                            display: 'flex',
                            alignItems: 'center'
                          }}
                          color="text.secondary">
                          <CalendarMonthOutlined
                            fontSize="inherit"
                            sx={{ mr: 0.3, mb: 0.3 }}
                          />
                          Created {timeSince(userForecast.created_at)}
                        </Typography>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="px-4 pt-2 text-lg">
                    <Typography>No forecasts could be found.</Typography>
                  </div>
                )}
              </TabPanel>
            )}
          <TabPanel value={'6'}>
            {strategies.length > 0 ? (
              strategies.map((strategy) => (
                <div key={strategy.id} >
                  {(outcomes?.find((x) => x.id === strategy.outcome_id).crowd_can_view_strategies === 'True' || 
                  outcomes?.find((x) => x.id === strategy.outcome_id).created_by.id === userData.id ||
                  strategy.created_by.id === userData.id ||
                  userData.role === 'Admin' || 
                  userData.role === 'Moderator') && <div className="border-b border-gray-300">
                    <Button
                      onClick={() =>
                        outcomeTitleClick(strategy.outcome_id)
                      }
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '1rem',
                        ml: 1.2,
                        textAlign: 'left',
                        textTransform: 'none'
                      }}>
                      {outcomes?.find((x) => x.id === strategy.outcome_id).title}
                    </Button>
                    <li className="w-full flex items-start justify-start flex-col px-4 pb-2 ml-2 rounded-t-lg">
                      <Link underline="hover" href={`/outcome/${strategy.outcome_id}`}>
                        <div className="flex items-center">
                          <Typography
                            sx={{
                              fontSize: '1.1rem',
                              ml: 1.2,
                              textAlign: 'left',
                              textTransform: 'none'
                            }}
                            className="text-lg font-normal inline-flex items-start max-h-42 overflow-y-auto">
                            {strategy.strategy}
                          </Typography>
                          {strategy.current_resolution !== undefined && (
                            <Typography
                              sx={{
                                borderColor: getResolutionColour(strategy.current_resolution?.name),
                                backgroundColor: getResolutionColour(strategy.current_resolution?.name),
                                py: 0,
                                px: 0.3,
                                fontSize: '0.85rem',
                                mx: 0.6,
                                textTransform: 'capitalize'
                              }}
                              className={`border-2 rounded text-white font-normal text-xs px-1 ml-1`}>
                              {strategy.current_resolution?.name.replace(
                                'Not Set',
                                'Reopened'
                              )}
                            </Typography>
                          )}
                        </div>
                      </Link>
                      <div className="text-xs font-light flex items-center">
                        <Typography
                          sx={{
                            mr: 1.2,
                            fontSize: '0.875rem',
                            p: 0,
                            display: 'flex',
                            alignItems: 'center'
                          }}
                          color="text.secondary">
                          <CalendarMonthOutlined
                            fontSize="inherit"
                            sx={{ mr: 0.3, mb: 0.3 }}
                          />
                          Posted {timeSince(strategy.created_at)}
                        </Typography>
                        <Typography
                          sx={{
                            mr: 1.2,
                            fontSize: '0.875rem',
                            p: 0,
                            display: 'flex',
                            alignItems: 'center'
                          }}
                          color="text.secondary">
                          <ChatBubbleOutlineOutlined
                            fontSize="inherit"
                            sx={{ mr: 0.3, mb: 0.3 }}
                          />
                          {strategy.comments.length} Comment
                          {strategy.comments.length === 1 ? '' : 's'}
                        </Typography>
                      </div>
                    </li>
                  </div>}
                </div>
              ))
            ) : (
              <div className="px-4 pt-2 text-lg">
                <Typography>No strategies could be found.</Typography>
              </div>
            )}
          </TabPanel>
        </Card>
      </TabContext>
    </Card>
  );
}
