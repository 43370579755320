import { ThumbDown } from '@mui/icons-material';
import { Button, Card, Typography } from '@mui/material';

export default function ErrorModal({ shown, close, errorMessage }) {
  return shown ? (
    <div className="modal-backdrop" onClick={() => {}}>
      <Card
        className="modal-content w-full sm:w-5/6 md:w-2/3 lg:w-3/5 xl:w-2/5 2xl:w-1/3"
        onClick={(e) => {
          e.stopPropagation();
        }}>
        <div>
          <div className="flex items-center justify-center">
            <ThumbDown />
          </div>
          <div className="flex items-center justify-center py-1">
            <Typography
              sx={{ fontWeight: 700 }}
              className="text-lg font-extrabold text-center"
              color="error.main">
              {errorMessage}
            </Typography>
          </div>
          <div className="flex items-center">
            <div className="w-full m-2">
              <Button variant="contained" onClick={close} className="w-full">
                Close
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  ) : null;
}
