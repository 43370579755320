import { Card } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from '../../App';
import {
  addNewUserForecast,
  fetchOutcomeById,
  fetchQuestionById,
  fetchUserForecastsByQuestion,
  selectCurrentUserForecastByQuestion,
  selectQuestionById
} from '../../store/slices/outcomeSlice';
import SuccessModal from '../modals/SuccessModal';
import UserForecastCommentModal from '../modals/UserForecastCommentModal';
import useWindowDimensions from '../useWindowDimensions.jsx';

export default function NumericForecastInput({ questionId }) {
  const dispatch = useDispatch();
  const { userData } = useContext(AuthContext);
  const { width } = useWindowDimensions();
  const [forecastErrorMessage, setForecastErrorMessage] = useState('');
  const [predictionSuccess, setPredictionSuccess] = useState(false);
  const [showForecastCommentModal, setShowForecastCommentModal] =
    useState(false);

  const question = useSelector((state) =>
    selectQuestionById(state, questionId)
  );

  const currentUserForecast = useSelector((state) =>
    selectCurrentUserForecastByQuestion(state, {
      userId: userData.id,
      questionId: questionId
    })
  );

  const [inputPrediction, setInputPrediction] = useState(
    (currentUserForecast?.probability.display_probability * 100).toFixed(1)
  );

  useEffect(() => {
    setInputPrediction(
      !currentUserForecast
        ? 50
        : (currentUserForecast?.probability.display_probability * 100).toFixed(
          1
        )
    );
  }, [currentUserForecast]);

  const handleInputChange = (event) => {
    const inputValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setInputPrediction(inputValue);
  };
  const handleSliderInputChange = (event, newValue) => {
    setInputPrediction(newValue);
  };

  const handleInputBlur = () => {
    if (inputPrediction < 0) {
      setInputPrediction(0);
    } else if (inputPrediction > 100) {
      setInputPrediction(100);
    }
  };

  const sliderMarks = () => {
    let marks = [];
    marks.push({ value: 100, label: `100%` });
    if (width > 800) {
      var increment = 10
    } else if (width > 500) {
      var increment = 20
    } else if (width > 450) {
      var increment = 25
    } else {
      var increment = 50
    }
    for (let i = 0; i < (100 / increment); i++) {
      let value = increment * i;
      marks.push({ value: value, label: `${value}%` });
    }
    return marks;
  };



  const submitNewUserForecast = async (forecast, submittedComment) => {
    if (forecast > 100 || forecast < 0) {
      setForecastErrorMessage('Forecast must be between 0-100!');
      return;
    }
    const floatProbability = (parseFloat(forecast) / 100).toFixed(3);
    const token = localStorage.getItem('auth_token');
    try {
      var payload = {
        auth_token: token,
        questionId: questionId,
        probability: floatProbability.toString()
      };
      if (submittedComment !== '') {
        payload['comment'] = submittedComment;
      }

      await dispatch(addNewUserForecast(payload))
        .unwrap()
        .then(() => {
          // clears local storage 'draft' when comment is submitted
          localStorage.removeItem(
            'forecastcomment:' + questionId + userData.username
          );
          // refreshes user stats and comments
          dispatch(
            fetchQuestionById({ questionId: questionId, auth_token: token })
          );
          dispatch(fetchUserForecastsByQuestion(questionId));
          dispatch(
            fetchOutcomeById({ outcomeId: question.outcome_id, auth_token: token })
          );
        });
      setForecastErrorMessage();
      setPredictionSuccess(true);
      setShowForecastCommentModal(false);
    } catch (err) {
      setForecastErrorMessage(
        `An error occurred while submitting your forecast: ${err.message}`
      );
    }
  };

  return (
    <>
      <div className="px-5 py-2 flex flex-col items-center">
        {forecastErrorMessage && (
          <p className="text-s m-2 text-red-600 py-2">{forecastErrorMessage}</p>
        )}
        <Typography variant="h6" sx={{ mb: 2 }}>
          Input your current forecast:
        </Typography>
        <div className="flex flex-row items-center gap-6 w-full mb-2">
          <Slider
            valueLabelDisplay="auto"
            value={typeof inputPrediction === 'number' ? inputPrediction : 0}
            onChange={handleSliderInputChange}
            marks={sliderMarks()}
          />
          <TextField
            type="number"
            value={inputPrediction}
            onChange={handleInputChange}
            InputProps={{ pattern: '[0-9]+[.]?[0-9]?' }}
            inputProps={{
              min: 0.0,
              max: 100.0,
              step: 0.1
            }}
            variant="outlined"
            label="Prediction (%)"
            fullWidth
            onBlur={handleInputBlur}
            sx={{ minWidth: 150, width: 150 }}
          />
        </div>

        <Button
          sx={{ mx: 0.6 }}
          variant="contained"
          onClick={() => setShowForecastCommentModal(true)}
        >
          {!currentUserForecast
            ? 'Submit'
            : (currentUserForecast?.probability.display_value * 100).toFixed(
              1
            ) === inputPrediction
              ? 'Reaffirm'
              : 'Update'}
        </Button>
      </div>
      {predictionSuccess && (
        <div>
          <SuccessModal
            shown={predictionSuccess}
            close={() => {
              setPredictionSuccess(false);
            }}
            successMessage={'Thanks for submitting your prediction!'}
          />
        </div>
      )}

      {showForecastCommentModal && (
        <UserForecastCommentModal
          shown={showForecastCommentModal}
          closeAndSubmit={(submittedComment) => {
            submitNewUserForecast(inputPrediction, submittedComment);
          }}
          cancel={() => {
            setShowForecastCommentModal(false);
          }}
          questionId={questionId}
          forecastErrorMessage={forecastErrorMessage}
        />
      )}
    </>
  );
}
