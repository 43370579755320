import React, { useContext, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { AuthContext, checkTokenStatus } from '../../App';
import {
  submitLikelihoodPrediction
} from '../../store/slices/outcomeSlice';
import useWindowDimensions from '../useWindowDimensions.jsx';

import { Button, Card, TextField, Typography, Slider } from '@mui/material';
import './styles.css';

export default function LikelihoodPredictionModal({
  close,
  afterSubmit,
  currentLikelihoodPrediction,
  strategyId,
  outcomeId
}) {
  const dispatch = useDispatch();
  const { setIsLoggedIn } = useContext(AuthContext);
  const { width } = useWindowDimensions();

  const [likelihoodPrediction, setLikelihoodPrediction] = useState(currentLikelihoodPrediction ? currentLikelihoodPrediction * 100 : 0);
  const [errorMessage, setErrorMessage] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');

  const handleSliderLikelihoodPrediction = (event, newValue) => {
    setLikelihoodPrediction(newValue);
  };

  const handleInputLikelihoodPrediction = (event) => {
    const inputValue = event.target.value === '' ? '' : Number(event.target.value);
    setLikelihoodPrediction(inputValue);
  };

  const handleLikelihoodPredictionBlur = () => {
    if (likelihoodPrediction < 0) {
      setLikelihoodPrediction(0);
    } else if (likelihoodPrediction > 100) {
      setLikelihoodPrediction(100);
    }
  };

  const sliderMarks = () => {
    let marks = [];
    marks.push({ value: 100, label: `100%` });
    if (width > 800) {
      var increment = 10
    } else if (width > 500){
      var increment = 20
    } else if (width > 450) {
      var increment = 25
    } else {
      var increment = 50
    }
    for (let i = 0; i < (100/increment); i++) {
      let value = increment * i;
      marks.push({ value: value, label: `${value}%` });
    }
    return marks;
  };

  const canCreate =
    [likelihoodPrediction, checkTokenStatus()].every(
      Boolean
    ) && addRequestStatus === 'idle';

  const addLikelihoodPrediction = async () => {
    if (canCreate) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setAddRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        const floatProbability = (parseFloat(likelihoodPrediction) / 100).toFixed(3);
        let payload = {
          strategy_id: strategyId,
          outcome_id: outcomeId,
          likelihood_prediction: floatProbability.toString(),
          auth_token: token
        };
        await dispatch(submitLikelihoodPrediction(payload))
          .unwrap()
          .then((data) => {
            if (isMounted) setLikelihoodPrediction('');
          });
        if (isMounted) setAddRequestStatus('idle');
        isMounted = false;
        afterSubmit();
      } catch (err) {
        setErrorMessage(`Failed to submit likelihood prediction: ${err.message}`);
        if (isMounted) setAddRequestStatus('idle');
        isMounted = false;
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      if (likelihoodPrediction) setErrorMessage('Likelihood prediction could not be saved.');
      if (!likelihoodPrediction) setErrorMessage('Please enter a likelihood prediction.');
    }
  };

  return <div
    className="modal-backdrop"
    onClick={close}> {/*closes modal when outside of modal is clicked*/}
    <Card
      className="modal-content w-full sm:w-5/6 md:w-2/3 lg:w-3/5 xl:w-2/5 2xl:w-1/3"
      onClick={(e) => {
        // do not close modal if anything inside modal content is clicked
        e.stopPropagation();
      }}>
      <div>
        <Typography
          sx={{ fontWeight: 'bold', fontSize: '1rem', my: 1 }}
          className="text-center">
          Please enter how likely you think it is that this strategy will be carried out (you can change this prediction later):
        </Typography>
        <div className="py-4 flex flex-row items-center gap-4">
          <Slider
            valueLabelDisplay="auto"
            value={typeof likelihoodPrediction === 'number' ? likelihoodPrediction : 0}
            onChange={handleSliderLikelihoodPrediction}
            marks={sliderMarks()}
          />
          <TextField
            type="number"
            value={likelihoodPrediction}
            onChange={handleInputLikelihoodPrediction}
            InputProps={{ pattern: '[0-9]+[.]?[0-9]?' }}
            inputProps={{
              min: 0.0,
              max: 100.0,
              step: 0.1
            }}
            sx={{ minWidth: 150 }}
            variant="outlined"
            label="Prediction (%)"
            onBlur={handleLikelihoodPredictionBlur}
          />
        </div>

        <Typography color="error">{errorMessage}</Typography>

        <div className="flex my-1">
          <div className="w-full mx-2">
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'gray',
                ':hover': { backgroundColor: '#757575' }
              }}
              onClick={close}
              className="w-full">
              Cancel
            </Button>
          </div>
          <div className="w-full mx-2">
            <Button
              variant="contained"
              onClick={addLikelihoodPrediction}
              className="w-full">
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Card>
  </div>
}
