import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import ReviewsIcon from '@mui/icons-material/Reviews';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { Typography, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import HierarchicalBreadcrumbs from '../../components/other/HierarchicalBreadcrumbs';
import NavigationGrid from '../../components/other/NavigationGrid';

import { AuthContext } from '../../App';

export default function QuestionerNavigationView() {
  const { userData } = useContext(AuthContext);
  const isDarkMode = useTheme().palette.mode === 'dark';

  const navigationItems = [
    {
      icon: {
        component: (
          <QuestionAnswerRoundedIcon
            style={{ color: 'white' }}
            fontSize="large"
            sx={{ scale: '1.25' }}
          />
        ),
        color: isDarkMode
          ? 'bg-gradient-to-br from-cyan-600 to-blue-800'
          : 'bg-gradient-to-br from-blue-400 to-cyan-600'
      },
      title: 'Create Questions',
      description: 'Add new questions to existing outcomes.',
      link: '/outcomes?filter=generation',
      md: 6
    },
    {
      icon: {
        component: (
          <ShowChartIcon
            style={{ color: 'white' }}
            fontSize="large"
            sx={{ scale: '1.25' }}
          />
        ),
        color: isDarkMode
          ? 'bg-gradient-to-br from-lime-600 to-green-800'
          : 'bg-gradient-to-br from-lime-400 to-green-600'
      },
      title: 'Forecast Questions',
      description: 'Add a forecast to questions.',
      link: '/outcomes?filter=forecasting',
      md: 6
    },
    {
      icon: {
        component: (
          <AssignmentLateIcon
            style={{ color: 'white' }}
            fontSize="large"
            sx={{ scale: '1.25' }}
          />
        ),
        color: isDarkMode
          ? 'bg-gradient-to-br from-orange-600 to-red-800'
          : 'bg-gradient-to-br from-orange-400 to-red-600'
      },
      title: 'Action Questions',
      description: 'Explore actionable questions.',
      link: '/action',
      md: 6
    },
    {
      icon: {
        component: (
          <AccountBoxIcon
            style={{ color: 'white' }}
            fontSize="large"
            sx={{ scale: '1.25' }}
          />
        ),
        color: isDarkMode
          ? 'bg-gradient-to-br from-fuchsia-600 to-purple-800'
          : 'bg-gradient-to-br from-fuchsia-400 to-purple-600'
      },
      title: 'User Profile',
      description: 'Explore user performance.',
      link: `/profile/${userData.username}`,
      md: 6
    }
  ];
  return (
    <div className="QuestionerNavigationView">
      <div className="flex justify-between ml-5 mt-7 items-center">
        <HierarchicalBreadcrumbs quickActions={true} />
      </div>
      <div className="flex flex-col relative shrink-0 box-border items-center pt-5 pb-12 px-5">
        <div className="box-border mt-3 mx-auto">
          <Typography variant="h4" sx={{ mb: 2 }}>
            <strong>Quick Actions</strong>
          </Typography>
        </div>
        <NavigationGrid navigationItems={navigationItems} />
      </div>
    </div>
  );
}
