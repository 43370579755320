import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Typography
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import 'react-datepicker/dist/react-datepicker.css';

import { selectUserPreferences } from '../../../store/slices/userSlice';
import StrategyForm from './StrategyForm';

export default function CreateStrategy({ outcome }) {
  const userPreferences = useSelector((state) => selectUserPreferences(state));

  return (
    <Accordion
      defaultExpanded={false}
      disableGutters
      sx={{
        px: userPreferences.outcome_layout === 'card' ? 0.9 : 3.6,
        pt: 1.0,
        pb: 1.0,
        mb: '5px !important'
      }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="create-question-content"
        id="create-question-header">
        <Typography sx={{ fontSize: '1.4rem', paddingLeft: '1rem' }}>
          Post a Strategy
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className="m-2 px-2">
          Post a strategy describing a route the Outcome Owner could take to
          achieve their desired outcome. Try to define the specific actions that
          will be taken.
        </div>
        <StrategyForm outcomeId={outcome.id} />
      </AccordionDetails>
    </Accordion>
  );
}
