import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';
import { Button, FormHelperText, Typography, useTheme } from '@mui/material';
import { MenuItem, TextField } from '@mui/material/';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTour } from '@reactour/tour';
import { parseISO } from 'date-fns';
import ukLocale from 'date-fns/locale/en-GB';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

import 'react-datepicker/dist/react-datepicker.css';
import './date-range-picker.css';

import { ClipLoader } from 'react-spinners';
import { AuthContext, checkTokenStatus } from '../../../App';
import {
  addNewStructuredQuestion,
  editStructuredQuestion,
  fetchQuestionById
} from '../../../store/slices/outcomeSlice';
import { selectUserPreferences } from '../../../store/slices/userSlice';
import AutocompleteSource from './AutocompleteSource';

export default function StructuredQuestionForm({
  outcome,
  setErrorMessage,
  question = null,
  setEditCard = null,
  saveClicked = null,
  setSaveClicked = null,
  outcomeAsQuestion = null
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const { setIsLoggedIn, userData } = useContext(AuthContext);

  var localQuestionObj = JSON.parse(
    localStorage.getItem(
      'structuredquestion:' + outcome.id.toString() + userData.username
    )
  );
  if (localQuestionObj === null) {
    localQuestionObj = {};
  }

  const [interrogative, setInterrogative] = useState('Will'); // not a field
  const [isMounted, setIsMounted] = useState(false);
  const [event, setEvent] = useState(
    localQuestionObj.event ? localQuestionObj.event : ''
  );
  const [eventFocused, setEventFocused] = useState(false);

  // const [questionState, setQuestionState] = useState(''); // optional
  // const [questionStateFocused, setQuestionStateFocused] = useState(false)

  const [conditions, setConditions] = useState(
    localQuestionObj.conditions ? localQuestionObj.conditions : ''
  ); //optional
  const [conditionsFocused, setConditionsFocused] = useState(false);

  const lastAllowedDate = !outcome.continue_forecasting
    ? parseISO(outcome.end_at)
    : undefined;

  const [resolutionDatePreposition, setResolutionDatePreposition] = useState(
    localQuestionObj.resolutionDatePreposition
      ? localQuestionObj.resolutionDatePreposition
      : ''
  );
  const [
    resolutionDatePrepositionFocused,
    setResolutionDatePrepositionFocused
  ] = useState(false);

  const [resolutionDate, setResolutionDate] = useState(
    localQuestionObj.resolutionDate
      ? parseISO(localQuestionObj.resolutionDate)
      : null
  );
  const [resolutionDateFocused, setResolutionDateFocused] = useState(false);

  const [resolutionDateRange, setResolutionDateRange] = useState(
    localQuestionObj.resolutionDateRange
      ? [
          parseISO(localQuestionObj.resolutionDateRange[0]),
          parseISO(localQuestionObj.resolutionDateRange[1])
        ]
      : [null, null]
  );
  // const [resolutionDateRange, setResolutionDateRange] = useState([null, null]);
  const [resolutionDateRangeFocused, setResolutionDateRangeFocused] =
    useState(false);

  const [sourcePreposition, setSourcePreposition] = useState('As reported by'); // not a field

  const [source, setSource] = useState(
    localQuestionObj.source ? localQuestionObj.source : ''
  );

  const [linkAuthority, setLinkAuthority] = useState(
    localQuestionObj.linkAuthority ? localQuestionObj.linkAuthority : ''
  ); // optional
  const [linkAuthorityFocused, setLinkAuthorityFocused] = useState(false);

  const [sourceDatePreposition, setSourceDatePreposition] = useState(
    localQuestionObj.sourceDatePreposition
      ? localQuestionObj.sourceDatePreposition
      : ''
  );
  const [sourceDatePrepositionFocused, setSourceDatePrepositionFocused] =
    useState(false);

  const [sourceDate, setSourceDate] = useState(
    localQuestionObj.sourceDate ? parseISO(localQuestionObj.sourceDate) : null
  );
  const [sourceDateFocused, setSourceDateFocused] = useState(false);
  const [sourceDateRange, setSourceDateRange] = useState(
    localQuestionObj.sourceDateRange
      ? [
          parseISO(localQuestionObj.sourceDateRange[0]),
          parseISO(localQuestionObj.sourceDateRange[1])
        ]
      : [null, null]
  );
  // const [sourceDateRange, setSourceDateRange] = useState([null, null]);
  const [sourceDateRangeFocused, setSourceDateRangeFocused] = useState(false);

  const [relevanceDescription1, setRelevanceDescription1] = useState(
    "The decision maker's Outcome will become"
  ); // not a field

  const [relationship, setRelationship] = useState(
    localQuestionObj.relationship ? localQuestionObj.relationship : ''
  );
  const [relationshipFocused, setRelationshipFocused] = useState(false);

  const [relevanceDescription2, setRelevanceDescription2] = useState(
    'if this question resolves true.'
  ); // not a field

  const [extraInfo, setExtraInfo] = useState(
    localQuestionObj.extraInfo ? localQuestionObj.extraInfo : ''
  ); // optional
  const [extraInfoFocused, setExtraInfoFocused] = useState(false);

  const [showConditionalSection, setShowConditionalSection] = useState(false);

  const [showExtraInfoSection, setShowExtraInfoSection] = useState(false);

  const { setIsOpen, setCurrentStep } = useTour();

  const openQuestionView = (id) => {
    navigate(`/questions/${id}`);
  };

  const changeEvent = (event) => {
    updateLocalStorage('event', event.target.value);
    setEvent(event.target.value);
  };
  const changeConditions = (event) => {
    updateLocalStorage('conditions', event.target.value);
    setConditions(event.target.value);
  };
  const changeResolutionDatePreposition = (event) => {
    updateLocalStorage('resolutionDatePreposition', event.target.value);
    setResolutionDatePreposition(event.target.value);
  };
  const changeResolutionDate = (newValue) => {
    updateLocalStorage('resolutionDate', newValue);
    setResolutionDate(newValue);
  };
  const changeResolutionDateRange = (newRange) => {
    updateLocalStorage('resolutionDateRange', newRange);
    setResolutionDateRange(newRange);
  };
  const changeSource = (newValue) => {
    updateLocalStorage('source', newValue);
    setSource(newValue);
  };
  const changeLinkAuthority = (event) => {
    updateLocalStorage('linkAuthority', event.target.value);
    setLinkAuthority(event.target.value);
  };
  const changeSourceDatePreposition = (event) => {
    updateLocalStorage('sourceDatePreposition', event.target.value);
    setSourceDatePreposition(event.target.value);
  };
  const changeSourceDate = (newValue) => {
    updateLocalStorage('sourceDate', newValue);
    setSourceDate(newValue);
  };
  const changeSourceDateRange = (newRange) => {
    updateLocalStorage('sourceDateRange', newRange);
    setSourceDateRange(newRange);
  };
  const changeRelationship = (event) => {
    updateLocalStorage('relationship', event.target.value);
    setRelationship(event.target.value);
  };
  const changeExtraInfo = (event) => {
    updateLocalStorage('extraInfo', event.target.value);
    setExtraInfo(event.target.value);
  };

  const updateLocalStorage = (field, value) => {
    // outcome id + username is the composite unique field for items saved in local storage (this makes drafts unique per outcome per user)
    // object is saved in stringified format so we parse it after fetching
    var localQuestionObj = JSON.parse(
      localStorage.getItem(
        'structuredquestion:' + outcome.id.toString() + userData.username
      )
    );
    if (localQuestionObj === null) {
      localQuestionObj = {};
    }
    // update question object's specified field
    localQuestionObj[field] = value;
    // save updated object in local storage (stringified format)
    localStorage.setItem(
      'structuredquestion:' + outcome.id.toString() + userData.username,
      JSON.stringify(localQuestionObj)
    );
  };

  useEffect(() => {
    if (question) {
      setEvent(question.event);
      // if (question.state) setQuestionState(question.state)
      if (question.conditions) setConditions(question.conditions);
      setResolutionDatePreposition(question.resolution_date_preposition);

      if (question.resolution_date.start) {
        setResolutionDateRange([
          moment(question.resolution_date.start).toDate(),
          moment(question.resolution_date.end).toDate()
        ]);
      } else {
        setResolutionDate(moment(question.resolution_date.end).toDate());
      }

      setSource(question.source);
      if (question.linkAuthority) setLinkAuthority(question.linkAuthority);
      setSourceDatePreposition(question.source_date_preposition);

      if (question.source_date.start) {
        setSourceDateRange([
          moment(question.source_date.start).toDate(),
          moment(question.source_date.end).toDate()
        ]);
      } else {
        setSourceDate(moment(question.source_date.end).toDate());
      }

      setRelationship(question.relationship);
      if (question.extra_info) setExtraInfo(question.extra_info);
    }
  }, [question]);

  useEffect(() => {
    if (saveClicked === true) {
      submitQuestionEdit();
    }
  }, [saveClicked]);

  function restartTour() {
    setCurrentStep(0);
    setIsOpen(true);
  }

  function toggleShowConditionalSection() {
    if (showConditionalSection) {
      setShowConditionalSection(false);
    } else {
      setShowConditionalSection(true);
    }
  }
  function toggleShowExtraInfoSection() {
    if (showExtraInfoSection) {
      setShowExtraInfoSection(false);
    } else {
      setShowExtraInfoSection(true);
    }
  }

  var selectedQuestionDate =
    resolutionDatePreposition === 'between'
      ? moment(resolutionDateRange[0]).format('Do MMMM yyyy') +
        ' - ' +
        moment(resolutionDateRange[1]).format('Do MMMM yyyy')
      : resolutionDatePreposition === 'in'
      ? moment(resolutionDateRange[0]).format('MMMM yyyy')
      : moment(resolutionDate).format('Do MMMM yyyy');

  var selectedSourceDate =
    sourceDatePreposition === 'between'
      ? moment(sourceDateRange[0]).format('Do MMMM yyyy') +
        ' - ' +
        moment(sourceDateRange[1]).format('Do MMMM yyyy')
      : sourceDatePreposition === 'in'
      ? moment(sourceDateRange[0]).format('MMMM yyyy')
      : moment(sourceDate).format('Do MMMM yyyy');

  var questionTitle = interrogative + ' ' + event; //+ " " + questionState
  if (conditions) {
    questionTitle += ', ' + conditions + ', ';
  } else {
    questionTitle += ' ';
  }
  var questionResolutionDate =
    resolutionDatePreposition + ' ' + selectedQuestionDate;
  var sourceAndSourceDate =
    ', as reported by ' +
    ' ' +
    source +
    ' ' +
    linkAuthority +
    ' ' +
    sourceDatePreposition +
    ' ' +
    selectedSourceDate;
  var relevance =
    relevanceDescription1 + ' ' + relationship + ' ' + relevanceDescription2;
  var extraInfoText = extraInfo;
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const userPreferences = useSelector((state) => selectUserPreferences(state));

  const stateValues = [
    'happen',
    'occur',
    'be present',
    'transpire',
    'materialise',
    'take place'
  ];
  const datePreposition = ['on or before', 'before', 'on', 'in', 'between'];
  const sourceValues = [
    'BBC News',
    'Reputable Media Sources',
    'UK Government',
    'the Outcome Owner'
  ];
  const relevanceValues = ['more likely', 'less likely'];

  var dateRangePrepositions = ['in', 'between'];

  const canCreate =
    [
      event,
      resolutionDatePreposition,
      source,
      sourceDatePreposition,
      relationship,
      outcome.id,
      checkTokenStatus()
    ].every(Boolean) &&
    (dateRangePrepositions.indexOf(resolutionDatePreposition) === -1
      ? resolutionDate
      : resolutionDateRange[0] && resolutionDateRange[1]) &&
    (dateRangePrepositions.indexOf(sourceDatePreposition) === -1
      ? sourceDate
      : sourceDateRange[0] && sourceDateRange[1]) &&
    addRequestStatus === 'idle';

  const submitQuestion = async (e) => {
    e.preventDefault();
    setIsMounted(true);
    if (canCreate) {
      try {
        setAddRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          event: event,
          // state: questionState,
          conditions: conditions,
          resolution_date_preposition: resolutionDatePreposition,
          resolution_date_start:
            dateRangePrepositions.indexOf(resolutionDatePreposition) !== -1
              ? moment(resolutionDateRange[0]).format('YYYY-MM-DD')
              : null,
          resolution_date_end:
            dateRangePrepositions.indexOf(resolutionDatePreposition) !== -1
              ? moment(resolutionDateRange[1]).format('YYYY-MM-DD')
              : moment(resolutionDate).format('YYYY-MM-DD'),
          source: source,
          link_authority: linkAuthority,
          source_date_preposition: sourceDatePreposition,
          source_date_start:
            dateRangePrepositions.indexOf(sourceDatePreposition) !== -1
              ? moment(sourceDateRange[0]).format('YYYY-MM-DD')
              : null,
          source_date_end:
            dateRangePrepositions.indexOf(sourceDatePreposition) !== -1
              ? moment(sourceDateRange[1]).format('YYYY-MM-DD')
              : moment(sourceDate).format('YYYY-MM-DD'),
          relationship: relationship,
          extra_info: extraInfo,

          outcome_id: outcome.id,
          auth_token: token
        };
        await dispatch(addNewStructuredQuestion(payload))
          .unwrap()
          .then((response) => {
            // clears local storage 'draft' when question is submitted
            localStorage.removeItem(
              'structuredquestion:' + outcome.id.toString() + userData.username
            );
            if (isMounted) setEvent('');
            // if (isMounted) setQuestionState('');
            if (isMounted) setConditions('');
            if (isMounted) setResolutionDatePreposition('');
            if (isMounted) setResolutionDate(null);
            if (isMounted) setResolutionDateRange([null, null]);
            if (isMounted) setSource('');
            if (isMounted) setLinkAuthority('');
            if (isMounted) setSourceDatePreposition('');
            if (isMounted) setSourceDate(null);
            if (isMounted) setSourceDateRange([null, null]);
            if (isMounted) setRelationship('');
            if (response.status === 'success') {
              if (typeof response.data.id !== 'undefined') {
                dispatch(
                  fetchQuestionById({
                    questionId: response.data.id,
                    auth_token: token
                  })
                );
                if (isMounted) setAddRequestStatus('idle');
                openQuestionView(response.data.id);
              }
            }
          });
      } catch (err) {
        setErrorMessage(`Failed to create question: ${err.message}`);
        if (isMounted) setAddRequestStatus('idle');
      } finally {
        setIsMounted(false);
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else if (!event) {
      setErrorMessage('Please provide the event text.');
    } else if (!resolutionDatePreposition) {
      setErrorMessage('Please provide the resolution date preposition.');
    } else if (
      dateRangePrepositions.indexOf(resolutionDatePreposition) === -1 &&
      !resolutionDate
    ) {
      setErrorMessage('Please provide the resolution date.');
    } else if (
      dateRangePrepositions.indexOf(resolutionDatePreposition) !== -1 &&
      (!resolutionDateRange[0] || !resolutionDateRange[1])
    ) {
      setErrorMessage('Please provide the resolution date range.');
    } else if (!source) {
      setErrorMessage('Please provide information about your trusted source.');
    } else if (!sourceDatePreposition) {
      setErrorMessage('Please provide the source date preposition.');
    } else if (
      dateRangePrepositions.indexOf(sourceDatePreposition) === -1 &&
      !sourceDate
    ) {
      setErrorMessage('Please provide the source date.');
    } else if (
      dateRangePrepositions.indexOf(resolutionDatePreposition) !== -1 &&
      (!sourceDateRange[0] || !sourceDateRange[1])
    ) {
      setErrorMessage('Please provide the source date range.');
    } else if (!relationship) {
      setErrorMessage(
        'Please specify whether this question makes the outcome more/less likely.'
      );
    } else if (!outcome.id) {
      setErrorMessage('The outcome ID is missing, please refresh the page.');
    } else if (addRequestStatus !== 'idle') {
      setErrorMessage(
        'Request has been sent to the server, if it takes too long please refresh the page.'
      );
    } else {
      setErrorMessage('Question could not be created.');
    }
  };

  const submitQuestionEdit = async () => {
    let isMounted = true;
    if (canCreate) {
      try {
        setAddRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          id: question.id,
          event: event,
          // state: questionState,
          conditions: conditions,
          resolution_date_preposition: resolutionDatePreposition,
          resolution_date_start:
            dateRangePrepositions.indexOf(resolutionDatePreposition) !== -1
              ? moment(resolutionDateRange[0]).format('YYYY-MM-DD')
              : null,
          resolution_date_end:
            dateRangePrepositions.indexOf(resolutionDatePreposition) !== -1
              ? moment(resolutionDateRange[1]).format('YYYY-MM-DD')
              : moment(resolutionDate).format('YYYY-MM-DD'),
          source: source,
          link_authority: linkAuthority,
          source_date_preposition: sourceDatePreposition,
          source_date_start:
            dateRangePrepositions.indexOf(sourceDatePreposition) !== -1
              ? moment(sourceDateRange[0]).format('YYYY-MM-DD')
              : null,
          source_date_end:
            dateRangePrepositions.indexOf(sourceDatePreposition) !== -1
              ? moment(sourceDateRange[1]).format('YYYY-MM-DD')
              : moment(sourceDate).format('YYYY-MM-DD'),
          relationship: relationship,
          extra_info: extraInfo,
          outcome_as_question: outcomeAsQuestion,
          outcome_id: outcome.id,
          auth_token: token
        };
        await dispatch(editStructuredQuestion(payload))
          .unwrap()
          .then((response) => {
            // clears local storage 'draft' when question is submitted
            localStorage.removeItem(
              'structuredquestion:' + outcome.id.toString() + userData.username
            );
            setSaveClicked(false);
            if (response.status === 'success') {
              setEditCard(false);
            }
          });
      } catch (err) {
        setErrorMessage(`Failed to update question: ${err.message}`);
      } finally {
        if (isMounted) {
          setAddRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      setErrorMessage('Question could not be submitted.');
    }
  };

  return (
    <div>
      <div className="px-2">
        <div className="flex flex-row w-full">
          <div className="flex flex-row flex-wrap w-full">
            {/* question text interrogative */}
            <div className="pt-6 pr-1">{interrogative}</div>

            {/* question text event */}
            <div className="w-2/5 p-1">
              <TextField
                type="text"
                label="this event"
                variant="standard"
                multiline
                required
                value={event}
                // placeholder=""
                onChange={(event) => changeEvent(event)}
                id="question-text-event"
                className="w-full"
                onFocus={() => setEventFocused(true)}
                onBlur={() => setEventFocused(false)}
                helperText={
                  eventFocused &&
                  'Main event of the question i.e. the thing you think is going to happen'
                }
              />
            </div>

            {/* question text state */}
            {/* <div className="p-1">
              <TextField
                label='State'
                id="question-text-state"
                select
                variant="standard"
                defaultValue="happen"
                className="w-48"
                value={questionState}
                onChange={(event) => setQuestionState(event.target.value)}
                onFocus={() => setQuestionStateFocused(true)}
                onBlur={() => setQuestionStateFocused(false)}
                helperText={questionStateFocused && 'Verb describing event state'}
              >
                {stateValues.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div> */}

            {!showConditionalSection ? (
              <div id="open-conditional" className="mt-6 mx-1">
                <Tooltip title="Open conditional section">
                  <IconButton onClick={() => toggleShowConditionalSection()}>
                    <AddCircleOutline color="primary" />
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              <div className=" p-1">
                {/* question text conditions */}
                <div className="flex" id="open-conditional">
                  <TextField
                    label="Conditions"
                    type="text"
                    variant="standard"
                    multiline
                    value={conditions}
                    placeholder="Conditions"
                    onChange={(event) => changeConditions(event)}
                    inputProps={{ style: { fontSize: '0.875rem' } }}
                    id="question-text-conditions"
                    onFocus={() => setConditionsFocused(true)}
                    onBlur={() => setConditionsFocused(false)}
                    helperText={conditionsFocused && 'Conditions on the event'}
                  />
                  <div className="mt-6 mr-1">
                    <Tooltip title="Close conditional section">
                      <IconButton
                        onClick={() => toggleShowConditionalSection()}>
                        <RemoveCircleOutline color="primary" />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>
            )}

            {/* question text date preposition */}
            <div className="p-1 pr-3">
              <TextField
                variant="standard"
                id="question-text-date-preposition"
                label="Date Preposition"
                select
                required
                defaultValue=""
                className="w-48"
                value={resolutionDatePreposition}
                onChange={(event) => changeResolutionDatePreposition(event)}
                onFocus={() => setResolutionDatePrepositionFocused(true)}
                onBlur={() => setResolutionDatePrepositionFocused(false)}
                helperText={
                  resolutionDatePrepositionFocused &&
                  'Describes resolution date'
                }>
                {datePreposition.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            {/* question text date */}
            <div>
              <div
                className="flex flex-col"
                id={
                  theme.palette.mode === 'dark' ? 'dark-picker' : 'light-picker'
                }>
                {resolutionDatePreposition === 'between' ? (
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ukLocale}>
                    <DatePicker
                      id="date-picker"
                      dateFormat="yyyy/MM/dd"
                      selected={resolutionDateRange[0]}
                      onChange={(newValue) => {
                        changeResolutionDateRange(newValue);
                      }}
                      startDate={resolutionDateRange[0]}
                      endDate={resolutionDateRange[1]}
                      selectsRange
                      placeholderText="Select date range *"
                      minDate={parseISO(outcome.forecasting_start_date)}
                      onFocus={() => setResolutionDateFocused(true)}
                      onBlur={() => setResolutionDateFocused(false)}
                      showYearDropdown
                    />
                    {resolutionDateFocused && (
                      <FormHelperText>
                        Date question will resolve
                      </FormHelperText>
                    )}
                  </LocalizationProvider>
                ) : resolutionDatePreposition === 'in' ? (
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ukLocale}>
                    <DatePicker
                      id="date-picker"
                      dateFormat="yyyy/MM"
                      selected={resolutionDateRange[0]}
                      onChange={(newValue) => {
                        var endDate = moment(newValue).endOf('month');
                        changeResolutionDateRange([newValue, endDate.toDate()]);
                      }}
                      startDate={resolutionDateRange[0]}
                      endDate={resolutionDateRange[1]}
                      showMonthYearPicker
                      showFullMonthYearPicker
                      placeholderText="Select month *"
                      minDate={parseISO(outcome.forecasting_start_date)}
                      onFocus={() => setResolutionDateFocused(true)}
                      onBlur={() => setResolutionDateFocused(false)}
                      showYearDropdown
                    />
                    {resolutionDateFocused && (
                      <FormHelperText>
                        Date question will resolve
                      </FormHelperText>
                    )}
                  </LocalizationProvider>
                ) : (
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ukLocale}>
                    <DatePicker
                      id="date-picker"
                      dateFormat="yyyy/MM/dd"
                      selected={resolutionDate}
                      onChange={(newValue) => {
                        changeResolutionDate(newValue);
                      }}
                      placeholderText="Select date *"
                      minDate={parseISO(outcome.forecasting_start_date)}
                      onFocus={() => setResolutionDateFocused(true)}
                      onBlur={() => setResolutionDateFocused(false)}
                      showYearDropdown
                    />
                    {resolutionDateFocused && (
                      <FormHelperText>
                        Date question will resolve
                      </FormHelperText>
                    )}
                  </LocalizationProvider>
                )}
              </div>
            </div>
          </div>
          <div className="mt-2">
            <Tooltip title="Open tour" className="text-center flex flex-col">
              <IconButton onClick={() => restartTour()}>
                <HelpOutlineIcon
                  fontSize="medium"
                  className="mx-3"
                  color="primary"
                />
                <Typography variant="caption" className="text-center">
                  Help
                </Typography>
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div className="flex flex-row flex-wrap my-5">
          {/* question text source preposition */}
          <div className="pt-6 px-1">{sourcePreposition} </div>
          {/* question text source */}
          <div id="autocomplete-source" className="p-1 pr-1">
            <AutocompleteSource
              sourceValues={sourceValues}
              value={source}
              setValue={changeSource}
            />
          </div>
          {/* question text link authority */}
          <div className="p-1 pr-1">
            <TextField
              type="text"
              variant="standard"
              label="Link Authority"
              value={linkAuthority}
              placeholder="e.g. https://www.bbc.co.uk/news"
              onChange={(event) => changeLinkAuthority(event)}
              id="link-authority"
              className="w-48"
              onFocus={() => setLinkAuthorityFocused(true)}
              onBlur={() => setLinkAuthorityFocused(false)}
              helperText={linkAuthorityFocused && 'Link to trusted authority'}
            />
          </div>

          {/* source date preposition */}
          <div className="p-1 pr-1">
            <TextField
              id="question-text-source-date-preposition"
              select
              required
              className="w-48"
              label="Date Preposition"
              variant="standard"
              defaultValue="happen"
              value={sourceDatePreposition}
              onChange={(event) => changeSourceDatePreposition(event)}
              onFocus={() => setSourceDatePrepositionFocused(true)}
              onBlur={() => setSourceDatePrepositionFocused(false)}
              helperText={
                sourceDatePrepositionFocused && 'Describes source date'
              }>
              {datePreposition.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div>

          {/* source date */}
          <div
            id={theme.palette.mode === 'dark' ? 'dark-picker' : 'light-picker'}>
            {sourceDatePreposition === 'between' ? (
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ukLocale}>
                <DatePicker
                  id="source-date-picker"
                  dateFormat="yyyy/MM/dd"
                  selected={sourceDateRange[0]}
                  onChange={(newValue) => {
                    changeSourceDateRange(newValue);
                  }}
                  startDate={sourceDateRange[0]}
                  endDate={sourceDateRange[1]}
                  selectsRange
                  placeholderText="Select date range *"
                  minDate={parseISO(outcome.forecasting_start_date)}
                  maxDate={lastAllowedDate}
                  onFocus={() => setSourceDateFocused(true)}
                  onBlur={() => setSourceDateFocused(false)}
                  showYearDropdown
                />
                {sourceDateFocused && (
                  <FormHelperText>
                    Date question will be evidenced
                  </FormHelperText>
                )}
              </LocalizationProvider>
            ) : sourceDatePreposition === 'in' ? (
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ukLocale}>
                <DatePicker
                  id="source-date-picker"
                  dateFormat="yyyy/MM"
                  selected={sourceDateRange[0]}
                  onChange={(newValue) => {
                    var endDate = moment(newValue).endOf('month');
                    changeSourceDateRange([newValue, endDate.toDate()]);
                  }}
                  startDate={sourceDateRange[0]}
                  endDate={sourceDateRange[1]}
                  showMonthYearPicker
                  showFullMonthYearPicker
                  placeholderText="Select month *"
                  minDate={parseISO(outcome.forecasting_start_date)}
                  maxDate={lastAllowedDate}
                  onFocus={() => setSourceDateFocused(true)}
                  onBlur={() => setSourceDateFocused(false)}
                  showYearDropdown
                />
                {sourceDateFocused && (
                  <FormHelperText>Date question will resolve</FormHelperText>
                )}
              </LocalizationProvider>
            ) : (
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ukLocale}>
                <DatePicker
                  id="source-date-picker"
                  dateFormat="yyyy/MM/dd"
                  selected={sourceDate}
                  onChange={(newValue) => {
                    changeSourceDate(newValue);
                  }}
                  placeholderText="Select date *"
                  minDate={parseISO(outcome.forecasting_start_date)}
                  maxDate={lastAllowedDate}
                  onFocus={() => setSourceDateFocused(true)}
                  onBlur={() => setSourceDateFocused(false)}
                  showYearDropdown
                />
                {sourceDateFocused && (
                  <FormHelperText>
                    Date question will be evidenced
                  </FormHelperText>
                )}
              </LocalizationProvider>
            )}
          </div>
        </div>

        <div className="flex flex-wrap flex-row my-5">
          {/* question text relevance description */}
          <div className="pt-6">{relevanceDescription1}</div>

          {/* question text relevance type */}
          <div className="p-1 w-40">
            <TextField
              id="question-text-relevance-type"
              select
              required
              label="more/less likely"
              defaultValue="happen"
              variant="standard"
              className="w-full"
              value={relationship}
              onChange={(event) => changeRelationship(event)}
              onFocus={() => setRelationshipFocused(true)}
              onBlur={() => setRelationshipFocused(false)}
              helperText={
                relationshipFocused && 'Influence of question on outcome'
              }>
              {relevanceValues.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div>

          {/* question text relevance description */}
          <div className="pt-6">{relevanceDescription2}</div>
        </div>

        {!showExtraInfoSection && (
          <div id="open-extra-info" className="flex ">
            <Tooltip title="Open extra information section">
              <IconButton onClick={() => toggleShowExtraInfoSection()}>
                <AddCircleOutline color="primary" className="mr-1" />
              </IconButton>
            </Tooltip>
            <div className="mt-2">Add extra info</div>
          </div>
        )}

        {showExtraInfoSection && (
          <div className="flex">
            {/* question text extra info */}
            <div
              className="mt-3 mr-1"
              onClick={() => toggleShowExtraInfoSection()}>
              <Tooltip title="Close extra information section">
                <IconButton onClick={() => toggleShowExtraInfoSection()}>
                  <RemoveCircleOutline color="primary" />
                </IconButton>
              </Tooltip>
            </div>
            <TextField
              type="text"
              variant="standard"
              multiline
              label="Extra Info"
              value={extraInfo}
              placeholder="Extra Info"
              onChange={(event) => changeExtraInfo(event)}
              id="question-text-extra-info"
              className="w-full"
              onFocus={() => setExtraInfoFocused(true)}
              onBlur={() => setExtraInfoFocused(false)}
              helperText={extraInfoFocused && 'Any extra information'}
            />
          </div>
        )}

        {!question && (
          <div className="ml-0.5 mt-8">
            <Typography sx={{ color: 'text.secondary', fontSize: '1.2rem' }}>
              Your question will be submitted as follows:
            </Typography>
            <div className="ml-1">
              {questionTitle}
              {resolutionDatePreposition && questionResolutionDate}
              {source && sourceDatePreposition && sourceAndSourceDate}?
              {extraInfo && ' ' + extraInfoText}
            </div>
            <div className="ml-1">{relationship && ' ' + relevance}</div>
          </div>
        )}
        {!question && (
          // <Button onClick={(event)=>submitQuestionEdit(event)}>
          //   {question.status === 'Rejected' &&
          //   question.created_by.username === userData.username
          //     ? 'Resubmit'
          //     : 'Save'}
          // </Button>
          // :*/}
          <Button
            id="submit-question-button"
            variant="contained"
            sx={{ mr: 2, mt: 4 }}
            disabled={isMounted}
            onClick={(event) => submitQuestion(event)}
            className="font-bold rounded">
            {isMounted ? (
              <ClipLoader color="#ffffff" loading={true} size={50} />
            ) : (
              'Post'
            )}
          </Button>
        )}
      </div>
    </div>
  );
}
