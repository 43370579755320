import {
  Box,
  Button,
  Card,
  Divider,
  Slider,
  TextField,
  Typography
} from '@mui/material';
import { min } from 'date-fns';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { AuthContext, checkTokenStatus } from '../../App';
import {
  fetchQuestionById,
  fetchStrategyById,
  makeRelevanceEvaluation,
  makeRelevanceEvaluationOnStrategy,
  selectOutcomeByQuestionOrStrategyId,
  selectStrategyById
} from '../../store/slices/outcomeSlice';
import SmallQuestionCard from '../cards/SmallQuestionCard';
import InfoTooltip from '../other/InfoTooltip';
import Markdown from '../other/Markdown';
import StrategyCard from '../cards/StrategyCard';

export default function SetRelevanceEvaluationModal({
  shown,
  close,
  questionId,
  strategyId,
  outcomeForecast,
  questionForecast,
  existingEvaluation
}) {
  const dispatch = useDispatch();
  const { setIsLoggedIn } = useContext(AuthContext);
  const [responseMessage, setResponseMessage] = useState('');
  const [relevanceImpactRequestStatus, setRelevanceImpactRequestStatus] =
    useState('idle');

  const [errorMessage, setErrorMessage] = useState('');
  const closeModal = () => {
    close();
  };

  const outcome = useSelector((state) =>
    selectOutcomeByQuestionOrStrategyId(state, {'strategyId': strategyId, 'questionId': questionId})
  );

  const strategy = useSelector((state) =>
    selectStrategyById(state, strategyId?strategyId:-1)
  );
  const minimumJointOccurrence = () =>
    Math.max((outcomeForecast - (1 - questionForecast)) * 100, 0);

  const maximumJointOccurrence = () =>
    Math.min(outcomeForecast, questionForecast) * 100;

  const irrelevantJointOccurrence = () =>
    outcomeForecast * questionForecast * 100;
  

  const [jointOccurrence, setJointOccurrence] = useState(
    existingEvaluation
      ? parseFloat(existingEvaluation.toPrecision(4))
      : irrelevantJointOccurrence().toPrecision(3)
  );

  const relevanceLevel = () =>
    jointOccurrence / 100 / questionForecast -
    (outcomeForecast - jointOccurrence / 100) / (1 - questionForecast);

  const canSetRelevanceImpact =
    [(questionId||strategyId), checkTokenStatus()].every(Boolean) &&
    jointOccurrence !== '' &&
    relevanceImpactRequestStatus === 'idle';

  const setRelevanceEvaluationConfirm = async () => {
    if (canSetRelevanceImpact) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setRelevanceImpactRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          id: questionId?questionId:strategyId,
          jointOccurrence: parseFloat(jointOccurrence) / 100,
          auth_token: token
        };
        if (questionId) {
          await dispatch(makeRelevanceEvaluation(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) {
              setJointOccurrence('');
            }
            if (response.status === 'success') {
              setErrorMessage('');
              setResponseMessage(
                `Your relevance evaluation has been submitted`
              );
              dispatch(
                fetchQuestionById({ questionId: questionId, auth_token: token })
              );
            }
          });
        } else {
          await dispatch(makeRelevanceEvaluationOnStrategy(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) {
              setJointOccurrence('');
            }
            if (response.status === 'success') {
              setErrorMessage('');
              setResponseMessage(
                `Your relevance evaluation has been submitted`
              );
              dispatch(
                fetchStrategyById({ strategyId: strategyId, auth_token: token })
              );
            }
          });
        }
      } catch (err) {
        setErrorMessage(
          `Failed to submit relevance evaluation: ${err.message}`
        );
      } finally {
        if (isMounted) {
          setRelevanceImpactRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else if (jointOccurrence === null) {
      setErrorMessage(
        'Please enter a Joint Occurrence Prediction before confirming.'
      );
    } else {
      setErrorMessage("Failed to submit Question's Relevance Evaluation");
    }
  };

  const handleSliderChange = (event, newValue) => {
    setJointOccurrence(newValue);
  };

  const valuetext = (value) => {
    return `${value}%`;
  };

  const sliderMarks = () => {
    let marks = [];
    let increment = (maximumJointOccurrence() - minimumJointOccurrence()) / 10;
    marks.push({
      value: irrelevantJointOccurrence(),
      label: `Irrelevant: ${irrelevantJointOccurrence().toFixed(2)}%`
    });
    marks.push({
      value: minimumJointOccurrence(),
      label: `${minimumJointOccurrence().toFixed(2)}%`
    });
    for (let i = 1; i < 10; i++) {
      let value = minimumJointOccurrence() + increment * i;
      marks.push({ value: value, label: `${value.toFixed(2)}%` });
    }
    marks.push({
      value: maximumJointOccurrence(),
      label: `${maximumJointOccurrence().toFixed(2)}%`
    });
    return marks;
  };

  return shown ? (
    <div
      className="modal-backdrop"
      onClick={() => {
        // close modal when outside of modal is clicked
      }}>
      <Card
        className="modal-content w-full sm:w-6/7 md:w-5/6 lg:w-3/5 xl:w-3/5 2xl:w-3/5"
        sx={{ overflowY: 'auto !important', maxHeight: '90vh' }}
        onClick={(e) => {
          // do not close modal if anything inside modal content is clicked
          e.stopPropagation();
        }}
        raised>
        <div>
          <Typography
            sx={{ fontWeight: 700, fontSize: '1.1rem' }}
            className="text-center">
            Joint Occurrence Prediction
          </Typography>
          {errorMessage && (
            <Typography color="error">{errorMessage}</Typography>
          )}
          <div className="flex justify-center">
            <div className="break-words text-xs font-medium m-1 my-2">
              <Typography sx={{ fontSize: '0.8rem' }}>
                Please enter your prediction for the likelihood (in %) of joint
                occurrence between the Outcome and this Question.
              </Typography>
            </div>
          </div>
          {!responseMessage && (
            <div>
              {outcome && (
                <div className="break-words font-medium">
                  <Typography
                    sx={{ fontWeight: 400, py: 0.6 }}
                    color="primary.main">
                    Outcome:
                  </Typography>
                  <div className="border-2 rounded p-2 px-4 max-h-64 overflow-y-auto">
                    <Typography sx={{ fontWeight: 'bold', py: 0.6 }}>
                      {outcome.title}
                    </Typography>
                    <Divider sx={{ my: 0.6 }} />
                    <div className="markdown">
                      <Typography sx={{ fontWeight: 400, py: 0.6 }}>
                        <Markdown description={outcome.description} />
                      </Typography>
                    </div>
                    {(outcome.statuses.includes('Generation') ||
                      outcome.statuses.includes('Evaluation')) && (
                      <div className="flex items-center mr-2">
                        <Typography
                          sx={{
                            mr: 0.6,
                            fontSize: '0.875rem'
                          }}>{`Outcome Ends: ${moment
                          .utc(outcome.end_at)
                          .local()
                          .format('dddd, MMMM Do YYYY')}`}</Typography>
                      </div>
                    )}
                  </div>
                  <div className="my-1">
                    <Typography>
                      {`Your current forecast on this Outcome is: ${(
                        outcomeForecast * 100
                      ).toFixed(1)}%`}
                    </Typography>
                  </div>
                </div>
              )}
              {questionId && <div className="break-words font-medium">
                <Typography
                  sx={{ fontWeight: 400, py: 0.6 }}
                  color="primary.main">
                  Question:
                </Typography>
                <div className="border-2 rounded">
                  <SmallQuestionCard questionId={questionId} />
                </div>
                <div className="my-1">
                  <Typography>
                    {`Your current forecast on this Question is: ${(
                      questionForecast * 100
                    ).toFixed(1)}%`}
                  </Typography>
                </div>
              </div>}
              {strategyId && <div className="break-words font-medium">
                <Typography
                  sx={{ fontWeight: 400, py: 0.6 }}
                  color="primary.main">
                  Strategy:
                </Typography>
                <div className="border-2 rounded">
                  <StrategyCard strategy={strategy} hideButtons />
                </div>
                <div className="my-1">
                  <Typography>
                    {`Your current forecast on this Question is: ${(
                      questionForecast * 100
                    ).toFixed(1)}%`}
                  </Typography>
                </div>
              </div>}

              <div className="justify-center w-full mx-1 my-4">
                <Typography id="input-slider" gutterBottom>
                  Joint Occurrence Prediction (%)
                </Typography>
                <Box
                  sx={{ minWidth: 400, width: '100%', my: 2, display: 'flex' }}>
                  <Slider
                    value={jointOccurrence}
                    onChange={handleSliderChange}
                    aria-labelledby="input-slider"
                    getAriaValueText={valuetext}
                    step={0.1}
                    min={minimumJointOccurrence()}
                    max={maximumJointOccurrence()}
                    marks={sliderMarks()}
                    track={'normal'}
                    sx={{
                      mx: 2,
                      '& .MuiSlider-markLabel[data-index="0"]': {
                        transform: 'translate(-50%, -200%)'
                      }
                    }}
                  />
                  <TextField
                    type="number"
                    value={jointOccurrence}
                    onChange={(e) => setJointOccurrence(e.target.value)}
                    InputProps={{ pattern: '[0-9]+[.]?[0-9]?' }}
                    inputProps={{
                      min: minimumJointOccurrence(),
                      max: maximumJointOccurrence(),
                      step: 0.1
                    }}
                    variant="outlined"
                    label="Joint Occurrence Prediction (%)"
                    sx={{ width: '30%', mx: 2 }}
                  />
                </Box>
                <div className="flex">
                  <Typography sx={{ fontSize: '1rem' }}>
                    {`Relevance Level: ${relevanceLevel().toFixed(2)}`}
                  </Typography>
                  <div className="ml-1">
                    <InfoTooltip
                      text="By providing a Joint Occurrence Prediction you are
                      implying a relevance between the Question and the
                      Outcome. The limits to this value are based on your forecasts placed on the Question and Outcome."
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-end mt-2">
                <div className="mx-2">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: 'gray',
                      ':hover': { backgroundColor: '#757575' }
                    }}
                    onClick={closeModal}>
                    Cancel
                  </Button>
                </div>
                <div className="mx-2">
                  <Button
                    variant="contained"
                    onClick={setRelevanceEvaluationConfirm}>
                    Confirm
                  </Button>
                </div>
              </div>
            </div>
          )}
          {responseMessage && (
            <div className="my-2">
              {responseMessage && (
                <Typography color="success.main" className="text-center">
                  {responseMessage}
                </Typography>
              )}

              <div className="flex mt-4 justify-center">
                <Button
                  onClick={closeModal}
                  variant="contained"
                  className="w-4/12">
                  Close
                </Button>
              </div>
            </div>
          )}
        </div>
      </Card>
    </div>
  ) : null;
}
