import React, { useContext, useRef, useState } from 'react';

import { Button, Card, TextField, Typography, useTheme } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import MDEditor from '@uiw/react-md-editor';
import { AuthContext } from '../../App';
import './styles.css';

export default function UserForecastCommentModal({
  shown,
  closeAndSubmit,
  cancel,
  questionId,
  forecastErrorMessage
}) {
  const isDarkMode = localStorage.getItem('mode') === 'dark';
  const theme = useTheme();
  const escapeMarkdownRef = useRef(null);
  const { userData } = useContext(AuthContext);
  var localCommentObj = JSON.parse(
    localStorage.getItem('forecastcomment:' + questionId + userData.username)
  );
  if (localCommentObj === null) {
    localCommentObj = {};
  }

  const [comment, setComment] = useState(
    localCommentObj.comment ? localCommentObj.comment : ''
  );
  const [checked, setChecked] = useState(false);

  const maxCommentLength = 2500;

  const handleChecked = () => {
    setChecked(!checked);
  };
  const changeCommentText = (value) => {
    updateLocalStorage('comment', value);
    setComment(value);
  };

  const updateLocalStorage = (field, value) => {
    // question id + parent comment id + username is the composite unique field for items saved in local storage (this makes drafts unique)
    // object is saved in stringified format so we parse it after fetching
    var localCommentObj = JSON.parse(
      localStorage.getItem('forecastcomment:' + questionId + userData.username)
    );
    if (localCommentObj === null) {
      localCommentObj = {};
    }
    // update question object's specified field
    localCommentObj[field] = value;
    // save updated object in local storage (stringified format)
    localStorage.setItem(
      'forecastcomment:' + questionId + userData.username,
      JSON.stringify(localCommentObj)
    );
  };

  return shown ? (
    <div
      className="modal-backdrop"
      onClick={() => {
        // close modal when outside of modal is clicked
      }}>
      <Card
        className="modal-content w-full sm:w-5/6 md:w-2/3 lg:w-3/5 xl:w-2/5 2xl:w-1/3"
        onClick={(e) => {
          // do not close modal if anything inside modal content is clicked
          e.stopPropagation();
        }}>
        <div>
          <Typography
            sx={{ fontWeight: 'bold', fontSize: '1rem', my: 1 }}
            className="text-center">
            Submit forecast prediction
          </Typography>
          <div className="flex flex-col items-center">
            <FormControlLabel
              control={<Checkbox checked={checked} onChange={handleChecked} />}
              label="Add comment"
            />
          </div>
          {checked && (
            <div>
              <Typography
                sx={{ fontSize: '0.8rem', my: 1 }}
                className="text-center">
                Add an explanation of the reasoning behind your forecast which
                might provide clarity to decision makers and be helpful to other
                forecasters in the crowd.
              </Typography>
              {forecastErrorMessage && (
                <p className="text-s m-2 text-red-600 py-2">
                  {forecastErrorMessage}
                </p>
              )}
              <div data-color-mode={isDarkMode ? 'dark' : 'light'}>
                <MDEditor
                  id="CommentText"
                  value={comment}
                  onChange={(value) => changeCommentText(value)}
                  textareaProps={{
                    placeholder: 'What is your comment?',
                    maxLength: maxCommentLength,
                    required: true,
                    autoCorrect: 'on'
                  }}
                  preview="edit"
                  style={{
                    backgroundColor: isDarkMode
                      ? theme.palette.background.paper
                      : '#fff',
                    color: isDarkMode ? theme.palette.text.primary : '#000'
                  }}
                  onKeyDownCapture={(e) => {
                    if (e.key === 'Tab') {
                      e.stopPropagation();
                      escapeMarkdownRef.current.focus();
                    }
                  }}
                  defaultTabEnable={true}
                />
              </div>
            </div>
          )}
          <div className="flex mt-4" ref={escapeMarkdownRef}>
            <div className="w-full mx-2">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'gray',
                  ':hover': { backgroundColor: '#757575' }
                }}
                onClick={cancel}
                className="w-full">
                Cancel
              </Button>
            </div>
            <div className="w-full mx-2">
              <Button
                variant="contained"
                onClick={() => {
                  checked ? closeAndSubmit(comment) : closeAndSubmit('');
                }}
                className="w-full">
                {checked ? 'Submit with comment' : 'Submit without comment'}
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  ) : null;
}
