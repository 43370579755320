import { createAsyncThunk } from '@reduxjs/toolkit';
import { hivemindAPI } from '../HivemindAPI';

export const autoGenerateQuestions = createAsyncThunk(
  '/auto/question-generation',
  async (payload) => {
    const response = await hivemindAPI.post(
      `/api/auto/question-generation`,
      {
        outcome_id: payload.outcome_id,
        outcome_context: payload.outcome_context
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const fetchRecommendedQuestions = createAsyncThunk(
  'auto/question-recommendation',
  async (payload) => {
    const response = await hivemindAPI.fetch(
      '/api/auto/question-recommendation',
      { question_text: payload.question_text },
      payload.auth_token
    );
    return response.json();
  }
);

export const addAIForecast = createAsyncThunk(
  'auto/addAIForecast',
  async (payload) => {
    const response = await hivemindAPI.post(
    '/api/auto/add-ai-forecast',
    {
      question_id: payload.question_id,
      outcome_id: payload.outcome_id,
      question_list: JSON.stringify(payload.questionsList)
    },
    payload.auth_token
  );
  return response.json();
  }
);
