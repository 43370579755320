import loadable from '@loadable/component';
import React, { useContext, useEffect, useState } from 'react';

import ClipLoader from 'react-spinners/ClipLoader';

import {
  Divider,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Typography,
  useTheme
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from '../../App';
import {
  fetchQuestionsByOutcome,
  selectOrderedFilteredQuestionRanks,
  selectOrderedFilteredQuestions,
  selectQuestionsByOutcome,
  selectQuestionsSortEvaluationScore,
  selectQuestionsSortQuestionScore,
  selectSortedFilteredQuestionsByOutcome,
  setQuestionSorter
} from '../../store/slices/outcomeSlice';
import { selectUserPreferences } from '../../store/slices/userSlice';
import ClickableQuestionCard from '../cards/ClickableQuestionCard';
import QuestionLayoutToggle from '../other/QuestionLayoutToggle';

import EvaluationFilter from '../other/EvaluationFilter';
import InfoTooltip from '../other/InfoTooltip';
import QuestionStatusFilter from '../other/QuestionStatusFilter';
export default function QuestionList({
  outcomeId,
  outcome,
  isLoggedIn,
  isVotingDisabled,
  setIsVotingDisabled,
  setListLoaded
}) {
  const { userData } = useContext(AuthContext);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [maxPages, setMaxPages] = useState(1);
  const sortType = useSelector(
    (state) => state.outcomes.outcomes.entities[outcomeId].questionSorter
  );
  const dispatch = useDispatch();
  const theme = useTheme();
  const questionStatus = useSelector(
    (state) => state.outcomes.outcomes.entities[outcomeId].questionStatus
  );
  const userPreferences = useSelector((state) => selectUserPreferences(state));

  const sortedQuestions = useSelector((state) =>
    selectSortedFilteredQuestionsByOutcome(state, outcomeId)
  );

  const allQuestions = useSelector((state) =>
    selectQuestionsByOutcome(state, outcomeId)
  );

  const questionSortQuestionScore = useSelector((state) =>
    selectQuestionsSortQuestionScore(state)
  );
  const questionSortEvaluationScore = useSelector((state) =>
    selectQuestionsSortEvaluationScore(state)
  );

  const questionRanksQuestionScoreTable = useSelector((state) =>
    selectOrderedFilteredQuestionRanks(state, userData.id, 'question_score')
  );
  const questionRanksEvaluationScoreTable = useSelector((state) =>
    selectOrderedFilteredQuestionRanks(state, userData.id, 'evaluation_score')
  );

  const orderedQuestionsQuestionScoreTable = useSelector((state) =>
    selectOrderedFilteredQuestions(state, userData.id, 'question_score')
  );
  const orderedQuestionsEvaluationScoreTable = useSelector((state) =>
    selectOrderedFilteredQuestions(state, userData.id, 'evaluation_score')
  );

  const LoadableQuestionCard = loadable(() => import('../cards/QuestionCard'), {
    fallback: <ClipLoader color="#f87171" loading={true} size={50} /> // Loading indicator
  });

  const LoadableCommentList = loadable(() => import('./CommentList'), {
    fallback: <ClipLoader color="#f87171" loading={true} size={50} /> // Loading indicator
  });

  const LoadableRankedQuestionList = loadable(
    () => import('../lists/RankedQuestionList'),
    {
      fallback: <ClipLoader color="#f87171" loading={true} size={50} /> // Loading indicator
    }
  );

  const changeSortType = (event) => {
    dispatch(
      setQuestionSorter({ outcomeId: outcomeId, sortValue: event.target.value })
    );
  };

  const getDisplayableFilters = () => {
    if (outcome.forecast_mechanism === 'derived') {
      if (
        outcome.statuses.length === 1 &&
        outcome.statuses[0] === 'Generation'
      ) {
        return [
          { key: 'pending', value: 'Pending' },
          { key: 'accepted', value: 'Accepted' },
          { key: 'rejected', value: 'Rejected' }
        ];
      } else {
        return [
          { key: 'pending', value: 'Pending' },
          { key: 'accepted', value: 'Accepted' },
          { key: 'rejected', value: 'Rejected' },
          { key: 'closed', value: 'Closed' }
        ];
      }
    } else {
      if (outcome.statuses.includes('Generation')) {
        return [
          { key: 'pending', value: 'Pending' },
          { key: 'accepted', value: 'Accepted' },
          { key: 'rejected', value: 'Rejected' },
          { key: 'duplicate', value: 'Duplicate' }
        ];
      } else if (outcome.statuses.includes('Evaluation')) {
        return [
          { key: 'pending', value: 'Pending' },
          { key: 'accepted', value: 'Accepted' },
          { key: 'rejected', value: 'Rejected' },
          { key: 'duplicate', value: 'Duplicate' }
        ];
      } else if (outcome.statuses.includes('Forecasting')) {
        return [
          { key: 'submitted', value: 'Submitted' },
          { key: 'notSubmitted', value: 'Not Submitted' },
          { key: 'rejected', value: 'Rejected' },
          { key: 'duplicate', value: 'Duplicate' },
          { key: 'closed', value: 'Closed' }
        ];
      } else if (outcome.statuses.includes('Closed')) {
        return [
          { key: 'submitted', value: 'Submitted' },
          { key: 'notSubmitted', value: 'Not Submitted' },
          { key: 'rejected', value: 'Rejected' },
          { key: 'duplicate', value: 'Duplicate' },
          { key: 'closed', value: 'Closed' }
        ];
      } else {
        return [];
      }
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
  };

  useEffect(() => {
    let isMounted = true;
    function fetchQuestionsData() {
      if (isMounted) {
        if (questionStatus === 'idle') {
          const token = localStorage.getItem('auth_token');
          dispatch(
            fetchQuestionsByOutcome({
              outcomeId: outcomeId,
              auth_token: token
            })
          );
        }
      }
    }

    fetchQuestionsData();
    return () => {
      isMounted = false;
    };
  }, [questionStatus, dispatch, outcomeId]);

  useEffect(() => {
    if (questionStatus === 'succeeded') {
      setListLoaded(true);
    }
  }, [questionStatus, setListLoaded]);

  const [content, setContent] = useState();

  useEffect(() => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    if (questionStatus === 'loading') {
      setContent(
        <section className="question-list">
          <div className="text-center">
            <ClipLoader color="#f87171" loading={true} size={100} />
          </div>
        </section>
      );
    } else if (questionStatus === 'succeeded') {
      const calculatedMaxPages = Math.ceil(sortedQuestions.length / pageSize);
      setMaxPages(calculatedMaxPages);

      setContent(
        <section className="question-list">
          <div className="flex relative mt-5 items-center mx-10">
            <Divider className="flex-grow"></Divider>
            <Typography sx={{ mx: 2.4 }} className="flex-shrink">
              Questions (
              {sortedQuestions.length === allQuestions.length
                ? ''
                : `${sortedQuestions.length}/`}
              {allQuestions.length})
            </Typography>
            <Divider className="flex-grow"></Divider>
          </div>
          <div className="flex-grow relative mt-2 items-center overflow-visible">
            <EvaluationFilter
              className="flex relative mt-2 items-center mx-10"
              outcomeId={outcomeId}
            />
          </div>
          <div className="flex justify-end my-1 mx-10">
            <QuestionLayoutToggle outcomeId={outcomeId} />
          </div>
          <div className="flex justify-end items-center">
            <InputLabel id="sort-select-label" sx={{ mx: 1 }}>
              Sort
            </InputLabel>
            <Select
              labelId="sort-select-label"
              id="sort-select"
              value={sortType}
              onChange={changeSortType}>
              <MenuItem value={1}>Creation Date</MenuItem>
              <MenuItem value={2}>Forecast</MenuItem>
              <MenuItem value={3}>Relevance</MenuItem>
              <MenuItem value={4}>Impact</MenuItem>
              <MenuItem value={5}>Movers</MenuItem>
            </Select>
            <Paper className="flex items-center mx-10 p-2 pl-4 my-1 rounded shadow-md">
              <Typography variant="h6" className="font-semibold">
                Filters
              </Typography>
              <div className="ml-1 pb-2 text-sm">
                <InfoTooltip text="Select the statuses of questions you would like to view." />
              </div>
              <div className="flex items-center ml-3">
                <QuestionStatusFilter
                  filters={getDisplayableFilters()}
                  outcomeId={outcomeId}
                />
              </div>
            </Paper>
          </div>
          {userPreferences.question_layout === 'ranked' &&
            orderedQuestionsQuestionScoreTable.length > 0 && (
              <LoadableRankedQuestionList
                questionList={orderedQuestionsQuestionScoreTable}
                questionSort={questionSortQuestionScore}
                questionRanks={questionRanksQuestionScoreTable}
                outcomeId={outcomeId}
                listType={'question_score'}
              />
            )}
          {userPreferences.question_layout === 'ranked' &&
            orderedQuestionsEvaluationScoreTable.length > 0 && (
              <LoadableRankedQuestionList
                questionList={orderedQuestionsEvaluationScoreTable}
                questionSort={questionSortEvaluationScore}
                questionRanks={questionRanksEvaluationScoreTable}
                outcomeId={outcomeId}
                listType={'evaluation_score'}
              />
            )}
          {userPreferences.question_layout !== 'ranked' &&
            sortedQuestions
              .slice(startIndex, endIndex)
              .map((question, index) => (
                <div key={index}>
                  {userPreferences.question_layout === 'detail' ? (
                    <LoadableQuestionCard
                      question={question}
                      isLoggedIn={isLoggedIn}
                      isVotingDisabled={isVotingDisabled}
                      setIsVotingDisabled={(val) => setIsVotingDisabled(val)}
                    />
                  ) : (
                    <ClickableQuestionCard
                      question={question}
                      isLoggedIn={isLoggedIn}
                      isVotingDisabled={isVotingDisabled}
                      setIsVotingDisabled={(val) => setIsVotingDisabled(val)}
                    />
                  )}
                  {userPreferences.question_layout !== 'ranked' && (
                    <LoadableCommentList
                      question={question}
                      maxIndentLevels={
                        userPreferences.question_layout === 'detail' ? 2 : 1
                      }
                    />
                  )}
                </div>
              ))}
          {maxPages > 0 && (
            <div className="flex justify-center items-center my-1">
              <Pagination
                count={maxPages}
                page={page}
                onChange={handlePageChange}
              />
              <Select
                value={pageSize}
                onChange={handlePageSizeChange}
                displayEmpty
                style={{ fontSize: '14px' }}>
                {Array.from({ length: 10 }, (_, index) => index + 1).map(
                  (size) => (
                    <MenuItem key={size} value={size}>
                      {size}
                    </MenuItem>
                  )
                )}
              </Select>
            </div>
          )}
        </section>
      );
    } else {
      setContent(
        <section className="question-list">
          <div className="flex justify-end mx-10">
            <QuestionLayoutToggle outcomeId={outcomeId} />
          </div>
          <Typography sx={{ my: 3 }} className="text-sm text-center">
            No questions could be found.
          </Typography>
        </section>
      );
    }
  }, [
    questionStatus,
    sortedQuestions,
    page,
    pageSize,
    maxPages,
    userPreferences.question_layout
  ]);

  return <div>{content}</div>;
}
