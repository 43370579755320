import { Gavel, Sync } from '@mui/icons-material';
import {
  Button,
  Card,
  MenuItem,
  Pagination,
  Select,
  Typography
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { AuthContext } from '../../App';
import ClickableQuestionCard from '../../components/cards/ClickableQuestionCard';
import RejectQuestionModal from '../../components/modals/RejectQuestionModal';
import HierarchicalBreadcrumbs from '../../components/other/HierarchicalBreadcrumbs';
import {
  fetchOutcomes,
  fetchRejectedOrNonRepliedQuestions,
  selectQuestionsByAction
} from '../../store/slices/outcomeSlice';

export default function ActionQuestionsView() {
  const dispatch = useDispatch();
  const { userData } = useContext(AuthContext);
  const isLoggedIn = !!userData;

  const questionStatus = useSelector(
    (state) => state.outcomes.questions.actionStatus
  );
  const questions = useSelector((state) =>
    selectQuestionsByAction(state, userData.id)
  );

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [maxPages, setMaxPages] = useState(1);
  const [content, setContent] = useState(null);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [rejectingQuestionId, setRejectingQuestionId] = useState(null);

  useEffect(() => {
    updateData();
  }, []);

  function updateData() {
    const token = localStorage.getItem('auth_token');
    dispatch(fetchOutcomes({ auth_token: token }));
    dispatch(fetchRejectedOrNonRepliedQuestions({ auth_token: token }));
  }

  useEffect(() => {
    if (questionStatus === 'loading') {
      setContent(
        <div className="text-center">
          <ClipLoader color="#f87171" loading={true} size={100} />
        </div>
      );
    } else if (
      questionStatus === 'succeeded' &&
      Array.isArray(questions) &&
      questions.length > 0
    ) {
      const calculatedMaxPages = Math.ceil(questions.length / pageSize);
      setMaxPages(calculatedMaxPages);

      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const questionsToDisplay = questions.slice(startIndex, endIndex);

      setContent(
        <div>
          {questionsToDisplay.map((question) => (
            <div key={question.id}>
              <ClickableQuestionCard
                question={question}
                isLoggedIn={isLoggedIn}
              />
            </div>
          ))}
          {maxPages > 1 && (
            <div className="flex justify-center items-center my-1">
              <Pagination
                count={maxPages}
                page={page}
                onChange={handlePageChange}
              />
              <Select
                value={pageSize}
                onChange={handlePageSizeChange}
                displayEmpty
                style={{ fontSize: '14px', marginLeft: '10px' }}>
                {[5, 10, 20, 50].map((size) => (
                  <MenuItem key={size} value={size}>
                    {size} per page
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
        </div>
      );
    } else {
      setContent(
        <Typography variant="h6" align="center" sx={{ mt: 5 }}>
          No questions available.
        </Typography>
      );
    }
  }, [questionStatus, questions, page, pageSize]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  function handleReject(question) {
    setOpenRejectModal(true);
    setRejectingQuestionId(question.id);
  }

  return (
    <div className="ActionQuestionView mt-5">
      <div className="flex ml-10 mt-7 items-center">
        <HierarchicalBreadcrumbs action={true} />
      </div>

      <Card className="flex flex-col relative shrink-0 box-border items-center p-5 m-5">
        <div className="grid grid-cols-5 items-center w-full">
          <div></div>
          <Typography
            variant="h4"
            sx={{ mb: 2 }}
            className="text-center col-span-3">
            <Gavel sx={{ mr: 2 }} />
            <strong>Action Questions</strong>
          </Typography>
          <div className="flex justify-end"></div>
        </div>
        <Typography variant="subtitle1" className="text-center">
          Rejected questions or questions with non-replied-to comments are
          displayed here. Once resolved, they will be removed from this view.
          <br />
          More information about the question can be found by clicking on the
          question.
        </Typography>
      </Card>

      <div className="flex mx-10 justify-between">
        <div></div>
        <Button variant="contained" color="primary" onClick={updateData}>
          <Sync sx={{ mr: 1 }} />
          Refresh
        </Button>
      </div>

      <section className="action-questions-view mt-5">{content}</section>

      {openRejectModal && (
        <RejectQuestionModal
          shown={openRejectModal}
          close={() => setOpenRejectModal(false)}
          questionId={rejectingQuestionId}
        />
      )}
    </div>
  );
}
