import { TextField, FormControl, Autocomplete } from "@mui/material";
import { useState } from "react";

export default function SelectDropdown({
  label,
  options,
  selectedValue,
  onSelectedValueChange,
}) {
  const [value, setValue] = useState(selectedValue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onSelectedValueChange(newValue);
  };

  return (
    <div>
      <FormControl sx={{ my: 1.2 }} className={`w-full`}>
        <Autocomplete
          id="tags-standard"
          options={options}
          getOptionLabel={(option) => option.name || option}
          value={value}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField {...params} variant="standard" label={label} />
          )}
        />
      </FormControl>
    </div>
  );
}
