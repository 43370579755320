import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import {
  deleteComment,
  selectCommentById,
  selectReplyIdsByComment,
  updateComment
} from '../../store/slices/outcomeSlice';

import { selectSettingByName } from '../../store/slices/settingsSlice';

import { AuthContext, checkTokenStatus } from '../../App';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Button,
  Card,
  Collapse,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import MDEditor from '@uiw/react-md-editor';
import moment from 'moment';
import { FiEdit, FiLink } from 'react-icons/fi';
import { GoComment } from 'react-icons/go';
import CreateCommentCard from '../cards/CreateCommentCard';
import ConfirmationModal from '../modals/ConfirmationModal';
import Markdown from '../other/Markdown';
import CommentCard from './CommentCard';

export default function RejectionCommentCard({
  questionId,
  commentId,
  maxIndentLevels,
  indentLevel
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDarkMode = localStorage.getItem('mode') === 'dark';

  const { setIsLoggedIn, userData } = useContext(AuthContext);

  const [commentExists, setCommentExists] = useState(false);
  const [showAddReply, setShowAddReply] = useState(false);
  const navigate = useNavigate();
  const usernameSettings = useSelector((state) =>
    selectSettingByName(state, 'View Usernames')
  );
  const maxCommentLength = 2500;

  const comment = useSelector((state) => selectCommentById(state, commentId));
  const replyIds = useSelector((state) =>
    selectReplyIdsByComment(state, commentId)
  );
  const [editCard, setEditCard] = useState(false);
  const [deleteCommentRequestStatus, setDeleteCommentRequestStatus] =
    useState('idle');
  const [updateCommentRequestStatus, setUpdateCommentRequestStatus] =
    useState('idle');
  const [errorMessage, setErrorMessage] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [repliesCollapsed, setRepliesCollapsed] = useState(false);
  const [extraReplies, setExtraReplies] = useState(false);

  const [commentText, setCommentText] = useState(comment.text);

  const timeSincePosted = () => {
    return moment.utc(comment.created_at).local().fromNow();
  };

  const openCommentView = () => {
    navigate(`/questions/${questionId}/comment/${commentId}`);
  };

  const changeCommentText = (value) => {
    setCommentText(value);
  };
  const changeRepliesCollapsed = (event) => {
    setRepliesCollapsed(!repliesCollapsed);
  };
  const showExtraReplies = (event) => {
    setExtraReplies(true);
  };

  useEffect(() => {
    if (comment !== undefined) {
      if (checkTokenStatus() === true) {
        setIsLoggedIn(true);
        setCommentExists(true);
      }
    } else {
      setCommentExists(false);
    }
  }, [comment, setIsLoggedIn, userData]);

  let replyContent;
  replyContent = replyIds.map((replyId, index) => (
    <div key={index}>
      {index < 3 && (
        <>
          <CommentCard
            key={replyId}
            questionId={questionId}
            commentId={replyId}
            maxIndentLevels={maxIndentLevels}
            indentLevel={indentLevel + 1}
          />
        </>
      )}
    </div>
  ));

  let extraReplyContent;
  extraReplyContent = replyIds.map((replyId, index) => (
    <div key={index}>
      {index >= 3 && (
        <CommentCard
          questionId={questionId}
          commentId={replyId}
          maxIndentLevels={maxIndentLevels}
          indentLevel={indentLevel + 1}
        />
      )}
    </div>
  ));

  const canUpdateComment =
    [comment.id, commentText, checkTokenStatus()].every(Boolean) &&
    updateCommentRequestStatus === 'idle';

  const updateCommentData = async () => {
    if (canUpdateComment) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setUpdateCommentRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          id: comment.id,
          text: commentText,
          auth_token: token
        };
        await dispatch(updateComment(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) {
              setEditCard(!editCard);
            }
          });
      } catch (err) {
        setErrorMessage(`Failed to update comment: ${err.message}`);
      } finally {
        if (isMounted) {
          setUpdateCommentRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      setErrorMessage('Comment could not be updated.');
    }
  };

  const canDeleteComment =
    [comment.id, checkTokenStatus()].every(Boolean) &&
    deleteCommentRequestStatus === 'idle';

  const deleteCommentData = async () => {
    if (canDeleteComment) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setDeleteCommentRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          commentId: comment.id,
          auth_token: token
        };
        await dispatch(deleteComment(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) {
              setEditCard(!editCard);
            }
          });
      } catch (err) {
        setErrorMessage(`Failed to delete comment: ${err.message}`);
      } finally {
        if (isMounted) {
          setDeleteCommentRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      setErrorMessage('Comment could not be deleted.');
    }
  };

  const isEdited = () => {
    return comment.modified_at ? true : false;
  };

  if (usernameSettings === undefined) {
    return null;
  } else {
    return (
      commentExists === true &&
      (userData !== undefined || userData !== null) && (
        <div id={'commentCard' + commentId}>
          <Card
            className={`CommentCard ml-20 mr-10 p-2 border-l-4 break-words ${
              replyIds.length > 0 ? 'mb-2' : 'mb-5'
            } ${comment.active ? 'border-red-500' : 'border-red-200'}`}>
            {errorMessage && (
              <Typography color="error">{errorMessage}</Typography>
            )}
            {/* <span className={`border-2 rounded border-red-500 bg-red-500 text-white font-normal text-xs px-1 ml-1`}>Rejection Comment</span> */}
            {editCard && userData.username === comment.created_by.username ? (
              <>
                <div className="flex text-sm font-extralight justify-start">
                  <Typography
                    sx={{
                      fontSize: '0.875rem'
                    }}
                    color="text.secondary">
                    Commented {timeSincePosted()}
                  </Typography>
                  {(userData.role === 'Admin' ||
                    userData.role === 'Moderator' ||
                    usernameSettings.active) && (
                    <>
                      <Typography
                        color="text.secondary"
                        sx={{
                          fontSize: '0.875rem',
                          ml: 0.6
                        }}>
                        by
                      </Typography>
                      <Typography
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/profile/${comment.created_by.username}`);
                        }}
                        tabIndex={0}
                        onKeyDown={(e) => {
                          if (e.key === ' ' || e.key === 'Enter') {
                            e.stopPropagation();
                            navigate(`/profile/${comment.created_by.username}`);
                          }
                        }}
                        sx={{ ml: 0.6, fontSize: '0.875rem' }}
                        className="hover:underline hover:cursor-pointer"
                        color="primary.main">
                        {comment.created_by.username}
                      </Typography>
                    </>
                  )}
                </div>
                <div data-color-mode={isDarkMode ? 'dark' : 'light'}>
                  <MDEditor
                    id="CommentText"
                    value={commentText}
                    onChange={(event) => changeCommentText(event)}
                    textareaProps={{
                      placeholder: 'What is your comment?',
                      maxLength: maxCommentLength,
                      required: true,
                      autoCorrect: 'on'
                    }}
                    preview="edit"
                    style={{
                      backgroundColor: isDarkMode
                        ? theme.palette.background.paper
                        : '#fff',
                      color: isDarkMode ? theme.palette.text.primary : '#000'
                    }}
                    defaultTabEnable={true}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="flex justify-between items-start max-h-24 overflow-y-auto">
                  <div className="markdown">
                    <Markdown description={comment.text} />
                  </div>
                  <Tooltip title="Permalink" placement="bottom">
                    <button
                      className="text-grey-darkest text-xs rounded inline-flex items-center justify-center"
                      onClick={openCommentView}>
                      <FiLink className="mr-1" />
                    </button>
                  </Tooltip>
                </div>
                <div className="flex text-sm justify-start">
                  <Typography
                    color="text.secondary"
                    sx={{
                      fontSize: '0.875rem',
                      ml: 0.6
                    }}
                    className="ml-1">
                    Rejected{' '}
                    {comment.comment_type.includes('resolvability')
                      ? 'for Resolvability'
                      : comment.comment_type.includes('relevance')
                      ? 'for Relevance'
                      : comment.comment_type.includes('duplicate')
                      ? 'as a Duplicate'
                      : ''}
                  </Typography>
                  <Typography
                    color="text.secondary"
                    sx={{
                      fontSize: '0.875rem',
                      ml: 0.6
                    }}>
                    by
                  </Typography>
                  <Typography
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/profile/${comment.created_by.username}`);
                    }}
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === ' ' || e.key === 'Enter') {
                        e.stopPropagation();
                        navigate(`/profile/${comment.created_by.username}`);
                      }
                    }}
                    sx={{ ml: 0.6, fontSize: '0.875rem' }}
                    className="hover:underline hover:cursor-pointer"
                    color="primary.main">
                    {comment.created_by.username}
                  </Typography>
                  {isEdited() && <p className="mr-1">*</p>}
                </div>
              </>
            )}
            <div className="flex items-start justify-between">
              <div className="flex">
                <>
                  <div className="flex items-center mr-2">
                    <button
                      className="hover:bg-gray-300 text-grey-darkest py-0 px-1 text-xs rounded inline-flex items-center"
                      onClick={() => setShowAddReply(true)}>
                      <GoComment className="mr-1" />
                      <span>Reply</span>
                    </button>
                  </div>
                  {comment.created_by.username === userData.username && (
                    <div className="flex items-center mr-2">
                      <button
                        onClick={() => {
                          setEditCard(!editCard);
                        }}
                        className={
                          'hover:bg-gray-300 text-grey-darkest py-0 px-1 text-xs rounded inline-flex items-center'
                        }>
                        <FiEdit className="mr-1" />
                        {editCard ? 'Cancel' : 'Edit'}
                      </button>
                    </div>
                  )}
                </>
              </div>
              {editCard && (
                <div className="flex justify-end p-1">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={updateCommentData}>
                    Save
                  </Button>
                </div>
              )}
            </div>
          </Card>
          {showAddReply && (
            <CreateCommentCard
              close={() => {
                setShowAddReply(false);
              }}
              afterSubmit={() => {
                setShowAddReply(false);
              }}
              parentCommentId={commentId}
            />
          )}
          {replyIds.length > 0 && (
            <div className="ml-20">
              <IconButton
                onClick={changeRepliesCollapsed}
                aria-label="delete"
                size="small">
                {repliesCollapsed ? (
                  <AddIcon fontSize="small" />
                ) : (
                  <RemoveIcon fontSize="small" />
                )}
              </IconButton>
            </div>
          )}
          <Collapse className="w-full" in={!repliesCollapsed}>
            <section className="reply-list ml-10">
              {replyContent}
              <Collapse className="w-full" in={extraReplies}>
                {extraReplyContent}
              </Collapse>
              <Collapse
                className="w-full"
                in={replyIds.length > 3 && !extraReplies}>
                <button
                  className="ml-20 pl-2 mb-2 text-blue-700 font-bold text-sm hover:underline"
                  onClick={showExtraReplies}>
                  View {replyIds.length - 3} more repl
                  {replyIds.length > 4 ? 'ies' : 'y'}
                </button>
              </Collapse>
            </section>
          </Collapse>

          {showDeleteConfirm && (
            <ConfirmationModal
              shown={showDeleteConfirm}
              close={() => {
                setShowDeleteConfirm(false);
              }}
              confirm={() => {
                setShowDeleteConfirm(false);
                deleteCommentData();
              }}
              confirmationMessage="Do you really want to delete this comment? This process cannot be undone"
            />
          )}
        </div>
      )
    );
  }
}
